import React, { useEffect, useState } from 'react'

import { connect, ConnectedProps } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Paper,
    createMuiTheme,
    MuiThemeProvider,
    MenuItem,
    IconButton,
    Button,
} from '@material-ui/core';
import { EmployeeType as EmployeeTypesModel } from '../../model';
import { ApplicationState } from '../../store';
import * as AuthenticateStore from '../../store/AuthenticateStore';
import { useTranslation } from 'react-i18next';
import { tableIcons } from '../../bug-fix/mui-table-tableicons';
import MaterialTable from 'material-table';
import MuiTable from '../../i18n/MuiTable';
import {
    Edit as EditIcon,
} from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    root: {
    },
    calendarContent: {
        marginTop: 20
    },
    formControl: {
        float: 'right',
        marginTop: 40,
        marginBottom: 20,
        minWidth: 120,
    },
}));

const mtTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#4caf50',
        },
        secondary: {
            main: '#5c6bc0',
        },
    },
    overrides: {
    },
});

const mapStateToProps = (state: ApplicationState) => {
    return {
        auth: state.authenticate,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        ...AuthenticateStore.actionCreators,
    }, dispatch)
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type EmployeeTypeListProps =
    {
        [x: string]: any;
    }
    & PropsFromRedux

const EmployeeTypeList = (props: EmployeeTypeListProps) => {
    const { className } = props;
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [employeeTypes, setEmployeeTypes] = useState<EmployeeTypesModel[]>([]);

    // INIT
    useEffect(() => {
        setIsLoading(true);
        fetch('api/employeetype')
            .then(response => response.json())
            .then(data => {
                setEmployeeTypes(data);
                setIsLoading(false);
            })
    }, []);

    const MTable = () => {
        return (
            <Paper>
                <MuiThemeProvider theme={mtTheme}>
                    <MaterialTable
                        icons={tableIcons}
                        columns={tableState.columns}
                        data={employeeTypes}
                        localization={MuiTable(i18n)}
                        options={{
                            filtering: true,
                            showTitle: false,
                            pageSize: 10,
                            toolbar: false,
                            headerStyle: { backgroundColor: '#9e9e9e', color: 'white', whiteSpace: 'nowrap' },
                        }}
                    />
                </MuiThemeProvider>
            </Paper>
        )
    }

    const tableState = {
        columns: [
            {
                title: '',
                sorting: false,
                // cellStyle: {
                //     width: "3rem",
                // },
                width: 70,
                render: (rowData: any) => <>
                    <IconButton
                        component={Link}
                        to={"/employeetype/" + rowData.id}
                        size="small"
                    >
                        <EditIcon />
                    </IconButton>
                </>
            },
            {
                title: t('administration.employeetype.employeetype_name'),
                field: 'employeeTypeName',
            },
            // {
            //     title: t('administration.employeetype.overtime_approval_level'),
            //     field: 'overtimeApprovalLevel',
            // },
            // {
            //     title: t('administration.employeetype.enable_attendance'),
            //     field: 'enableAttendance',
            // },
        ]
    }

    return (
        <>
            <Card>
                <CardHeader
                    title={t('administration.employeetype.title')}
                />
                <Divider />
                <CardContent>
                    <div style={{ display: 'flex', marginBottom: 10 }}>
                        <div style={{ flexGrow: 1 }}></div>
                        <Button
                            component={Link}
                            to={"/employeetype/add"}
                            color="primary"
                            variant="contained">{t('administration.employeetype.new')}
                        </Button>
                    </div>
                    <MTable />
                </CardContent>
            </Card>
        </>

    )
}


export default compose(
    connector,
)(EmployeeTypeList as any) 