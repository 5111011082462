import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

import { User as UserModel } from '../model'
import { setJWT } from '../helper';

import { isNullOrUndefined } from 'util';


const jwtStorageName = 'JWT';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface AuthenticateState {
    isLoading: boolean;
    user?: UserModel;
    LoginRequired: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestAuthenticateAction {
    type: 'REQUEST_AUTHENTICATE';
}

interface SuccessAuthenticateAction {
    type: 'SUCCESS_AUTHENTICATE';
    userinfo: UserModel;
}

interface FailAuthenticateAction {
    type: 'FAIL_AUTHENTICATE';
}

interface BeginLogoutAction {
    type: 'BEGIN_LOGOUT';
}

interface FinishLogoutAction {
    type: 'FINISH_LOGOUT';
}

interface FailSomethingWrongAction {
    type: 'FAIL_SOMETHINGWORNG';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestAuthenticateAction | SuccessAuthenticateAction | FailAuthenticateAction | BeginLogoutAction | FinishLogoutAction | FailSomethingWrongAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    Token: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch('Auth/Token')
            .then(response => {
                if (response.status === 200) {
                    return response.json() as Promise<UserModel>
                }
                else if (response.status === 401) {
                    dispatch({ type: 'FAIL_AUTHENTICATE' })
                }
                else {
                    dispatch({ type: 'FAIL_SOMETHINGWORNG' })
                }
            })
            .then(data => {
                if (data != null) {
                    dispatch({ type: 'SUCCESS_AUTHENTICATE', userinfo: data });
                }
            })
            .catch(x => {
                console.warn(x);
                dispatch({ type: 'FAIL_AUTHENTICATE' })
            })
            ;

        dispatch({ type: 'REQUEST_AUTHENTICATE' });
    },
    checkLoginFootprint: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        let JWT = localStorage.getItem(jwtStorageName);

        if (isNullOrUndefined(JWT))
            return;


        let mnd = JWT;
        let httpOptions: any = {};
        httpOptions.headers = {};
        httpOptions.headers.Authorization = 'Bearer ' + mnd;

        fetch('api/authenticate', httpOptions)
            .then(response => response.json() as Promise<UserModel>)
            .then(data => {
                if (isNullOrUndefined(data.token)) { // Keep old Token
                    data.token = mnd;
                }
                dispatch({ type: 'SUCCESS_AUTHENTICATE', userinfo: data });
            })
            .catch(x => {
                console.warn(x);
                dispatch({ type: 'FAIL_AUTHENTICATE' })
            })
            ;

        dispatch({ type: 'REQUEST_AUTHENTICATE' });
    },
    login: (code: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        let httpOptions: any = {};
        httpOptions.method = 'POST';
        httpOptions.headers = {
            'Content-Type': 'application/json'
        }
        httpOptions.body = JSON.stringify({ code: code });

        setJWT(httpOptions, appState);

        fetch('api/authenticate/dcone', httpOptions)
            .then(response => response.json() as Promise<UserModel>)
            .then(data => {
                dispatch({ type: 'SUCCESS_AUTHENTICATE', userinfo: data });
            })
            .catch(x => {
                console.warn(x);
                dispatch({ type: 'FAIL_AUTHENTICATE' })
            })

        dispatch({ type: 'REQUEST_AUTHENTICATE' });
    },
    mockLogin: (upn: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.leavedRequest) {
            fetch('api/authenticate/mock', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ upn: upn }),
            }).then(response => response.json() as Promise<UserModel>)
                .then(data => {
                    dispatch({ type: 'SUCCESS_AUTHENTICATE', userinfo: data });
                })
                .catch(x => {
                    console.warn(x);
                    dispatch({ type: 'FAIL_AUTHENTICATE' })
                })

            dispatch({ type: 'REQUEST_AUTHENTICATE' });
        }
    },
    logout: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'BEGIN_LOGOUT' });
        dispatch({ type: 'FINISH_LOGOUT' });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: AuthenticateState = { user: undefined, isLoading: false, LoginRequired: false };

export const reducer: Reducer<AuthenticateState> = (state: AuthenticateState | undefined, incomingAction: Action): AuthenticateState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'BEGIN_LOGOUT':
            return {
                user: state.user,
                isLoading: true,
                LoginRequired: false,
            }
        case 'FINISH_LOGOUT':
            localStorage.removeItem(jwtStorageName);
            return {
                user: undefined,
                isLoading: false,
                LoginRequired: false,
            }
        case 'REQUEST_AUTHENTICATE':
            return {
                user: undefined,
                isLoading: true,
                LoginRequired: false,
            };

        case 'SUCCESS_AUTHENTICATE':
            console.log('SUCCESS_AUTHENTICATE', 'Welcome back, ' + action.userinfo.userName)
            if (action.userinfo.token)
                localStorage.setItem(jwtStorageName, action.userinfo.token);
            return {
                user: action.userinfo,
                isLoading: false,
                LoginRequired: false,
            };
        case 'FAIL_AUTHENTICATE':
            localStorage.removeItem(jwtStorageName);
            return {
                user: undefined,
                isLoading: false,
                LoginRequired: true,
            };
        case 'FAIL_SOMETHINGWORNG':
            alert("Sorry, you don't have access. Please Contact System Admin!");
            localStorage.removeItem(jwtStorageName);
            return {
                user: undefined,
                isLoading: false,
                LoginRequired: true,
            };
    }

    return state;
};
