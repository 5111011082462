import React, { useEffect, useState } from 'react';

import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { isNullOrUndefined } from 'util';

type RejectReasionDialogProps = {
    title?: string
    onConfirm: (reason: string) => void
    onCancel?: () => any
}

const RejectReasionDialog = (props: RejectReasionDialogProps) => {
    const { title, onConfirm, onCancel } = props;

    const { t, i18n } = useTranslation();
    
    const [open, setOpen] = React.useState(false);

    const [rejectReason, setRejectReason] = React.useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        onConfirm(rejectReason);
        handleClose();
    };

    const handleCancel = () => {
        if (onCancel)
            onCancel();
        handleClose();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRejectReason(event.target.value);
    };

    return (
        <React.Fragment>
            <Button variant="contained" color="secondary" onClick={handleClickOpen}>
                {t('reject')}
            </Button>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
                {!isNullOrUndefined(title) &&
                    <DialogTitle>{title}</DialogTitle>
                }
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label={t('reject_reason')}
                        value={rejectReason}
                        onChange={handleChange}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleConfirm} color="primary">
                        {t('confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default RejectReasionDialog;