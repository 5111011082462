import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import moment, { Moment } from 'moment';
import { connect, ConnectedProps } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

import * as AuthenticateStore from '../store/AuthenticateStore';
import * as PreApprovalRequestStore from '../store/PreApprovalRequestStore';
import { ApplicationState } from '../store';

import { Button, ButtonGroup, Card, CardHeader, CardContent, Divider, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { tableIcons } from '../bug-fix/mui-table-tableicons';
import MaterialTable, { Column } from 'material-table';
import MuiTable from '../i18n/MuiTable';
import { useSelector } from 'react-redux';

import PreApprovalTable from '../component/PreApprovalTable';
import UserFilter from '../component/User/UserFilter';
import LeaveTypeFilter from '../component/LeaveType/LeaveTypeFilter';
import SelectFilter from '../component/MuiTable/SelectFilter';

import {
    Edit as EditIcon,
} from '@material-ui/icons';

import UserName from '../component/UserName'
import LeaveTypeName from '../component/LeaveTypeName'
import { PreApprovalRequestStatus } from '../model';

import dateSearch from '../helper/dateSearch';
import userSorting from '../helper/userSorting';
import leaveTypeSorting from '../helper/leaveTypeSorting';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const mapStateToProps = (state: ApplicationState) => {
    return {
        auth: state.authenticate,
        users: state.user,
        leaveType: state.leaveType,
    }
}

const connector = connect(
    mapStateToProps,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type PreApprovalProps =
    {
        classes: {
            root: string,
        },
        className: string,
    }
    & PropsFromRedux
    & RouteComponentProps<{}>

const PreApproval = (prop: PreApprovalProps) => {
    const { auth, users, leaveType, className, ...rest } = prop;

    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const { requests } = useSelector(state => ({ requests: state }))

    return (
        <React.Fragment>
            <Card>
                <CardHeader
                    title={t('pre_approval.title')}
                />
                <Divider />
                <CardContent>
                    <div style={{ display: 'flex', marginBottom: 16 }}>
                        <span style={{ flexGrow: 1 }}></span>
                        <Button
                            component={Link}
                            to={"/pre_approval/add"}
                            color="primary"
                            variant="contained">{t('pre_approval.new')}</Button>
                    </div>
                    <PreApprovalTable columns={[
                        {
                            title: '',
                            sorting: false,
                            // cellStyle: {
                            //     width: "3rem",
                            //     maxWidth: "10%"
                            // },
                            width: 70,
                            render: rowData => <React.Fragment>
                                <IconButton
                                    component={Link}
                                    to={"/pre_approval/" + rowData.id}
                                    size="small"
                                >
                                    <EditIcon />
                                </IconButton>
                                {rowData.nextApprover == 1 &&
                                    <IconButton
                                        component={Link}
                                        to={"/pre_approval/" + rowData.id}
                                        size="small"
                                    >
                                        <EditIcon />
                                    </IconButton>
                                }
                            </React.Fragment>
                        },
                        {
                            title: t('leave_request.request_id'),
                            field: 'requestId',
                        },
                        {
                            title: t('leave_request.applicant'),
                            field: 'applicant',
                            render: rowData => <UserName userid={rowData.applicant ? rowData.applicant : 0} />,
                            customSort: userSorting({ field: 'applicant', userState: users }),
                            filterComponent: UserFilter,
                            lookup: {}
                        },
                        {
                            title: t('leave_request.leave_type'),
                            field: 'leaveTypeId',
                            render: rowData => <LeaveTypeName leaveTypeId={rowData.leaveTypeId ? rowData.leaveTypeId : 0} />,
                            customSort: leaveTypeSorting({ field: 'leaveTypeId', leaveTypeState: leaveType }),
                            filterComponent: LeaveTypeFilter,
                            lookup: {}, // empty is must, to let stupid MuiTable know is array of key
                        },
                        {
                            title: t('status'),
                            field: 'status',
                            filterComponent: SelectFilter,
                            lookup: {
                                "pending_approval": t('common_status.pending_approval'),
                                "pending_hr_approval": t('common_status.pending_hr_approval'),
                                "approved": t('common_status.approved'),
                                "rejected": t('common_status.rejected'),
                                "withdraw": t('common_status.withdraw'),
                            },
                        },
                        {
                            title: t('create_at'),
                            field: 'createdDate',
                            customFilterAndSearch: dateSearch('createdDate'),
                            render: rowData => {
                                if (rowData.createdDate)
                                    return (
                                        <span>{rowData.createdDate.format('YYYY-MM-DD')}</span>
                                    )
                                else
                                    return (
                                        <span>??????</span>
                                    )
                            },
                        },
                    ]}
                        quickFilter={[
                            {
                                title: t('my_application'),
                                logic: (item) => {
                                    if (!auth || !auth.user) {
                                        return false;
                                    } else {
                                        return item.applicant == auth.user.id;
                                    }
                                },
                            },
                            {
                                title: t('waiting_my_approval'),
                                logic: (item) => {
                                    if (!auth || !auth.user) {
                                        return false;
                                    } else if (auth.user.hradmin) {
                                        return item.nextApprover == auth.user.id || item.status == PreApprovalRequestStatus.PendingHRApprove;
                                    } else {
                                        return item.nextApprover == auth.user.id;
                                    }
                                },
                            },
                        ]}
                    />
                </CardContent>
            </Card>
        </React.Fragment>
    )
}

export default connector(PreApproval);
