
import { LeaveAttachment } from './LeaveAttachment'
import { TaskApproval } from './TaskApproval'
import { Moment } from 'moment'
import { PreApprovalRefDate } from './PreApprovalRefDate'

export enum PreApprovalRequestStatus {
    Draft = 'draft',
    PendingApprove = 'pending_approval',
    PendingHRApprove = 'pending_hr_approval',
    Approved = 'approved',
    Reject = 'rejected',
    Withdraw = 'withdraw',
}

export interface PreApprovalRequest {
    id: number
    requestId?: string
    leaveTypeId?: number
    applicant?: number
    leaveNum?: number
    remarks?: string
    status?: PreApprovalRequestStatus
    nextApprover?: number
    createdBy?: number
    createdDate?: Moment
    modifiedBy?: number
    modifiedDate?: Moment

    leaveAttachment?: LeaveAttachment[]
    refDate?: PreApprovalRefDate[]
    reportingLine?: TaskApproval[]
}
