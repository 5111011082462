import { isNullOrUndefined } from "util"
import { ApplicationState } from "../store"
import { AuthenticateState } from "../store/AuthenticateStore"

const setJWT = (httpOptions: any, appState: ApplicationState | AuthenticateState) => {
    if (isNullOrUndefined(httpOptions))
        return;
    if (httpOptions.headers === null)
        throw Error('The HTTP header was set to null, please confirm.');

    let temp: AuthenticateState;

    let tempAsApp = (appState as ApplicationState);
    let tempAsAuth = (appState as AuthenticateState);

    if (tempAsApp.authenticate !== undefined) {
        temp = tempAsApp.authenticate
    } else if (tempAsAuth !== undefined) {
        temp = tempAsAuth;
    } else {
        throw Error("Type or object undefined");
    }

    if (temp && temp.user) {

        if (httpOptions.headers === undefined) {
            httpOptions.headers = {};
        }

        httpOptions.headers.Authorization = 'Bearer ' + temp.user.token;
    }
}

export default setJWT
