import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import moment from 'moment'
import { EmployeeType as EmployeeTypeModel } from '../model';
import { setJWT } from '../helper';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface EmployeeTypeState {
    isLoading: boolean;
    employeeTypeList: EmployeeTypeModel[];
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestEmployeeTypeAction {
    type: 'REQUEST_EMPLOYEETYPE';
}

interface ResultEmployeeTypeAction {
    type: 'RESULT_EMPLOYEETYPE';
    employeeTypeList: EmployeeTypeModel[];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
    RequestEmployeeTypeAction
    | ResultEmployeeTypeAction
    ;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestEmployeeType: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        let fetchDest = '';

        if (appState && appState.leavedRequest) {
            fetchDest = 'api/employeeType'

            let httpOptions: any = {};

            setJWT(httpOptions, appState);

            fetch(fetchDest, httpOptions)
                .then(response => response.json() as Promise<EmployeeTypeModel[]>)
                .then(data => {
                    dispatch({ type: 'RESULT_EMPLOYEETYPE', employeeTypeList: data });
                })
                .catch(() => {
                    dispatch({ type: 'RESULT_EMPLOYEETYPE', employeeTypeList: [] });
                })

            dispatch({ type: 'REQUEST_EMPLOYEETYPE' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: EmployeeTypeState = { employeeTypeList: [], isLoading: false };

export const reducer: Reducer<EmployeeTypeState> = (state: EmployeeTypeState | undefined, incomingAction: Action): EmployeeTypeState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_EMPLOYEETYPE':
            return {
                employeeTypeList: [],
                isLoading: true,
            };
        case 'RESULT_EMPLOYEETYPE':
            return {
                employeeTypeList: action.employeeTypeList,
                isLoading: false,
            };
    }

    return state;
};
