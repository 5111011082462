import React, { useEffect } from "react"
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../store';
import * as LeaveTypeStore from '../../store/LeaveTypeStore';

import { Column, EditCellColumnDef } from 'material-table';
import { PreApprovalRequest } from '../../model/PreApprovalRequest';

import { Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import UserAutocomplete from './UserAutocomplete';



type UserFilterProps =
    {
        columnDef: any,
        onFilterChanged: (rowId: string, value: any) => void,
    }

const UserFilter = (props: UserFilterProps): React.ReactElement<any> => {
    const { columnDef, onFilterChanged } = props;

    return (
        <UserAutocomplete
            onChange={(e, v, m) => {
                if (columnDef && columnDef.tableData && columnDef.tableData.id) {
                    onFilterChanged(columnDef.tableData.id, v.map(x => x.id.toString()))
                }
            }}
        />
    )
}

export default UserFilter;
