import React, { useEffect, useState, Fragment } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Grid,
    Paper,
    Theme,
    FormHelperText,
    createMuiTheme,
    MuiThemeProvider,
    Button,
    InputLabel,
    TextField,
    MenuItem,
    Select,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { tableIcons } from '../../bug-fix/mui-table-tableicons';
import MaterialTable from 'material-table';
import MuiTable from '../../i18n/MuiTable';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../theme';
import { IAttendance } from '../Attendance/AttendanceModel';
import { ApplicationState } from '../../store';
import * as AuthenticateStore from '../../store/AuthenticateStore';
import { setJWT } from '../../helper';
import Loading from '../../component/Loading';
import SelectFilter from '../../component/MuiTable/SelectFilter';
import {
    ReasonType as ReasonTypeModel,
} from '../../model';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover,
        // },
    },
    monthButton: {
        marginRight: 10,
        marginBottom: 10,
    },
    actionButton: {
        float: 'right',
        marginRight: 10,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    customRow: {
        height: 51
    },
    head: {
        // backgroundColor: '#c0c0c0',
    },
    headerCell: {
        fontWeight: 'bold',
        alignContent: 'left',
        whiteSpace: 'nowrap'
    },
    infoArea: {
        marginBottom: 10,
    },
    detailLabel: {
        marginTop: 8,
    },
    detailFontSize: {
        fontSize: 14
    },
}));




interface IAttendanceApproval {
    id: number
    applicantid?: number
    applicant?: string
    status?: string
    starttime?: string
    endtime?: string
    reasontypename?: string
    date?: string
    submitteddate?: string
}

type Action = "APPROVE" | "REJECT"


const mapStateToProps = (state: ApplicationState) => {
    return {
        auth: state.authenticate,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        ...AuthenticateStore.actionCreators,
    }, dispatch)
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type AttendanceListProps =
    {
        [x: string]: any;
    }
    & PropsFromRedux


type StatusFilterProps =
    {
        columnDef: any,
        onFilterChanged: (rowId: string, value: any) => void,
    }

const AttendanceRequest = (props: AttendanceListProps) => {

    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
        defaultMatches: true
    });

    const mtTheme = createMuiTheme({
        palette: {
            primary: {
                main: '#4caf50',
            },
            secondary: {
                main: '#5c6bc0',
            },
        },
        overrides: {
            // MuiTableCell: {
            //     root: {
            //         padding: '10px 10px',
            //     }
            // },
            MuiIconButton: {
                root: {
                    padding: 8
                }
            },
            MuiFormControlLabel: {
                root: {
                    marginBottom: 0
                }
            },
        },
    });

    const { className, auth, ...rest } = props;
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const [requests, setRequests] = useState<IAttendanceApproval[]>([]);
    const [selectedRequests, setSelectedRequests] = useState<IAttendanceApproval[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [master, setMaster] = useState({
        masterReasonTypes: []
    });


    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = () => {
        setIsLoading(true);
        let httpOptions: any = {};
        httpOptions.method = 'GET';
        httpOptions.headers = {
            'Content-Type': 'application/json'
        }

        if (auth) {
            setJWT(httpOptions, auth);
        }

        Promise.all([
            fetch('api/userattendance/approval'),
            fetch('api/reasontype'),
        ])
            .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
            .then(([data1, data2]) => {
                setRequests(data1);
                setMaster({ masterReasonTypes: data2 });
                setIsLoading(false);
            })
    }


    const reasonTypeFilter = (props: any): React.ReactElement<any> => {
        return (
            <Select
                fullWidth
                value={props.columnDef.tableData.filterValue || ''}
                onChange={(event) => {
                    props.onFilterChanged(props.columnDef.tableData.id, event.target.value);
                }}
            >
                <MenuItem value=''><em>None</em></MenuItem>
                {master.masterReasonTypes.map((option: ReasonTypeModel) =>
                    <MenuItem key={option.id} value={option.reasonTypeName}>{t("ddl.reasontype." + option.reasonTypeName)}</MenuItem>
                )}
            </Select>
        )
    }


    const tableState = {
        columns: [
            {
                title: t('attendance.applicant'),
                field: 'applicant',
            },
            {
                title: t('attendance.date'),
                field: 'date',
                render: (rowData: IAttendanceApproval) => moment(rowData.date).format('YYYY-MM-DD')
            },
            {
                title: t('attendance.reason'),
                field: 'reasontypename',
                lookup: {},
                filterComponent: reasonTypeFilter,
                hidden: !isDesktop,
                render: (rowData: IAttendanceApproval) => rowData.reasontypename == null ? '--' : t("ddl.reasontype." + rowData.reasontypename)
            },
            {
                title: t('attendance.start_time') + '/' + t('attendance.end_time'),
                hidden: !isDesktop,
                render: (rowData: IAttendanceApproval) => {
                    if (rowData.starttime == null && rowData.endtime == null)
                        return '--'
                    else
                        return rowData.starttime + ' - ' + rowData.endtime
                }
            },
            {
                title: t('attendance.submitted_date'),
                field: 'submitteddate',
                hidden: !isDesktop,
                render: (rowData: IAttendanceApproval) => moment(rowData.submitteddate).format('YYYY-MM-DD')
            },
            {
                title: t('status'),
                field: 'status',
                filterComponent: SelectFilter,
                hidden: !isDesktop,
                lookup: {
                    'pending_approval': t('common_status.pending_approval'),
                    'approved': t('common_status.approved'),
                    'rejected': t('common_status.rejected'),
                },
                render: (rowData: any) => rowData.status && t('common_status.' + rowData.status)
            },
        ]
    }



    const handleSelection = (val: IAttendanceApproval[]) => {
        setSelectedRequests(val.map(object => {
            return {
                id: object.id,
                applicantid: object.applicantid
            }
        }))
    }

    const handleAction = (action: Action) => {
        setIsLoading(true);
        let url = '';
        let jsonObjs: IAttendanceApproval[] = [];
        switch (action) {
            case "APPROVE":
                url = '/api/userattendance/approve';
                jsonObjs = selectedRequests;
                break;
            case "REJECT":
                url = '/api/userattendance/reject';
                jsonObjs = selectedRequests;
                break;
        }

        fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(jsonObjs)
        })
            .then(response => {
                if (response.ok) {
                    switch (action) {
                        case "APPROVE":
                            alert(t("attendance.message.approve_success"))
                            break;
                        case "REJECT":
                            alert(t("attendance.message.reject_success"))
                            break;
                    }
                    fetchData();
                }
                else {
                    throw new Error("response isnt ok")
                }

                setIsLoading(false);
            })
    }

    const MobileTable = () => {
        return (
            <Paper>
                <MuiThemeProvider theme={mtTheme}>
                    <MaterialTable
                        icons={tableIcons}
                        columns={tableState.columns}
                        data={requests}
                        localization={MuiTable(i18n)}
                        options={{
                            filtering: true,
                            showTitle: false,
                            pageSize: 10,
                            toolbar: false,
                            selection: true,
                            headerStyle: { backgroundColor: '#9e9e9e', color: 'white', whiteSpace: 'nowrap' },
                            detailPanelType: 'single'
                        }}
                        onSelectionChange={handleSelection}

                        // onRowClick={(event: any, rowData: any, togglePanel: any) => togglePanel()}
                        detailPanel={isDesktop ? [] : rowData =>
                            <React.Fragment>
                                <Grid style={{ padding: '10px 15px' }}
                                    container spacing={0}>
                                    <Grid item xs={12}>
                                        <FormHelperText>{t("attendance.reason")}</FormHelperText>
                                        {rowData.reasontypename == null ? '--' : t("ddl.reasontype." + rowData.reasontypename.toLowerCase())}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormHelperText>{t('attendance.start_time') + '/' + t('attendance.end_time')}</FormHelperText>
                                        {(rowData.starttime == null && rowData.endtime == null) ? '--' : rowData.starttime + ' - ' + rowData.endtime}
                                    </Grid>
                                    <Grid className={classes.detailLabel} item xs={12}>
                                        <FormHelperText>{t("attendance.status")}</FormHelperText>
                                        {rowData.status && t('common_status.' + rowData.status.toLowerCase())}
                                    </Grid>
                                    <Grid className={classes.detailLabel} item xs={12}>
                                        <FormHelperText>{t("attendance.submitted_date")}</FormHelperText>
                                        {moment(rowData.submitteddate).format('YYYY-MM-DD')}
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                    />
                </MuiThemeProvider>
            </Paper>
        )
    }

    return (
        <Fragment>
            <Card>
                <CardHeader
                    title={t('attendance.title')}
                />
                <Divider />
                <CardContent>
                    <MobileTable />
                </CardContent>
                <CardActions className={classes.actionButton}>
                    {
                        selectedRequests.length > 0 &&
                        <>
                            <Button color="secondary" variant="contained" onClick={() => handleAction('REJECT')}>{t('reject')}</Button>
                            <Button color="primary" variant="contained" onClick={() => handleAction('APPROVE')}>{t('approve')}</Button>
                        </>
                    }
                </CardActions>
                <div>
                    <Loading loading={isLoading} />
                </div>
            </Card>
        </Fragment>
    );
}

export default compose(
    connector,
)(AttendanceRequest as any) 