import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import moment from 'moment'
import { OvertimeRequest as OvertimeRequestModel,OvertimeRequestStatus } from '../model';
import { setJWT } from '../helper';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface OvertimeRequestState{
    isLoading: boolean;
    OvertimeRequestList: OvertimeRequestModel[];
}
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.


interface SubmitOvertimeRequestAction {
    type: 'SUBMIT_OVERTIME_REQUEST';
    overtimeRecord: OvertimeRequestModel;
}

interface ResultOvertimeRequestAction {
    type: 'RESULT_OVERTIME_REQUEST';
    overtimeRecord: OvertimeRequestModel;
}


interface RequestOvertimeRequestAction {
    type: 'REQUEST_OVERTIME_REQUEST';
}

interface ReceiveOvertimeRequestAction {
    type: 'RECEIVE_OVERTIME_REQUEST';
    overtimeRequestList: OvertimeRequestModel[];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
    SubmitOvertimeRequestAction
    | ResultOvertimeRequestAction
    | RequestOvertimeRequestAction
    | ReceiveOvertimeRequestAction
    ;
    // ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestOvertimeRecord: (StaffId?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        let fetchDest = '';

        if (appState && appState.overtimeRequest) {
           
            fetchDest = 'api/overtimeRequest'

            let httpOptions: any = {};

            setJWT(httpOptions, appState);

            fetch(fetchDest, httpOptions)
                .then(response => response.json() as Promise<OvertimeRequestModel[]>)
                .then(data => {
                    data.forEach(element => {
                        if (element.createdDate)
                            element.createdDate = moment(element.createdDate)
                        if (element.modifiedDate)
                            element.modifiedDate = moment(element.modifiedDate)
                    });
                    dispatch({ type: 'RECEIVE_OVERTIME_REQUEST', overtimeRequestList: data });
                })
                .catch(() => {

                    dispatch({ type: 'RECEIVE_OVERTIME_REQUEST', overtimeRequestList: [] });
                })

            dispatch({ type: 'REQUEST_OVERTIME_REQUEST' });
        }
    },
    
    createOvertimeRecord: (overtimeRecord: OvertimeRequestModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        let fetchDest = 'api/overtimeRecord'

        let httpOptions: any = {};
        httpOptions.method = 'POST';
        httpOptions.headers = {
            'Content-Type': 'application/json'
        }
        httpOptions.body = JSON.stringify({
            overtimeRecord: overtimeRecord
        })

        setJWT(httpOptions, appState);

        fetch(fetchDest, httpOptions)
            .then(response => response.json() as Promise<OvertimeRequestModel>)
            .then(data => {
                dispatch({ type: 'RESULT_OVERTIME_REQUEST', overtimeRecord: data });
            });

        dispatch({ type: 'SUBMIT_OVERTIME_REQUEST', overtimeRecord: overtimeRecord });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: OvertimeRequestState = { OvertimeRequestList: [], isLoading: false };

export const reducer: Reducer<OvertimeRequestState> = (state: OvertimeRequestState | undefined, incomingAction: Action): OvertimeRequestState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_OVERTIME_REQUEST':
            return {
                OvertimeRequestList:[],
                isLoading: true,
            };

        case 'RECEIVE_OVERTIME_REQUEST':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            return {
                OvertimeRequestList: action.overtimeRequestList,
                isLoading: false,
            };

        case 'SUBMIT_OVERTIME_REQUEST':
            return {
                OvertimeRequestList: state.OvertimeRequestList,
                isLoading: true,
            };
        case 'RESULT_OVERTIME_REQUEST':
            return {

                OvertimeRequestList: state.OvertimeRequestList,
                isLoading: false,
            };
    }

    return state;
};
