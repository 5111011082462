import React, { useEffect, useState } from 'react'

import { connect, ConnectedProps } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    TextField,
    Paper,
    createMuiTheme,
    MuiThemeProvider,
    Button,
    Grid,
} from '@material-ui/core';
import { DatePicker } from "@material-ui/pickers";
import { User as UserModel } from '../../model';
import { ApplicationState } from '../../store';
import * as AuthenticateStore from '../../store/AuthenticateStore';
import { useTranslation } from 'react-i18next';
import { tableIcons } from '../../bug-fix/mui-table-tableicons';
import MaterialTable from 'material-table';
import MuiTable from '../../i18n/MuiTable';
import moment from "moment";

const mtTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#4caf50',
        },
        secondary: {
            main: '#5c6bc0',
        },
    },
    overrides: {
    },
});

const mapStateToProps = (state: ApplicationState) => {
    return {
        auth: state.authenticate,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        ...AuthenticateStore.actionCreators,
    }, dispatch)
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type IAnnualLeaveBalanceReportModelProps =
    {
        [x: string]: any;
    }
    & PropsFromRedux

interface IAnnualLeaveBalanceModel {
    itcode: string
    username: string
    employeetype: string
    startdate: string
    enddate: string
    days: string
    used: string
}

interface ISearchProps {
    year: string
}


const AnnualLeaveBalanceReport = (props: IAnnualLeaveBalanceReportModelProps) => {
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<IAnnualLeaveBalanceModel[]>([]);
    const [searchProps, setSearchProps] = useState<ISearchProps>({
        year: moment().format('YYYY'),
    });

    const handleClick = () => {
        setIsLoading(true);
        fetch('api/report/AnnualLeaveBalanceByYear?year=' + searchProps.year)
            .then(response => response.json())
            .then(data => {
                setData(data.map((x: IAnnualLeaveBalanceModel) => {
                    return {
                        ...x,
                        startdate: moment(x.startdate).format('YYYY-MM-DD'),
                        enddate: moment(x.enddate).format('YYYY-MM-DD'),
                    }
                }))
                setIsLoading(false);
            })
    }

    const MTable = () => {
        return (
            <Paper>
                <MuiThemeProvider theme={mtTheme}>
                    <MaterialTable
                        icons={tableIcons}
                        columns={tableState.columns}
                        data={data}
                        localization={MuiTable(i18n)}
                        options={{
                            search: false,
                            showTitle: false,
                            pageSize: 10,
                            headerStyle: { backgroundColor: '#9e9e9e', color: 'white', whiteSpace: 'nowrap' },
                            exportButton: true,
                            exportFileName: 'Annual Leave Balance Report ' + moment().format('YYYY-MM-DDTHHmmss'),
                            exportAllData: true,
                        }}
                    />
                </MuiThemeProvider>
            </Paper>
        )
    }

    const tableState = {
        columns: [
            {
                title: 'ITcode',
                field: 'itcode',
            },
            {
                title: 'User Name',
                field: 'username',
            },
            {
                title: 'Employee Type',
                field: 'employeetype',
            },
            {
                title: 'Start Date',
                field: 'startdate',
            },
            {
                title: 'End Date',
                field: 'enddate',
            },
            {
                title: 'Days',
                field: 'days',
            },
            {
                title: 'Used',
                field: 'used',
            },
        ]
    }

    return (
        <>
            <Card>
                <CardHeader
                    title={t('report.annual_leave_balance.title')}
                />
                <Divider />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <DatePicker
                                label={t("report.annual_leave_balance.year")}
                                autoOk
                                views={["year"]}
                                value={searchProps.year}
                                onChange={(val: any) => {
                                    setSearchProps({
                                        year: moment(val).format('YYYY')
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                style={{ float: 'right' }}
                                color="primary"
                                variant="contained"
                                onClick={handleClick}
                            >
                                {t('report.annual_leave_balance.search')}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <MTable />
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </>

    )
}


export default compose(
    connector,
)(AnnualLeaveBalanceReport as any) 