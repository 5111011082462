import React, { Component } from 'react';


import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import * as AuthenticateStore from './store/AuthenticateStore';
import * as LeaveTypeStore from './store/LeaveTypeStore';
import * as UserStore from './store/UserStore';
import { useLocation } from 'react-router-dom'
import { RouteComponentProps } from 'react-router';

import SignIn from './views/SignIn';

import { ApplicationState } from './store';
import { isNullOrUndefined } from 'util';
import { withRouter } from "react-router-dom";
import Loading from './component/Loading';


type AuthenticateProp = {
  auth: AuthenticateStore.AuthenticateState
  children: React.ReactNode
}
  & typeof AuthenticateStore.actionCreators
  & typeof LeaveTypeStore.actionCreators
  & typeof UserStore.actionCreators
  & RouteComponentProps<{}>

type AuthenticateState = {
  error: boolean;
}

class Authenticate extends Component<AuthenticateProp, AuthenticateState> {

  constructor(prop: AuthenticateProp) {
    super(prop);

    this.state = {
      error: false,
    };
  }

  componentDidMount() {
    const urlParam = new URLSearchParams(window.location.search);
    const errorParam = urlParam.get('error');

    if (!isNullOrUndefined(errorParam)) { // if error occur, uuip will redirect to "?error=mapping_error&error_description=Maxim...."
      this.setState({ error: true });
    } else if (this.props.location.pathname === '/signin') {
    } else if (this.props.auth && !this.props.auth.isLoading) {
      this.props.Token();
    }

    // this.props.requestLeaveType();
    // this.props.requestUserRecord();
  }

  componentDidUpdate() {
    if (this.props.auth && !this.props.auth.isLoading && this.props.auth.LoginRequired && !this.state.error) {
      // window.location.href = "/Auth/oAuthLogin?returnUrl=" + window.location.pathname;
    }
  }

  private oAuthLogin(): void {
    window.location.href = "/Auth/oAuthLogin?returnUrl=" + window.location.pathname;
  }

  public render() {
    if (this.props.auth.isLoading) {
      return <Loading loading />
    }
    if (this.state.error) {
      return <div>Your account has been logged in multiple times, please try again in 30 minutes.</div>
    }
    if (this.props.auth.user)
      return <>{this.props.children}</>
    else
      return (<SignIn />);
      // return null
  }
}

export default withRouter(compose(
  connect((state: ApplicationState) => {
    const { authenticate } = state;
    return { auth: authenticate };
  }, (dispatch) => {
    return bindActionCreators({
      ...AuthenticateStore.actionCreators,
      ...LeaveTypeStore.actionCreators,
      ...UserStore.actionCreators,
    }, dispatch)
  }),
)(Authenticate as any))
