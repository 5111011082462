import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  mainContent: {
    paddingTop: 100,
    width: '100%',
    textAlign: 'center',
    marginTop: '10px'
  },
  errorCode: {
    fontSize: '72px',
    fontWeight: 'bold',
    color: '#4c79a5'
  },
  errorHeader: {
    fontSize: '30px',
    color: '#585858'
  },
  errorMessage: {
    fontSize: '15px',
    color: '#777777'
  }
}));

const Unauthorized = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        spacing={4}
      >
        <Grid
          item
          lg={6}
          xs={12}
        >
          <div className={classes.mainContent}>
            <div className={classes.errorCode}>401</div>
            <div className={classes.errorHeader}>You are not authorized to access</div>
            <div className={classes.errorMessage}>Sorry, but the page you are looking for is not authorized for you. please make sure you have typed the current URL.</div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Unauthorized;
