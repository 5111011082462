import React, { useEffect, useState } from 'react'

import { connect, ConnectedProps } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import clsx from 'clsx';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/list/main.css';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Autocomplete } from '@material-ui/lab';
import Switch from '@material-ui/core/Switch';
import { useTranslation } from 'react-i18next';
import './custom.css';
import sha256 from 'crypto-js/sha256';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import { User as UserModel, Region as RegionModel } from '../../model';
import { ApplicationState } from '../../store';
import * as AuthenticateStore from '../../store/AuthenticateStore';
import { zh_cn, zh_hk } from '../../i18n/translate';

require('@fullcalendar/core/locales-all');

const useStyles = makeStyles(() => ({
    root: {
    },
    calendarContent: {
        marginTop: 20
    },
    formControl: {
        float: 'right',
        marginTop: 40,
        marginBottom: 20,
        minWidth: 120,
    },
}));

interface IEvent {
    start?: Date
    end?: Date
    userId?: number
    color?: string
    title?: string
    description?: string
    backgroundColor?: string
    className?: string
    slot?: string
}

interface ISearchProps {
    peoples?: number[]
    reportingline?: boolean
    selectall?: boolean
}

const hashCode = (inputVal: number) => {
    let hash: string = sha256((inputVal * (new Date).getDate()).toString()).toString(CryptoJS.enc.Hex);
    return "#" + hash.slice(-6);
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        auth: state.authenticate,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        ...AuthenticateStore.actionCreators,
    }, dispatch)
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type CalendarProps =
    {
        [x: string]: any;
    }
    & PropsFromRedux

const Calendar = (props: CalendarProps) => {
    const { className, ...rest } = props;
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const [region, setRegion] = useState(0);
    const [events, setEvents] = useState<IEvent[]>([]);
    const [searchProps, setSearchProps] = useState<ISearchProps>({ peoples: [], reportingline: false, selectall: false });

    const [master, setMaster] = useState({
        Peoples: [],
        Regions: [],
    })

    // const [isLoading, setIsLoading] = useState(false);

    // INIT
    useEffect(() => {
        // fetch("/api/user?active=true").then(response => response.json()).then(data => setPeoples(data))
        Promise.all([
            fetch('/api/user?active=true'),
            fetch('/api/region'),
            fetch('/api/user/GetCurrentUser')
        ])
            .then(([res1, res2, res3]) => Promise.all([res1.json(), res2.json(), res3.json()]))
            .then(([data1, data2, data3]) => {
                setMaster({
                    Peoples: data1,
                    Regions: data2,
                })
                // setUser(data3);
                setRegion(data3.regionId);
                return fetch('/api/publicholiday?region=' + data3.regionId)
            })
            .then(response => response.json())
            .then(
                data => {
                    let events: any = [];
                    data.forEach((element: any) => events.push({
                        date: moment(element.date).format('YYYY-MM-DD'),
                        backgroundColor: '#ffa5a5',
                        rendering: 'background',
                        classNames: ['mybgevent']
                    }));
                    setEvents(events);
                }
            )
    }, []);

    const handleChangeRegion = (e: any) => {
        fetch("/api/publicholiday?region=" + e.target.value)
            .then(response => response.json())
            .then(
                data => {
                    let events: any = [];
                    data.forEach((element: any) => events.push({
                        date: moment(element.date).format('YYYY-MM-DD'),
                        backgroundColor: '#ffa5a5',
                        rendering: 'background',
                        classNames: ['mybgevent']
                    }));

                    setEvents(prevState => {
                        let allEvents = prevState;
                        let nonHolidayEvents = allEvents.filter((obj: any) => { return obj['classNames'] === undefined });
                        return [...nonHolidayEvents, ...events]
                    });

                    setRegion(e.target.value)

                }
            )
    };

    const handleChange = (e: any, val: any) => {
        let searchObj: ISearchProps = {};
        if (e.target.name === 'reportingline') {
            searchObj = { ...searchProps, reportingline: val };
            setSearchProps((prevState): any => {
                return {
                    ...prevState,
                    reportingline: val
                }
            });
        }
        else if (e.target.name === 'selectall') {
            searchObj = { ...searchProps, selectall: val };
            setSearchProps((prevState): any => {
                return {
                    ...prevState,
                    selectall: val
                }
            });
        }
        else {
            let selectedPeoples = val.map((a: any) => a.id);
            searchObj = { ...searchProps, peoples: selectedPeoples };
            setSearchProps((prevState): any => {
                return {
                    ...prevState,
                    peoples: selectedPeoples
                }
            });
        }

        fetch("/api/calendar", {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(searchObj)
        })
            .then(response => response.json())
            .then(
                data => {
                    let userId = data.map((item: any) => item.userid);
                    let unique = userId.filter((v: any, i: any, a: any) => a.indexOf(v) === i);

                    let peopleColors: any = [];
                    unique.forEach((element: any) => peopleColors.push({
                        userId: element,
                        color: hashCode(element)
                    }));

                    let events: IEvent[] = [];
                    data.forEach((element: any) => {

                        let _slot = '';
                        switch (element.slot) {
                            case null:
                            case 'FD':
                                _slot = ' (' + t('fd') + ')';
                                break;
                            case 'AM':
                                _slot = ' (' + t('am') + ')';
                                break;
                            case 'PM':
                                _slot = ' (' + t('pm') + ')';
                                break;
                            default:
                                _slot = element.slot;
                                break;
                        }

                        events.push({
                            userId: element.userid,
                            color: peopleColors.find((obj: any) => { return obj.userId === element.userid }).color,
                            start: element.start,
                            end: moment(element.end).endOf('day').toDate(),
                            // title: (element.leave ? t('ddl.leavetype.' + element.title) : t('ddl.reasontype.' + element.title) + ' ' + _slot) + ' ~ ' + element.username,
                            title: (function _title() {
                                if (element.leave)
                                    return `${t('ddl.leavetype.' + element.title)} ${_slot} ~ ${element.username}`
                                else
                                    return `${t('ddl.reasontype.' + element.title)} ${_slot} ~ ${element.username}`
                            })(),
                            description: element.username
                        })
                    });

                    setEvents((prevState): any => {
                        let allEvents = [...prevState];
                        let HolidayEvents = allEvents.filter((obj: any) => { return obj['classNames'] != null });
                        return [...HolidayEvents, ...events]
                    });

                }
            )
    };

    return (
        <Card
            // {...rest}
            className={clsx(classes.root, className)}
        >
            <CardHeader
                title={t("calendar.title")}
            />
            <Divider />
            <CardContent>
                {/* {props.auth.user && props.auth.user.userName} */}
                <Autocomplete
                    multiple
                    style={{ marginBottom: 8 }}
                    options={master.Peoples}
                    onChange={handleChange}
                    getOptionLabel={(option: UserModel) => option.upn}
                    blurOnSelect
                    renderInput={(params) =>
                        <TextField {...params}
                            label={t("calendar.people")}
                            name="people"
                            margin="dense"
                        />}

                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <div>
                                {option.upn} ({option.userName})
                            </div>
                        </React.Fragment>
                    )}
                />
                <FormControlLabel
                    name="reportingline"
                    value="default"
                    control={<Switch color="primary" />}
                    label={t("calendar.search_w_rptline")}
                    labelPlacement="end"
                    onChange={handleChange}
                />
                <FormControlLabel
                    name="selectall"
                    value="default"
                    control={<Switch color="primary" />}
                    label={t("calendar.select_all")}
                    labelPlacement="end"
                    onChange={handleChange}
                />
                <div className={classes.calendarContent}>
                    <FullCalendar
                        buttonText={{
                            today: t("calendar.today"),
                            month: t("calendar.month"),
                            list: t("calendar.list")
                        }}
                        noEventsMessage={t("calendar.no_events")}
                        locale={i18n.language === 'zn-HK' ? 'zn-tw' : i18n.language} // FullCalendar did not map zh-HK with Tran Chinese, hack with zh-TW values
                        displayEventTime={false}
                        height="parent"
                        defaultView="dayGridMonth"
                        businessHours={{ daysOfWeek: [1, 2, 3, 4, 5] }}
                        plugins={[dayGridPlugin, listPlugin]}
                        header={{
                            left: "prev,next today",
                            center: "title",
                            right: "dayGridMonth,listWeek"
                        }}
                        events={events}
                        eventRender={(info) => {
                            if (info.view.type === 'dayGridMonth') {
                                if (info.event.classNames.indexOf('mybgevent') === -1) {
                                    var dotEl = info.el.getElementsByClassName('fc-title')[0];
                                    dotEl.innerHTML = info.event.extendedProps.description;
                                }
                            }
                            else if (info.view.type === 'listWeek') {
                                // console.log(info);
                            }
                        }}
                        eventLimit={3}

                    />
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="region">{t("calendar.region")}</InputLabel>
                        <Select
                            value={region}
                            onChange={handleChangeRegion}
                            label="Region"
                            inputProps={{
                                name: 'region',
                                id: 'region',
                            }}
                            margin="dense"
                        >
                            {master.Regions
                                // .sort((a: RegionModel, b: RegionModel) => a.name > b.name ? 1 : -1)
                                .map((item: RegionModel) => <MenuItem key={item.id} value={item.id}>{t('region.' + item.name)}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>
            </CardContent>
        </Card >
    )
}


// export default Calendar;

export default compose(
    connector,
)(Calendar as any) 