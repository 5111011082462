import React from 'react';

import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    PropTypes,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { isNullOrUndefined } from 'util';

export interface ConfirmDialogProps {
    title: string;
    confirmMessage: string;

    variant?: 'text' | 'outlined' | 'contained';
    color?: PropTypes.Color;
    onCancel?: () => any;
    onConfirm: () => void;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
    const { title, confirmMessage, onCancel, onConfirm, variant, color } = props;

    const { t, i18n } = useTranslation();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        onConfirm();
        handleClose();
    };

    const handleCancel = () => {
        if (onCancel)
            onCancel();
        handleClose();
    };

    return (
        <React.Fragment>
            <Button variant={variant} color={color} onClick={handleClickOpen}>
                {title}
            </Button>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open={open}
            >
                <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
                <DialogContent dividers>
                    {confirmMessage}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="secondary">
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleConfirm} color="primary">
                        {t('confirm')}
                    </Button>
                </DialogActions>
            </Dialog></React.Fragment>
    );
}

export default ConfirmDialog;
