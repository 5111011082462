
import { UserState } from '../store/UserStore';
import { isNullOrUndefined } from 'util';

type userSortingProps = {
    field: string,
    userState: UserState | undefined
}

const userSorting = (props: userSortingProps) => (a: any, b: any): number => {
    if (isNullOrUndefined(props.userState) || props.userState.isLoading) {
        return 0;
    }

    let aField = a[props.field];
    let bField = b[props.field];
    let aUserName = '';
    let bUserName = '';

    if (!isNullOrUndefined(aField)) {
        let aUser = props.userState.userList.find(x => x.id == aField);
        if (!isNullOrUndefined(aUser)) {
            aUserName = aUser.userName;
        }
    }

    if (!isNullOrUndefined(bField)) {
        let bUser = props.userState.userList.find(x => x.id == bField);
        if (!isNullOrUndefined(bUser)) {
            bUserName = bUser.userName;
        }
    }

    return aUserName.localeCompare(bUserName);
}

export default userSorting
