import React, { useEffect, useState } from 'react'

import { connect, ConnectedProps } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Paper,
    createMuiTheme,
    MuiThemeProvider,
    MenuItem,
    IconButton,
    Button,
    Grid,
} from '@material-ui/core';
import { DatePicker } from "@material-ui/pickers";
import { User as UserModel } from '../../model';
import { ApplicationState } from '../../store';
import * as AuthenticateStore from '../../store/AuthenticateStore';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import { tableIcons } from '../../bug-fix/mui-table-tableicons';
import MaterialTable from 'material-table';
import MuiTable from '../../i18n/MuiTable';
import moment from "moment";

// const _filefy = require('filefy');

const mtTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#4caf50',
        },
        secondary: {
            main: '#5c6bc0',
        },
    },
    overrides: {
    },
});

const mapStateToProps = (state: ApplicationState) => {
    return {
        auth: state.authenticate,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        ...AuthenticateStore.actionCreators,
    }, dispatch)
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type OTClaimBreakdownReportProps =
    {
        [x: string]: any;
    }
    & PropsFromRedux

interface IOTClaimBreakdownModel {
    userid: number
    upn: string
    username: string
    overtimedate: Date
    fromtime: string
    totime: string
    reason: string
}

interface ISearchProps {
    users: string
    datefrom: string
    dateto: string
}


const OTClaimBreakdownReport = (props: OTClaimBreakdownReportProps) => {
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [master, setMaster] = useState({
        masterUsers: []
    });
    const [data, setData] = useState<IOTClaimBreakdownModel[]>([]);
    const [searchProps, setSearchProps] = useState<ISearchProps>({
        users: '',
        datefrom: moment().startOf('month').format('YYYY-MM-DD'),
        dateto: moment().endOf('month').format('YYYY-MM-DD'),
    });

    // INIT
    useEffect(() => {
        fetch('api/user')
            .then(response => response.json())
            .then(data => {
                setMaster({ masterUsers: data });
            })
    }, []);

    const handleClick = () => {
        setIsLoading(true);
        fetch('api/report/OTClaimBreakdown?datefrom=' + searchProps.datefrom + '&dateto=' + searchProps.dateto + '&users=' + searchProps.users)
            .then(response => response.json())
            .then(data => {
                setData(data.map((x: IOTClaimBreakdownModel) => {
                    return {
                        ...x,
                        overtimedate: moment(x.overtimedate).format('YYYY-MM-DD'),
                    }
                }))
                setIsLoading(false);
            })
    }

    const MTable = () => {
        return (
            <Paper>
                <MuiThemeProvider theme={mtTheme}>
                    <MaterialTable
                        icons={tableIcons}
                        columns={tableState.columns}
                        data={data}
                        localization={MuiTable(i18n)}
                        options={{
                            search: false,
                            showTitle: false,
                            pageSize: 10,
                            headerStyle: { backgroundColor: '#9e9e9e', color: 'white', whiteSpace: 'nowrap' },
                            exportButton: true,
                            exportFileName: 'Overtime Report ' + moment().format('YYYY-MM-DDTHHmmss'),
                            exportAllData: true,
                        }}
                    />
                </MuiThemeProvider>
            </Paper>
        )
    }

    const tableState = {
        columns: [
            {
                title: 'IT Code',
                field: 'upn',
            },
            {
                title: 'User Name',
                field: 'username',
            },
            {
                title: 'Date',
                field: 'overtimedate',
            },
            {
                title: 'From Time',
                field: 'fromtime',
            },
            {
                title: 'To Time',
                field: 'totime',
            },
            {
                title: 'Reason',
                field: 'reason',
            },
        ]
    }

    return (
        <>
            <Card>
                <CardHeader
                    title={t('report.ot_claim_breakdown.title')}
                />
                <Divider />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <Autocomplete
                                fullWidth
                                multiple
                                style={{ marginBottom: 8 }}
                                options={master.masterUsers}
                                onChange={(e: any, val: any) => {
                                    let selectedPeoples = val.map((a: any) => a.id)
                                    setSearchProps((prevState): any => {
                                        return {
                                            ...prevState,
                                            users: selectedPeoples.join(',')
                                        }
                                    })
                                }}
                                getOptionLabel={(option: UserModel) => option.upn}
                                blurOnSelect
                                renderInput={(params) =>
                                    <TextField {...params}
                                        label={t("report.ot_claim_breakdown.people")}
                                        name="people"
                                    />}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <div>{option.upn} ({option.userName})</div>
                                    </React.Fragment>
                                )}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <DatePicker
                                label={t("report.ot_claim_breakdown.datefrom")}
                                autoOk
                                format='YYYY-MM-DD'
                                value={searchProps.datefrom}
                                onChange={(val: any) => {
                                    setSearchProps((prevState): any => {
                                        return {
                                            ...prevState,
                                            datefrom: moment(val).format('YYYY-MM-DD')
                                        }
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <DatePicker
                                label={t("report.ot_claim_breakdown.dateto")}
                                autoOk
                                format='YYYY-MM-DD'
                                value={searchProps.dateto}
                                onChange={(val: any) => {
                                    setSearchProps((prevState): any => {
                                        return {
                                            ...prevState,
                                            dateto: moment(val).format('YYYY-MM-DD')
                                        }
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                style={{ float: 'right' }}
                                color="primary"
                                variant="contained"
                                onClick={handleClick}
                            >
                                {t('report.ot_claim_breakdown.search')}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <MTable />
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </>

    )
}


export default compose(
    connector,
)(OTClaimBreakdownReport as any) 