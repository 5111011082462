import { Action, Reducer } from 'redux'
import { AppThunkAction } from '.'

import moment from 'moment'
import { UserAnnuallyLeaveTaked as UserAnnuallyLeaveTakedModel } from '../model'
import { setJWT } from '../helper';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UserAnnuallyLeaveTakedState {
    initLoad: boolean
    isLoading: boolean
    UserAnnuallyLeaveTakedList: UserAnnuallyLeaveTakedModel[]
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestUserAnnuallyLeaveTakedAction {
    type: 'REQUEST_USERANNUALLYLEAVETAKEDREQUEST'
}

interface ReceiveUserAnnuallyLeaveTakedAction {
    type: 'RECEIVE_USERANNUALLYLEAVETAKEDREQUEST'
    preApprovalRequestList: UserAnnuallyLeaveTakedModel[]
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
    RequestUserAnnuallyLeaveTakedAction
    | ReceiveUserAnnuallyLeaveTakedAction
    ;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestUserAnnuallyLeaveTaked: (userid?: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.userAnnuallyLeaveTaked) {
            let fetchDest = 'api/userAnnuallyLeaveTaked';

            if (userid) {
                fetchDest += '/user/' + userid;
            }
            
            let httpOptions: any = {};

            setJWT(httpOptions, appState);

            fetch(fetchDest, httpOptions)
                .then(response => response.json() as Promise<UserAnnuallyLeaveTakedModel[]>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_USERANNUALLYLEAVETAKEDREQUEST', preApprovalRequestList: data });
                })
                .catch(() => {
                    dispatch({ type: 'RECEIVE_USERANNUALLYLEAVETAKEDREQUEST', preApprovalRequestList: [] });
                })

            dispatch({ type: 'REQUEST_USERANNUALLYLEAVETAKEDREQUEST' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: UserAnnuallyLeaveTakedState = { UserAnnuallyLeaveTakedList: [], isLoading: false, initLoad: false };

export const reducer: Reducer<UserAnnuallyLeaveTakedState> = (state: UserAnnuallyLeaveTakedState | undefined, incomingAction: Action): UserAnnuallyLeaveTakedState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_USERANNUALLYLEAVETAKEDREQUEST':
            return {
                UserAnnuallyLeaveTakedList: state.UserAnnuallyLeaveTakedList,
                isLoading: true,
                initLoad: true,
            };
        case 'RECEIVE_USERANNUALLYLEAVETAKEDREQUEST':
            return {
                UserAnnuallyLeaveTakedList: action.preApprovalRequestList,
                isLoading: false,
                initLoad: true,
            }
    }

    return state;
};
