import { Action, Reducer } from 'redux'
import { AppThunkAction } from '.'

import moment from 'moment'
import { TaskApproval as TaskApprovalModel } from '../model'
import { setJWT } from '../helper';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface TaskApprovalState {
    isLoading: boolean
    taskApprovalList: TaskApprovalModel[]
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface SubmitTaskApprovalAction {
    type: 'SUBMIT_TASKAPPROVAL'
}
interface ResultTaskApprovalAction {
    type: 'RESULT_TASKAPPROVAL'
    taskApproval: TaskApprovalModel | undefined
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
    SubmitTaskApprovalAction
    | ResultTaskApprovalAction
    ;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    hrApproval: (record: TaskApprovalModel, callback?: (goodresult: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.preApprovalRequest) {
            let fetchDest = 'api/taskApproval/hrApproval';

            let httpOptions: any = {};
            httpOptions.method = 'POST';
            httpOptions.headers = {
                'Content-Type': 'application/json'
            }
            httpOptions.body = JSON.stringify(record);

            setJWT(httpOptions, appState);

            fetch(fetchDest, httpOptions)
                .then(response => {
                    if (response.ok && response.status == 201) {
                        return response.json() as Promise<TaskApprovalModel>;
                    } else {
                        throw new Error('Not ok, request not accept by server');
                    }
                })
                .then(data => {
                    data.createdDate = moment(data.createdDate)
                    data.modifiedDate = moment(data.modifiedDate)
                    dispatch({ type: 'RESULT_TASKAPPROVAL', taskApproval: data });
                    if (callback) callback(true);
                })
                .catch((e) => {
                    console.warn(e);
                    dispatch({ type: 'RESULT_TASKAPPROVAL', taskApproval: undefined });
                    if (callback) callback(false);
                });

            dispatch({ type: 'SUBMIT_TASKAPPROVAL' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: TaskApprovalState = { taskApprovalList: [], isLoading: false };

export const reducer: Reducer<TaskApprovalState> = (state: TaskApprovalState | undefined, incomingAction: Action): TaskApprovalState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SUBMIT_TASKAPPROVAL':
            return {
                taskApprovalList: state.taskApprovalList,
                isLoading: true,
            };
        case 'RESULT_TASKAPPROVAL':
            if (action.taskApproval)
                return {
                    taskApprovalList: [...state.taskApprovalList, action.taskApproval],
                    isLoading: false,
                };
            else
                return {
                    taskApprovalList: state.taskApprovalList,
                    isLoading: false,
                };

    }

    return state;
};
