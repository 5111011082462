import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

import { UserStat as UserStatModel } from '../model';
import { setJWT } from '../helper';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface MyStatState {
    isLoading: boolean;
    myStat: UserStatModel | undefined;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface ResultMyStatAction {
    type: 'RESULT_MY_STAT';
    stat: UserStatModel;
}

interface FailureMyStatAction {
    type: 'FAIL_MY_STAT';
}

interface RequestMyStatAction {
    type: 'REQUEST_MY_STAT';
}
interface FailAuthenticateAction {
    type: 'FAIL_AUTHENTICATE';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
    ResultMyStatAction
    | FailureMyStatAction
    | RequestMyStatAction
    | FailAuthenticateAction
    ;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestMyStat: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.user) {
            let fetchDest = 'api/userStat/currentUser'

            let httpOptions: any = {
                credentials: 'same-origin',
                redirect: 'error',
            };

            setJWT(httpOptions, appState);

            fetch(fetchDest, httpOptions)
                .then(response => response.json() as Promise<UserStatModel>)
                .then(data => {
                    dispatch({ type: 'RESULT_MY_STAT', stat: data });
                })
                .catch(() => {
                    dispatch({ type: 'FAIL_MY_STAT' });
                    dispatch({ type: 'FAIL_AUTHENTICATE' });
                })

            dispatch({ type: 'REQUEST_MY_STAT' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: MyStatState = { myStat: undefined, isLoading: false };

export const reducer: Reducer<MyStatState> = (state: MyStatState | undefined, incomingAction: Action): MyStatState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_MY_STAT':
            return {
                myStat: state.myStat,
                isLoading: true,
            };

        case 'RESULT_MY_STAT':
            return {
                myStat: action.stat,
                isLoading: false,
            };

        case 'FAIL_MY_STAT':
            return {
                myStat: undefined,
                isLoading: false,
            };

    }

    return state;
};
