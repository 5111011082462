import { Action, Reducer } from 'redux'
import { AppThunkAction } from '.'

import { LeaveTypeEmployeeType as LeaveTypeEmployeeTypeModel } from '../model'
import { setJWT } from '../helper';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface LeaveTypeEmployeeTypeState {
    isLoading: boolean
    leaveTypePropsList: LeaveTypeEmployeeTypeModel[]
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.


interface RequestLeaveTypeAction {
    type: 'REQUEST_LEAVETYPEPROP'
}

interface ReceiveLeavTypeAction {
    type: 'RECEIVE_LEAVETYPEPROP'
    leaveTypePropsList: LeaveTypeEmployeeTypeModel[]
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
    RequestLeaveTypeAction
    | ReceiveLeavTypeAction
    ;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestLeaveTypeEmployeeType: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();


        if (appState && appState.leaveType) {
            let fetchDest = 'api/leaveTypeEmployeeType'

            let httpOptions: any = {};

            setJWT(httpOptions, appState);

            fetch(fetchDest, httpOptions)
                .then(response => response.json() as Promise<LeaveTypeEmployeeTypeModel[]>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_LEAVETYPEPROP', leaveTypePropsList: data });
                })
                .catch(() => {
                    dispatch({ type: 'RECEIVE_LEAVETYPEPROP', leaveTypePropsList: [] });
                })

            dispatch({ type: 'REQUEST_LEAVETYPEPROP' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: LeaveTypeEmployeeTypeState = { leaveTypePropsList: [], isLoading: false };

export const reducer: Reducer<LeaveTypeEmployeeTypeState> = (state: LeaveTypeEmployeeTypeState | undefined, incomingAction: Action): LeaveTypeEmployeeTypeState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_LEAVETYPEPROP':
            return {
                leaveTypePropsList: state.leaveTypePropsList,
                isLoading: true,
            };

        case 'RECEIVE_LEAVETYPEPROP':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            return {
                leaveTypePropsList: action.leaveTypePropsList,
                isLoading: false,
            };
    }

    return state;
};
