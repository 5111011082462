import * as React from 'react';
import moment from 'moment';
import { Moment } from "moment";
import { connect, ConnectedProps } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as AuthenticateStore from '../store/AuthenticateStore';
import * as OvertimeRequestStore from '../store/OvertimeRequestStore';
import * as UserStore from '../store/UserStore';

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

import { tableIcons } from '../bug-fix/mui-table-tableicons';
import MaterialTable, { Column } from 'material-table';
import MuiTable from '../i18n/MuiTable';
import { OvertimeRequestStatus, OvertimeRequest as OvertimeRequestModel } from '../model';
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    Button,
    IconButton,
    LinearProgress,
    Paper,
    Tab,
    Tabs,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
} from '@material-ui/core';
import {
    Edit as EditIcon,
} from '@material-ui/icons';

import UserFilter from '../component/User/UserFilter';
import UserName from '../component/UserName'
import SelectFilter from '../component/MuiTable/SelectFilter';
import userSorting from '../helper/userSorting';
import { number } from 'yup';
import { isNullOrUndefined } from 'util';

const mapStateToProps = (state: ApplicationState) => {
    return {
        auth: state.authenticate,
        users: state.user,
        overtimeRequest: state.overtimeRequest,
        employeeType: state.employeeType,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        ...UserStore.actionCreators,
        ...OvertimeRequestStore.actionCreators,
    }, dispatch)
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type OvertimeRequestProps =
    {
    }
    & RouteComponentProps<{}> // ... plus incoming routing parameters
    & WithTranslation
    & PropsFromRedux

const useStyles = {
    root: {
        width: '100%',
    },
    container: {
        maxHeight: '100%',
    },
};



type OvertimeRequestState = {
    columns: Array<Column<OvertimeRequestModel>>,
    filter?: number | undefined,
}

const defaultSort = (x: OvertimeRequestModel, y: OvertimeRequestModel): number => {
    if (// if both is pending approve or both not, order by submition date
        (
            (x.status == OvertimeRequestStatus.PendingApproval || x.status == OvertimeRequestStatus.PendingHRApproval)
            &&
            (y.status == OvertimeRequestStatus.PendingApproval || y.status == OvertimeRequestStatus.PendingHRApproval)
        )
        ||
        (
            (x.status != OvertimeRequestStatus.PendingApproval && x.status != OvertimeRequestStatus.PendingHRApproval)
            &&
            (y.status != OvertimeRequestStatus.PendingApproval && y.status != OvertimeRequestStatus.PendingHRApproval)
        )
    ) {
        if (isNullOrUndefined(x.createdDate) || isNullOrUndefined(y.createdDate))
            return 0;
        return 1 - x.createdDate.diff(y.createdDate);
    }

    if (x.status == OvertimeRequestStatus.PendingApproval || x.status == OvertimeRequestStatus.PendingHRApproval) {
        return -1;
    }

    if (y.status == OvertimeRequestStatus.PendingApproval || y.status == OvertimeRequestStatus.PendingHRApproval) {
        return 1;
    }
    return 0;
}

class OvertimeRequest extends React.PureComponent<OvertimeRequestProps, OvertimeRequestState> {


    constructor(prop: OvertimeRequestProps) {
        super(prop)

        this.state = {
            columns: [
                {
                    title: '',
                    sorting: false,
                    // cellStyle: {
                    //     width: "3rem",
                    //     maxWidth: "10%"
                    // },
                    width: 70,
                    render: rowData => <React.Fragment>
                        <IconButton
                            component={Link}
                            to={"/overtime/" + rowData.id}
                            size="small"
                        >
                            <EditIcon />
                        </IconButton>
                        {rowData.nextApprover == 1 &&
                            <IconButton
                                component={Link}
                                to={"/overtime/" + rowData.id}
                                size="small"
                            >
                                <EditIcon />
                            </IconButton>
                        }
                    </React.Fragment>
                },
                {
                    title: this.props.i18n.t('overtimeRequest.request_no'),
                    field: 'requestId',
                },
                {
                    title: this.props.i18n.t('overtimeRequest.applicant'),
                    field: 'applicant',
                    width: '20%',
                    render: rowData => <UserName userid={rowData.applicant ? rowData.applicant : 0} />,
                    customSort: userSorting({ field: 'applicant', userState: this.props.users }),
                    filterComponent: UserFilter,
                    lookup: {}, // empty is must, to let stupid MuiTable know is array of key
                },
                {
                    title: this.props.i18n.t('status'),
                    field: 'status',
                    filterComponent: SelectFilter,
                    render: rowData => rowData.status == null ? '' : this.props.i18n.t('common_status.' + rowData.status.toLowerCase()),
                    lookup: {
                        "pending_approval": this.props.i18n.t('common_status.pending_approval'),
                        "pending_hr_approval": this.props.i18n.t('common_status.pending_hr_approval'),
                        "approved": this.props.i18n.t('common_status.approved'),
                        "rejected": this.props.i18n.t('common_status.rejected'),
                        "withdraw": this.props.i18n.t('common_status.withdraw'),
                    },
                },
                {
                    title: this.props.i18n.t('create_at'),
                    field: 'createDate',
                    render: rowData => {
                        if (rowData.createdDate)
                            return (
                                <span>{rowData.createdDate.format('YYYY-MM-DD')}</span>
                            )
                        else
                            return (
                                <span>??????</span>
                            )
                    },
                },
            ],
            filter: 0,
        }
    }

    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        //this.ensureDataFetched();
    }

    public filteredData(): OvertimeRequestModel[] {
        let currentUserID = 0;
        if (this.props.auth !== undefined && this.props.auth.user) {
            currentUserID = this.props.auth.user.id;
        }

        if (this.props.overtimeRequest === undefined) {
            return [];
        }

        if (!isNullOrUndefined(this.state.filter) && this.state.filter > 0) {
            switch (this.state.filter) {
                case 1:
                    return this.props.overtimeRequest.OvertimeRequestList.filter(x => x.applicant == currentUserID);
                case 2:
                    if (this.props.auth !== undefined && this.props.auth.user && this.props.auth.user.hradmin) {
                        return this.props.overtimeRequest.OvertimeRequestList.filter(x => x.nextApprover == currentUserID || x.status == OvertimeRequestStatus.PendingHRApproval);
                    } else {
                        return this.props.overtimeRequest.OvertimeRequestList.filter(x => x.nextApprover == currentUserID);
                    }
                default:
                    return this.props.overtimeRequest.OvertimeRequestList;
            }
        }
        return this.props.overtimeRequest.OvertimeRequestList;
    }

    public render() {
        return (
            <React.Fragment>
                <Card>
                    <CardHeader
                        title={this.props.i18n.t('overtimeRequest.title')}
                    />
                    <Divider />
                    <CardContent>
                        {this.props.auth && this.props.auth.user && this.props.employeeType && this.props.employeeType.employeeTypeList.some(x => x.id === this.props.auth!.user!.employeeTypeId && x.enableOvertime) &&
                            <>
                                <div style={{ display: 'flex', marginBottom: 16 }}>
                                    <span style={{ flexGrow: 1 }}></span>
                                    <Button
                                        component={Link}
                                        to={"/overtime/add"}
                                        color="primary"
                                        variant="contained">{this.props.i18n.t('overtimeRequest.new')}</Button>
                                </div>
                            </>
                        }
                        {this.renderFsorecastsTable()}
                    </CardContent>
                </Card>
            </React.Fragment >
        );
    }

    private ensureDataFetched() {
        this.props.requestOvertimeRecord(undefined);
    }

    private renderFsorecastsTable() {
        return (
            <Paper>
                <Tabs
                    value={this.state.filter}
                    onChange={(e, v) => { this.setState({ filter: v }); }}
                    //onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label={this.props.i18n.t('all')} />
                    <Tab label={this.props.i18n.t('my_application')} />
                    <Tab label={this.props.i18n.t('waiting_my_approval')} />
                </Tabs>
                {this.props.overtimeRequest !== undefined && this.props.overtimeRequest.isLoading && <LinearProgress />}

                <MaterialTable
                    icons={tableIcons}
                    columns={this.state.columns}
                    data={this.filteredData().sort(defaultSort)}
                    isLoading={this.props.overtimeRequest === undefined || this.props.overtimeRequest.isLoading}
                    localization={MuiTable(this.props.i18n)}
                    options={{
                        filtering: true,
                        search: false,
                        showTitle: false,
                        pageSize: 10,
                        toolbar: false,
                        headerStyle: { backgroundColor: '#9e9e9e', color: 'white', whiteSpace: 'nowrap' },
                    }}
                />
            </Paper>
        );
    }

}

export default compose(
    withTranslation(),
    withStyles(useStyles),
    connector,
)(OvertimeRequest as any);
