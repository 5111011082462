import React, { useEffect } from "react"
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../store';
import * as UserStore from '../../store/UserStore';

import { User as UserModel } from '../../model';

import { Column, EditCellColumnDef } from 'material-table';
import { PreApprovalRequest } from '../../model/PreApprovalRequest';

import { Button, Checkbox, TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteProps, UseAutocompleteProps } from '@material-ui/lab'

import UserName from '../../component/UserName';


const mapStateToProps = (state: ApplicationState) => {
    return {
        user: state.user,
    }
}

const connector = connect(
    mapStateToProps,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type UserAutocompleteProps =
    {
        autoCompleteProps?: AutocompleteProps<UserModel>
        & UseAutocompleteProps<UserModel>
        onChange?: (event: React.ChangeEvent<{}>, value: UserModel[], reason: string) => void,
    }
    & PropsFromRedux

const UserAutocomplete = (props: UserAutocompleteProps): React.ReactElement<any> => {
    const { user, autoCompleteProps, onChange } = props;

    return (
        <React.Fragment>
            {user &&
                <Autocomplete
                    multiple
                    disableCloseOnSelect
                    size="small"
                    onChange={(e, v, r, d) => {
                        if (onChange !== undefined)
                            onChange(e, v, r);
                    }}
                    limitTags={1}
                    blurOnSelect
                    options={user.userList}
                    getOptionLabel={(option) => option.userName}
                    loading={user.isLoading}
                    renderOption={(params, { selected }) => (
                        <React.Fragment>
                            <UserName userid={params.id} />
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                        />
                    )}
                />
            }
        </React.Fragment>
    )
}

export default connector(UserAutocomplete);
