import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { bindActionCreators, compose } from 'redux';
import { ApplicationState } from './store';
import * as EmployeeTypeStore from './store/EmployeeTypeStore';
import * as LeaveTypeStore from './store/LeaveTypeStore';
import * as UserStore from './store/UserStore';
import * as MyStatStore from './store/MyStatStore';

const mapStateToProps = (state: ApplicationState) => {
    return {
        user: state.user,
        leaveType: state.leaveType,
    }
}

const mapDispatchToProps = (dispatch: any) => {

    return bindActionCreators({
        ...EmployeeTypeStore.actionCreators,
        ...LeaveTypeStore.actionCreators,
        ...UserStore.actionCreators,
        ...MyStatStore.actionCreators,
    }, dispatch)
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type CommonResourceProps =
    {
        children: React.ReactNode
    }
    & PropsFromRedux

const CommonResource = (props: CommonResourceProps): React.ReactElement<any> => {
    const { requestEmployeeType, requestMyStat, requestLeaveType, requestUserRecord,  children } = props

    let timmer;

    useEffect(() => {
        requestEmployeeType();
        requestLeaveType();
        requestUserRecord();
        requestMyStat();

        timmer = setInterval(() => {
            requestMyStat();
        }, 300000);
    }, []);
    return (<>{children}</>);
}

export default connector(CommonResource);