import React, { useEffect } from "react"
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../store';
import * as LeaveTypeStore from '../../store/LeaveTypeStore';

import { LeaveType as LeaveTypeModel } from '../../model';

import { Column, EditCellColumnDef } from 'material-table';
import { PreApprovalRequest } from '../../model/PreApprovalRequest';

import { Button, Checkbox, TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteProps, UseAutocompleteProps } from '@material-ui/lab'

import LeaveTypeName, { i18n_LeaveTypeName } from '../../component/LeaveTypeName';


const mapStateToProps = (state: ApplicationState) => {
    return {
        leaveType: state.leaveType,
    }
}

const connector = connect(
    mapStateToProps,
    LeaveTypeStore.actionCreators,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type LeaveTypeAutocompleteProps =
    {
        autoCompleteProps?: AutocompleteProps<LeaveTypeModel>
        & UseAutocompleteProps<LeaveTypeModel>
        onChange?: (event: React.ChangeEvent<{}>, value: LeaveTypeModel[], reason: string) => void,
    }
    & PropsFromRedux

const LeaveTypeAutocomplete = (props: LeaveTypeAutocompleteProps): React.ReactElement<any> => {
    const { leaveType, autoCompleteProps, onChange } = props;
    const { t, i18n } = useTranslation();
    
    return (
        <React.Fragment>
            {leaveType &&
                <Autocomplete
                    multiple
                    disableCloseOnSelect
                    size="small"
                    onChange={(e, v, r, d) => {
                        if (onChange !== undefined)
                            onChange(e, v, r);
                    }}
                    limitTags={1}
                    blurOnSelect
                    options={leaveType.leaveTypeList}
                    getOptionLabel={(option) => i18n_LeaveTypeName({
                        leaveTypeName: option.leaveTypeName,
                        i18n: i18n,
                    })}
                    loading={leaveType.isLoading}
                    renderOption={(params, { selected }) => (
                        <React.Fragment>
                            <LeaveTypeName leaveTypeId={params.id} />
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                        />
                    )}
                />
            }
        </React.Fragment>
    )
}

export default connector(LeaveTypeAutocomplete);
