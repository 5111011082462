import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { bindActionCreators, compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import i18next from "i18next";


import { makeStyles } from '@material-ui/styles';

import Loading from '../component/Loading';
import { ApplicationState } from '../store';
import * as AuthenticateStore from '../store/AuthenticateStore';

import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    Theme,
} from '@material-ui/core';

const oAuth = {
    scope: "",
    response_type: "code",
    redirect_uri: "http://10.200.0.241",
    client_id: "iEOQ9RvkBGUCGK1Ct3vE",
}

const useStyles = makeStyles((theme: Theme) => ({
}));

const mapStateToProps = (state: ApplicationState) => {
    return {
        auth: state.authenticate,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        ...AuthenticateStore.actionCreators,
    }, dispatch)
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type LoginFormProps =
    {
        [x: string]: any;
        classes: {
            root: string;
            container: string;
            TableCell: string;
        },
    }
    & RouteComponentProps<{ code?: string }>
    & PropsFromRedux

const LoginForm = (props: LoginFormProps) => {

    const { auth, history, match, location, login } = props


    const classes = useStyles();

    const [uo, setUo] = useState<Window | null>(null);
    const [username, setUsername] = useState<string>('');

    const oAuthPop = () => {
        let str = Object.entries(oAuth).map(([key, val]) => encodeURIComponent(key) + '=' + encodeURIComponent(val)).join('&');

        let popupWin = window.open("https://m.digitalchina.com/mga/sps/oauth/oauth20/authorize" + "?" + str, "_blank", "resizable=yes, scrollbars=yes, titlebar=no, width=800, height=900, top=10, left=10");

        if (popupWin) {
            setUo(popupWin);
            popupWin.focus();
        }
    }

    useEffect(() => {

        let qParams = new URLSearchParams(location.search);
        let code = qParams.get('code');

        if (code) {
            login(code);
        }
    }, []);


    const usernameChangeHandler = (event: any) => {
        setUsername(event.target.value);
    }

    const mockLogin = (username: string) => {
        props.mockLogin(username);
    }

    const logout = () => {
        props.logout();
    }

    return <React.Fragment>
        <Loading loading={auth === undefined || auth.isLoading} />

        <Card style={{ margin: '0px auto', maxWidth: 1200 }}>

            <CardHeader title='Switch User'>
            </CardHeader>
            <CardContent>

                <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                        <TextField id="standard-basic" onChange={usernameChangeHandler} label="Username" />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        {auth && auth.user && auth.user.userName}
                    </Grid>
                </Grid>
            </CardContent>
            <Divider />
            <CardActions style={{ float: 'right', marginRight: 10 }}>
                <Button onClick={() => { mockLogin(username) }}>Direct Login</Button>
            </CardActions>
        </Card>
    </React.Fragment>
}


export default compose(
    connector,
)(LoginForm as any) 
