import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { RouteComponentProps } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { ApplicationState } from '../store';
import { withTranslation, WithTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';

import { withRouter } from "react-router-dom";
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardContent,
} from '@material-ui/core';

// At runtime, Redux will merge together...
type SettingsProps = {}
    & RouteComponentProps<{}>
    & WithTranslation

const useStyles = {
    root: {
        width: '100%',
    },
    container: {
        maxHeight: '100%',
    },
};

type SettingsState = {
    region: string
}

class Settings extends React.PureComponent<SettingsProps, SettingsState> {


    constructor(prop: SettingsProps) {
        super(prop)

        this.state = {
            region: 'hk'
        }
    }

    // This method is called when the component is first added to the document
    public componentDidMount() {
        console.log(this.props.i18n);
        console.log(this.props.i18n.language);
        console.log(this.props.i18n.languages);
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        console.log(this.props.i18n.languages);
        //this.ensureDataFetched();
    }
    private changeLang(lang: string): any {
        this.props.i18n.changeLanguage(lang).then(() => {
            this.props.i18n.options.lng = lang
            this.props.history.replace('/settings')
        });
    }

    private currentLang(): 'en' | 'zh-HK' | 'zh-CN'{
        // check cn first because the zh-CN include fallback zh-HK,
        // which will make checking zh-HK alway true
        if (this.props.i18n.languages.includes('zh-CN')){
            return 'zh-CN';
        }

        if (this.props.i18n.languages.includes('zh-HK')){
            return 'zh-HK';
        }

        return 'en';
    }

    private isCurrentLang(lang: 'en' | 'zh-HK' | 'zh-CN'): boolean{
        return this.currentLang() == lang;
    }

    public render() {

        return (
            <React.Fragment>
                <Card>
                    <CardHeader title={this.props.t('settings.title')} />

                    <CardContent>
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                            <Button {...(this.isCurrentLang('en') ? { variant: "contained" } : {})} onClick={() => { this.changeLang('en') }}>English</Button>
                            <Button {...(this.isCurrentLang('zh-HK') ? { variant: "contained" } : {})} onClick={() => { this.changeLang('zh-HK') }} >繁體中文</Button>
                            <Button {...(this.isCurrentLang('zh-CN') ? { variant: "contained" } : {})} onClick={() => { this.changeLang('zh-CN') }}>簡體中文</Button>
                        </ButtonGroup>
                    </CardContent>
                </Card>
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
    }

}

export default compose(
    withRouter,
    withTranslation(),
    withStyles(useStyles),
)(Settings as any);
