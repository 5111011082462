import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";
import moment from 'moment'

// the translations
// (tip move them in a JSON file and import them)

i18n.on('languageChanged', function (lng) {
    moment.locale(lng);
})

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        //lng: "en",
        fallbackLng: {
            'zh': ['zh-HK'],
            'zh-TW': ['zh-HK'],
            'zh-Hant': ['zh-HK'],
            'zh-Hans': ['zh-CN'],
            'default': ['en'],
        },
        whitelist: ['en', 'zh', 'zh-TW','zh-Hant', 'zh-Hans', 'zh-HK', 'zh-CN'], 
        // this white list did not work for commmon concept of i18n
        // cannot found out the originallanguage and fallbacked language at same time
        nonExplicitWhitelist: true,

        saveMissing: false,

        detection: {
            //options: ['navigator']
        },

        //keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            wait: true,
            //useSuspense: false,
        },

        backend: {
            loadPath: '/static/i18n/{{lng}}.json',
        }
    });

export default i18n;