import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import moment, { Moment } from 'moment';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

import { PreApprovalRequest as PreApprovalRequestModel, PreApprovalRequestStatus } from '../model';
import * as PreApprovalRequestStore from '../store/PreApprovalRequestStore';
import { ApplicationState } from '../store';

import { Button, ButtonGroup, Card, Paper, Tab, Tabs, LinearProgress } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { tableIcons } from '../bug-fix/mui-table-tableicons';
import MaterialTable, { Column } from 'material-table';
import MuiTable from '../i18n/MuiTable';
import { useSelector } from 'react-redux';

import filter from '../type/filter';
import { isNullOrUndefined } from 'util';
import { YearSelection } from '@material-ui/pickers/views/Year/YearView';


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const mapStateToProps = (state: ApplicationState) => {
    return {
        preApprovalRequest: state.preApprovalRequest,
    }
}

const connector = connect(
    mapStateToProps,
    PreApprovalRequestStore.actionCreators,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type PreApprovalTableProps =
    {
        columns: Column<PreApprovalRequestModel>[]
        className?: string,
        quickFilter?: filter<PreApprovalRequestModel>[]
    }
    & PropsFromRedux

const PreApprovalTable = (prop: PreApprovalTableProps) => {
    const { className, preApprovalRequest, columns, quickFilter, ...rest } = prop;

    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const { requests } = useSelector(state => ({ requests: state }))

    const [qFilter, setQuickFilter] = useState<number | undefined>(0);

    useEffect(() => {
        prop.requestPreApprovalRequest();
    }, []);

    const tableData = () => {
        let data: PreApprovalRequestModel[] = [];
        if (preApprovalRequest) {
            data = preApprovalRequest.preApprovalRequestList;
        }
        if (quickFilter && !isNullOrUndefined(qFilter) && qFilter > 0) {
            data = data.filter(quickFilter[qFilter - 1].logic);
        }
        return data.sort((x, y) => {
            if (// if both is pending approve or both not, order by submition date
                (
                    (x.status == PreApprovalRequestStatus.PendingApprove || x.status == PreApprovalRequestStatus.PendingHRApprove)
                    &&
                    (y.status == PreApprovalRequestStatus.PendingApprove || y.status == PreApprovalRequestStatus.PendingHRApprove)
                )
                ||
                (
                    (x.status != PreApprovalRequestStatus.PendingApprove && x.status != PreApprovalRequestStatus.PendingHRApprove)
                    &&
                    (y.status != PreApprovalRequestStatus.PendingApprove && y.status != PreApprovalRequestStatus.PendingHRApprove)
                )
            ) {
                if (isNullOrUndefined(x.createdDate) || isNullOrUndefined(y.createdDate))
                    return 0;
                return 1 - x.createdDate.diff(y.createdDate);
            }

            if (x.status == PreApprovalRequestStatus.PendingApprove || x.status == PreApprovalRequestStatus.PendingHRApprove) {
                return -1;
            }

            if (y.status == PreApprovalRequestStatus.PendingApprove || y.status == PreApprovalRequestStatus.PendingHRApprove) {
                return 1;
            }

            return 0;
        });
    }

    return (
        <React.Fragment>
            <Paper>
                {!isNullOrUndefined(quickFilter) && quickFilter.length > 0 &&

                    <Tabs
                        value={qFilter}
                        onChange={(e, v) => { setQuickFilter(v) }}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label={t('all')} />
                        {
                            quickFilter.map((x, index) => (
                                <Tab label={x.title} key={index} />
                            ))
                        }
                    </Tabs>
                }
                {preApprovalRequest && preApprovalRequest.isLoading && <LinearProgress />}
                <MaterialTable
                    icons={tableIcons}
                    columns={columns}
                    data={tableData()}
                    isLoading={!preApprovalRequest || preApprovalRequest.isLoading}
                    localization={MuiTable(i18n)}
                    options={{
                        filtering: true,
                        headerStyle: {
                            backgroundColor: '#9e9e9e',
                            color: 'white',
                            whiteSpace: 'nowrap',
                        },
                        pageSize: 10,
                        search: false,
                        showTitle: false,
                        toolbar: false,
                    }}
                />
            </Paper>
        </React.Fragment >
    )
}

export default connector(PreApprovalTable);
