import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

import { connect, ConnectedProps } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Button,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Divider,
	Grid,
	TextField,
	Select,
	MenuItem,
	IconButton,
	Checkbox,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	FormControlLabel,
	FormHelperText,
} from '@material-ui/core';
import { DatePicker } from "@material-ui/pickers";
import {
	Add as AddIcon,
	Clear as DeleteIcon,
} from '@material-ui/icons';
import TimePicker from 'rc-time-picker';
import { useTranslation } from 'react-i18next';
import {
	User as UserModel,
	LeaveType as LeaveTypeModel,
	EmployeeType as EmployeeTypeModel,
	EmployeeTypeHistory as EmployeeTypeHistoryModel,
	LeaveTypeEmployeeType as LeaveTypeEmployeeTypeModel,
} from '../../model';
import { ApplicationState } from '../../store';
import * as AuthenticateStore from '../../store/AuthenticateStore';
import * as Yup from 'yup';
import { Field, Form, Formik, FormikProps, FieldArray, ErrorMessage } from 'formik';
import moment from "moment";
import Loading from '../../component/Loading';

import { isNullOrUndefined } from 'util';


const useStyles = makeStyles(() => ({
	root: {
	},
	calendarContent: {
		marginTop: 20
	},
	formControl: {
		float: 'right',
		marginTop: 40,
		marginBottom: 20,
		minWidth: 120,
	},
	actionButton: {
		float: 'right',
		marginRight: 10,
	},
}));

const mapStateToProps = (state: ApplicationState) => {
	return {
		auth: state.authenticate,
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return bindActionCreators({
		...AuthenticateStore.actionCreators,
	}, dispatch)
}

const connector = connect(
	mapStateToProps,
	mapDispatchToProps,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type EmployeeTypeProps =
	{
		[x: string]: any;
	}
	& PropsFromRedux

interface ILeaveTypeEmployeeType {
	id: number
	leaveTypeId?: number
	leaveTypeName?: string
	employeeTypeId?: number
	approvalLevel?: number | null
	leaveNum?: number | null
	oneOff?: boolean
	preApproval?: boolean
	preApprovalRefDate?: boolean
	allowDateBack?: boolean
	validFrom?: number | null
	validTo?: number | null
	consecutive?: boolean
	isActive?: boolean
}

interface IEmployeeType {
	id: number
	employeeTypeName: string
	overtimeApprovalLevel: number
	enableAttendance: boolean
	enableOvertime: boolean
	history: EmployeeTypeHistoryModel[]
	leaveTypes: ILeaveTypeEmployeeType[]
}


const EmployeeType = (props: EmployeeTypeProps) => {
	const { className } = props;
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const history = useHistory();

	const [employeeTypeRecord, setEmployeeTypeRecord] = useState<IEmployeeType>({
		id: 0,
		employeeTypeName: '',
		overtimeApprovalLevel: 1,
		enableAttendance: false,
		enableOvertime: false,
		history: [{
			id: 0,
			onDutyTime: '09:00',
			offDutyTime: '18:00',
			effectiveDate: null,
			effectiveEnd: null,
		}],
		leaveTypes: [{
			id: 0,
			leaveTypeId: undefined,
			approvalLevel: 1,
			leaveNum: null,
			preApproval: false,
			preApprovalRefDate: false,
			validTo: null,
			allowDateBack: false,
			consecutive: false,
			isActive: true,
		}],
	});

	const [master, setMaster] = useState({
		masterLeaveTypes: [],
		employeeTypeList: [],
	});
	const [isNew, setIsNew] = useState(true)
	const [isLoading, setIsLoading] = useState(false);


	// INIT
	useEffect(() => {
		let routeRecordId: number
		if (props.match.params
			&& props.match.params.recordId
			&& (routeRecordId = parseInt(props.match.params.recordId, 10))
			&& routeRecordId > 0) {
			setIsLoading(true);
			Promise.all([
				fetch('api/employeetype/' + routeRecordId),
				fetch('api/leavetype/GetLeaveTypeByEmployeeTypeID/' + routeRecordId),
				fetch('api/employeetypehistory/GetEmployeeTypeHistoryByEmployeeTypeId/' + routeRecordId),
			])
				.then(([res1, res2, res3]) => Promise.all([res1.json(), res2.json(), res3.json()]))
				.then(([data1, data2, data3]) => {
					let obj: IEmployeeType = {
						...data1,
						leaveTypes: data2,
						history: data3
					}

					setEmployeeTypeRecord(obj)
					setIsNew(false)
					setIsLoading(false);
				})
		}

		Promise.all([
			fetch('api/leavetype'),
			fetch('api/EmployeeType'),
		])
			.then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
			.then(([data1, data2]) => setMaster({ masterLeaveTypes: data1, employeeTypeList: data2 }))

	}, []);

	const validationSchema = () => {
		var obj = Yup.object({
			employeeTypeName: Yup.string().nullable()
				.required(t('administration.employeetype.form.required_name'))
				.test(
					'employeeTypeName',
					t('administration.employeetype.form.duplicated_name'),
					(value: any) => {
						if (props.match.params.recordId) {
							return !master.employeeTypeList.some((x: EmployeeTypeModel) => x.employeeTypeName == value && x.id != props.match.params.recordId)
						}
						else {
							return !master.employeeTypeList.some((x: EmployeeTypeModel) => x.employeeTypeName === value)
						}
					}
				),
			overtimeApprovalLevel: Yup.number()
				.required(t('administration.employeetype.form.required_overtime_approval_level'))
				.min(1, t('administration.employeetype.form.min_overtime_approval_level')),
			history: Yup.array().min(1, t('administration.employeetype.form.required_minimal')).of(
				Yup.object().shape({
					effectiveDate: Yup.date().nullable()
						.required(t('administration.employeetype.form.required_effectivedate')),
					effectiveEnd: Yup.date().nullable()
						.min(Yup.ref('effectiveDate'), "administration.employeetype.form.effectiveend_minimal")
				})),
			leaveTypes: Yup.array().min(1, t('administration.employeetype.form.required_minimal'))
				.when([], function (schema: any, options: any) {
					let count: any = {};
					let multiSelected: number[] = [];

					let sai: any[] = [];

					// TODO please help to check why the original value have two shape,
					if (options !== undefined && Array.isArray(options.originalValue)) {
						sai = options.originalValue;
					} else if (options !== undefined && options.originalValue !== undefined && Array.isArray(options.originalValue.leaveTypes)) {
						sai = options.originalValue.leaveTypes;
					} else {
						console.warn('cannot check duplicated leaveType');
						console.debug(options);
					}

					sai.forEach((item: any) => {
						if (typeof item.leaveTypeId === 'number') {
							if (typeof count[item.leaveTypeId] === 'number') {
								count[item.leaveTypeId]++;
							} else {
								count[item.leaveTypeId] = 1;
							}
						}
					});

					for (const [key, value] of Object.entries(count)) {
						let numKey = Number(key);
						let numVal = Number(value);

						if (isNaN(numKey) || isNaN(numVal)) {
							continue;
						}

						if (numVal > 1) {
							multiSelected.push(numKey);
						}
					}


					schema = schema.of(
						Yup.object().shape({
							leaveTypeId: Yup.number().nullable()
								.required(t('administration.employeetype.form.required_leavetype'))
								.notOneOf(multiSelected, t('administration.employeetype.form.duplicated_leave_type'))
						})
					);

					return schema;
				})
		})

		return obj
	}

	const onSubmit = (values: EmployeeTypeModel) => {
		setIsLoading(true);
		let method, url;
		if (isNew) {
			method = 'POST'
			url = 'api/employeetype'
		}
		else {
			method = 'PUT'
			url = 'api/employeetype/' + employeeTypeRecord.id
		}

		fetch(url, {
			method: method,
			cache: 'no-cache',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(values)
		})
			.then(response => {
				if (response.ok) {
					alert(t('administration.employeetype.message.success'))
					history.push('/employeetypelist');
				}
				else if (response.status === 409) {
					alert(t('administration.employeetype.message.duplicated'))
				}

				setIsLoading(false);
			})
			.catch(e => console.warn(e))
	};

	const textToNumber = (newVal: any, oldVal: any): number | null => {
		let retVal = null
		if (newVal === '') retVal = null
		else if (isFinite(newVal)) retVal = parseInt(newVal, 10)
		else retVal = oldVal

		return retVal
	}


	return (
		<>
			<Formik
				enableReinitialize={true}
				initialValues={employeeTypeRecord}
				validationSchema={validationSchema}
				onSubmit={onSubmit}>
				{
					(props: any) => {
						const {
							values,
							touched,
							errors,
							dirty,
							isSubmitting,
							handleChange,
							handleBlur,
							handleSubmit,
							handleReset,
							setFieldValue,
						} = props;
						return (
							<form onSubmit={handleSubmit}>
								<Card
									className={clsx(classes.root, className)}
								// style={{ margin: '0px auto', maxWidth: 1200 }}
								>
									<CardHeader
										title={t("administration.employeetype.title")}
									/>
									<Divider />
									<CardContent>
										<Grid container spacing={3}>
											<Grid item md={6} xs={12}>
												<TextField name="employeeTypeName"
													fullWidth
													label={t('administration.employeetype.employeetype_name')}
													value={values.employeeTypeName}
													onChange={handleChange}
													onBlur={handleBlur}
													helperText={(errors.employeeTypeName && touched.employeeTypeName) && errors.employeeTypeName}
													error={errors.employeeTypeName && touched.employeeTypeName}
													margin="dense"
												// InputProps={{ readOnly: !isNew, }}
												/>
											</Grid>
											<Grid item md={6} xs={12}>
												<FormControlLabel name="enableAttendance"
													label={t('administration.employeetype.enable_attendance')}
													control={
														<Checkbox
															color="primary"
															checked={values.enableAttendance}
															onChange={handleChange}
														/>
													}
												/>
											</Grid>

											<Grid item md={6} xs={12}>
												<TextField name="overtimeApprovalLevel"
													fullWidth
													type="number"
													label={t('administration.employeetype.overtime_approval_level')}
													value={values.overtimeApprovalLevel}
													onChange={handleChange}
													onBlur={handleBlur}
													helperText={(errors.overtimeApprovalLevel && touched.overtimeApprovalLevel) && errors.overtimeApprovalLevel}
													error={errors.overtimeApprovalLevel && touched.overtimeApprovalLevel}
													margin="dense"
												/>
											</Grid>
											<Grid item md={6} xs={12}>
												<FormControlLabel name="enableOvertime"
													label={t('administration.employeetype.enable_overtime')}
													control={
														<Checkbox
															color="primary"
															checked={values.enableOvertime}
															onChange={handleChange}
														/>
													}
												/>
											</Grid>

											<Grid item xs={12}>
												<FieldArray name="leaveTypes">
													{({ move, swap, push, insert, unshift, remove, pop }) => (
														<TableContainer component={Paper}>
															<Table>
																<TableHead>
																	<TableRow>
																		<TableCell style={{ minWidth: "2rem", width: "5%" }}>
																			<IconButton size="small" onClick={() => {
																				push({
																					id: 0,
																					leaveTypeId: '',
																					approvalLevel: 1,
																					leaveNum: null,
																					preApproval: false,
																					preApprovalRefDate: false,
																					validTo: null,
																					allowDateBack: false,
																					consecutive: false,
																					isActive: true,
																				})
																			}}>
																				<AddIcon />
																			</IconButton>
																		</TableCell>
																		<TableCell style={{ width: "20%" }}>{t('administration.employeetype.leave_type')}</TableCell>
																		<TableCell>{t('administration.employeetype.approval_level')}</TableCell>
																		<TableCell>{t('administration.employeetype.leavenum')}</TableCell>
																		<TableCell>{t('administration.employeetype.valid_to')}</TableCell>
																		<TableCell>{t('administration.employeetype.pre_approval')}</TableCell>
																		<TableCell>{t('administration.employeetype.ref_date')}</TableCell>
																		<TableCell>{t('administration.employeetype.dateback')}</TableCell>
																		<TableCell>{t('administration.employeetype.consecutive')}</TableCell>
																		<TableCell>{t('administration.employeetype.isactive')}</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	{values.leaveTypes.map((row: ILeaveTypeEmployeeType, index: number) => (
																		<TableRow key={index}>
																			<TableCell>
																				{row.id === 0 &&
																					<IconButton size="small" onClick={() => remove(index)}>
																						<DeleteIcon />
																					</IconButton>
																				}
																			</TableCell>
																			<TableCell component="th" scope="row">
																				<TextField
																					select
																					fullWidth
																					margin="dense"
																					value={row.leaveTypeId || ''}
																					onChange={(e: any) => {
																						e.persist();
																						props.setFieldValue(`leaveTypes[${index}].leaveTypeId`, e.target.value)
																					}}
																					error={
																						errors.leaveTypes
																						&& errors.leaveTypes[index]
																						&& errors.leaveTypes[index].leaveTypeId
																						&& touched.leaveTypes
																						&& touched.leaveTypes[index]
																						&& touched.leaveTypes[index].leaveTypeId
																					}
																					helperText={(errors.leaveTypes
																						&& errors.leaveTypes[index]
																						&& errors.leaveTypes[index].leaveTypeId
																						&& touched.leaveTypes
																						&& touched.leaveTypes[index]
																						&& touched.leaveTypes[index].leaveTypeId)
																						&& errors.leaveTypes[index].leaveTypeId
																					}
																				>
																					<MenuItem value=""><em>None</em></MenuItem>
																					{master.masterLeaveTypes.map((option: LeaveTypeModel) =>
																						<MenuItem key={option.id} value={option.id}>
																							{t('ddl.leavetype.' + option.leaveTypeName)}
																						</MenuItem>
																					)}
																				</TextField>
																			</TableCell>
																			<TableCell>
																				<TextField
																					value={row.approvalLevel}
																					onChange={(e: any) => {
																						e.persist();
																						props.setFieldValue(`leaveTypes[${index}].approvalLevel`, textToNumber(e.target.value, row.approvalLevel))
																					}}
																				/>
																			</TableCell>
																			<TableCell>
																				<TextField
																					value={row.leaveNum || ''}
																					onChange={(e: any) => {
																						e.persist();
																						props.setFieldValue(`leaveTypes[${index}].leaveNum`, textToNumber(e.target.value, row.leaveNum))
																					}}
																				/>
																			</TableCell>
																			<TableCell>
																				<TextField
																					value={row.validTo || ''}
																					onChange={(e: any) => {
																						e.persist();
																						props.setFieldValue(`leaveTypes[${index}].validTo`, textToNumber(e.target.value, row.validTo))
																					}}
																				/>
																			</TableCell>
																			<TableCell>
																				<Checkbox
																					size="small"
																					color="primary"
																					checked={row.preApproval}
																					onChange={(e: any) => {
																						e.persist();
																						props.setFieldValue(`leaveTypes[${index}].preApproval`, e.target.checked)
																					}}
																				/>
																			</TableCell>
																			<TableCell>
																				<Checkbox
																					size="small"
																					color="primary"
																					checked={row.preApprovalRefDate}
																					onChange={(e: any) => {
																						e.persist();
																						props.setFieldValue(`leaveTypes[${index}].preApprovalRefDate`, e.target.checked)
																					}}
																				/>
																			</TableCell>
																			<TableCell>
																				<Checkbox
																					size="small"
																					color="primary"
																					checked={row.allowDateBack}
																					onChange={(e: any) => {
																						e.persist();
																						props.setFieldValue(`leaveTypes[${index}].allowDateBack`, e.target.checked)
																					}}
																				/>
																			</TableCell>
																			<TableCell>
																				<Checkbox
																					size="small"
																					color="primary"
																					checked={row.consecutive}
																					onChange={(e: any) => {
																						e.persist();
																						props.setFieldValue(`leaveTypes[${index}].consecutive`, e.target.checked)
																					}}
																				/>
																			</TableCell>
																			<TableCell>
																				<Checkbox
																					size="small"
																					color="primary"
																					checked={row.isActive}
																					onChange={(e: any) => {
																						e.persist();
																						props.setFieldValue(`leaveTypes[${index}].isActive`, e.target.checked)
																					}}
																				/>
																			</TableCell>
																		</TableRow>
																	))}
																</TableBody>
															</Table>
														</TableContainer>
													)}
												</FieldArray>
												<ErrorMessage name="leaveTypes" render={msg => {
													if (typeof msg == 'string') {
														return (<FormHelperText error>{msg}</FormHelperText>)
													}
													else {
														return null;
													}
												}} />
											</Grid>

											<Grid item xs={12}>
												<FieldArray name="history">
													{({ move, swap, push, insert, unshift, remove, pop }) => (

														<TableContainer component={Paper}>
															<Table>
																<TableHead>
																	<TableRow>
																		<TableCell style={{ minWidth: "2rem", width: "5%" }}>
																			<IconButton size="small" onClick={() => {
																				push({
																					id: 0,
																					onDutyTime: '09:00',
																					offDutyTime: '18:00',
																					effectiveDate: null,
																					effectiveEnd: null,
																				})
																			}}>
																				<AddIcon />
																			</IconButton>
																		</TableCell>
																		<TableCell>{t('administration.employeetype.onduty_time')}</TableCell>
																		<TableCell>{t('administration.employeetype.offduty_time')}</TableCell>
																		<TableCell>{t('administration.employeetype.effective_date')}</TableCell>
																		<TableCell>{t('administration.employeetype.effective_end')}</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	{values.history.map((row: EmployeeTypeHistoryModel, index: number) => (
																		<TableRow key={index}>
																			<TableCell>
																				<IconButton size="small" onClick={() => remove(index)}>
																					<DeleteIcon />
																				</IconButton>
																			</TableCell>
																			<TableCell component="th" scope="row">
																				<TimePicker
																					minuteStep={15}
																					showSecond={false}
																					placeholder="--:--"
																					value={moment(row.onDutyTime, 'HH:mm')}
																					onChange={(e: any) => {
																						props.setFieldValue(`history[${index}].onDutyTime`, e.format('HH:mm'))
																					}}
																				>
																				</TimePicker>
																			</TableCell>
																			<TableCell>
																				<TimePicker
																					minuteStep={15}
																					showSecond={false}
																					placeholder="--:--"
																					value={moment(row.offDutyTime, 'HH:mm')}
																					onChange={(e: any) => {
																						props.setFieldValue(`history[${index}].offDutyTime`, e.format('HH:mm'))
																					}}
																				>
																				</TimePicker>
																			</TableCell>
																			<TableCell>
																				<DatePicker
																					autoOk
																					clearable
																					format='YYYY-MM-DD'
																					value={row.effectiveDate}
																					onChange={(e: any) => {
																						props.setFieldValue(`history[${index}].effectiveDate`, e == null ? null : e.format('YYYY-MM-DD'))
																					}}
																					okLabel={t('date_picker.ok')}
																					cancelLabel={t('date_picker.cancel')}
																					clearLabel={t('date_picker.clear')}
																					error={
																						errors.history
																						&& errors.history[index]
																						&& errors.history[index].effectiveDate
																						&& touched.history
																						&& touched.history[index]
																						&& touched.history[index].effectiveDate
																					}
																					helperText={(errors.history
																						&& errors.history[index]
																						&& errors.history[index].effectiveDate
																						&& touched.history
																						&& touched.history[index]
																						&& touched.history[index].effectiveDate)
																						&& errors.history[index].effectiveDate
																					}
																				/>
																			</TableCell>
																			<TableCell>
																				<DatePicker
																					autoOk
																					clearable
																					format='YYYY-MM-DD'
																					value={row.effectiveEnd}
																					onChange={(e: any) => {
																						props.setFieldValue(`history[${index}].effectiveEnd`, e == null ? null : e.format('YYYY-MM-DD'))
																					}}
																					okLabel={t('date_picker.ok')}
																					cancelLabel={t('date_picker.cancel')}
																					clearLabel={t('date_picker.clear')}
																					error={
																						errors.history
																						&& errors.history[index]
																						&& errors.history[index].effectiveEnd
																						&& touched.history
																						&& touched.history[index]
																						&& touched.history[index].effectiveEnd
																					}
																					helperText={(errors.history
																						&& errors.history[index]
																						&& errors.history[index].effectiveEnd
																						&& touched.history
																						&& touched.history[index]
																						&& touched.history[index].effectiveEnd)
																						&& errors.history[index].effectiveEnd
																					}
																				/>
																			</TableCell>
																		</TableRow>
																	))}
																</TableBody>
															</Table>
														</TableContainer>
													)}
												</FieldArray>
												<ErrorMessage name="history" render={msg => {
													if (typeof msg == 'string') {
														return (<FormHelperText error>{msg}</FormHelperText>)
													}
													else {
														return null;
													}
												}} />
											</Grid>

										</Grid>
									</CardContent>
									<CardActions className={classes.actionButton}>
										<Button variant="outlined" component={Link} to="/employeetypelist">{t('back')}</Button>
										<Button color="primary" variant="contained" type="submit">{t('save')}</Button>
									</CardActions>
									<div>
										<Loading loading={isLoading} />
									</div>
								</Card>
							</form>
						);
					}
				}
			</Formik >
		</>
	)
}


export default compose(
	connector,
)(EmployeeType as any) 