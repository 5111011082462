import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import UserInfo from './UserInfo';

import { ApplicationState } from '../store';
import * as AuthenticateStore from '../store/AuthenticateStore';


const mapStateToProps = (state: ApplicationState) => {
    return {
        auth: state.authenticate,
    }
}

const connector = connect(
    mapStateToProps,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type CurrentUserInfoProps =
    {
    }
    & PropsFromRedux

const CurrentUserInfo = (props: CurrentUserInfoProps) => {
    const { auth } = props;

    return <React.Fragment>

        {auth !== undefined && !auth.isLoading && auth.user &&
            <UserInfo user={auth.user} />
        }


    </React.Fragment>

};


export default compose(
    connector,
)(CurrentUserInfo) 