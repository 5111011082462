import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

import { User as UserModel } from '../model';
import { LeaveRequest } from '../views';
import { setJWT } from '../helper';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UserState {
    isLoading: boolean;
    focuseRequestId?: number;
    userList: UserModel[];
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface ResultUserAction {
    type: 'RESULT_USER';
    user: UserModel[];
}

interface RequestUserAction {
    type: 'REQUEST_USER';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
    ResultUserAction
    | RequestUserAction
    ;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestUserRecord: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.user) {
            let fetchDest = 'api/user/'

            let httpOptions: any = {};

            setJWT(httpOptions, appState);

            fetch(fetchDest, httpOptions)
                .then(response => response.json() as Promise<UserModel[]>)
                .then(data => {
                    dispatch({ type: 'RESULT_USER', user: data });
                })
                .catch(() => {
                    dispatch({ type: 'RESULT_USER', user: [] });
                })

            dispatch({ type: 'REQUEST_USER' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: UserState = { userList: [], isLoading: false };

export const reducer: Reducer<UserState> = (state: UserState | undefined, incomingAction: Action): UserState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_USER':
            return {
                userList: state.userList,
                isLoading: true,
            };

        case 'RESULT_USER':
            return {
                userList: action.user,
                isLoading: false,
            };

    }

    return state;
};
