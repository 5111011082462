import React, { Component, Suspense } from 'react';
import { Route } from 'react-router';


import { Router, useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';

import theme from './theme';
// import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import Routes from './Routes';
import Authenticate from './Authenticate';
import CommonResource from './CommonResource';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// pick a date util library
import MomentUtils from '@date-io/moment';

//import './custom.css'

import i18n from './i18n';

const browserHistory = createBrowserHistory();

export default class App extends Component {
  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={i18n.language}>
        <ThemeProvider theme={theme}>
          <Suspense fallback="loading">
            <Authenticate>
              <CommonResource>
                <Router history={browserHistory}>
                  <Routes />
                </Router>
              </CommonResource>
            </Authenticate>
          </Suspense>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}