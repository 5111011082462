import React, { useEffect } from "react"
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../store';
import * as LeaveTypeStore from '../store/LeaveTypeStore';
import i18next, { i18n } from "i18next";
import { useTranslation } from 'react-i18next';
import { isNullOrUndefined } from "util";

const mapStateToProps = (state: ApplicationState) => {
    return {
        leaveType: state.leaveType,
    }
}

const connector = connect(
    mapStateToProps,
    LeaveTypeStore.actionCreators,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type LeaveTypeNameProps =
    {
        leaveTypeId: number
    }
    & PropsFromRedux

type i18n_LeaveTypeNameProps =
    {
        leaveTypeName: string,
        i18n?: i18n,
    }

export const i18n_LeaveTypeName = (props: i18n_LeaveTypeNameProps): string => {
    const { leaveTypeName, i18n } = props;

    if (isNullOrUndefined(i18n)) {
        return i18next.t("ddl.leavetype." + leaveTypeName);
    } else {
        return i18n.t("ddl.leavetype." + leaveTypeName);
    }
}

const LeaveTypeName = (props: LeaveTypeNameProps) => {
    const { leaveTypeId, leaveType, requestLeaveType, ...rest } = props;
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (!leaveType)
            throw Error('leaveType state did not ready??');

        if (!leaveType.isLoading && (!leaveType.leaveTypeList || leaveType.leaveTypeList.length == 0)) {
            requestLeaveType();
        }
    }, []);

    const getLeaveTypeName = (): string => {
        if (leaveTypeId <= 0 || !leaveType || !leaveType.leaveTypeList || leaveType.leaveTypeList.length == 0)
            return '';
        var getLeaveType = leaveType.leaveTypeList.find(x => x.id == leaveTypeId)

        if (getLeaveType)
            return i18n_LeaveTypeName({
                leaveTypeName: getLeaveType.leaveTypeName,
                i18n: i18n,
            });
        else
            return '';
    }

    return (
        <React.Fragment>{getLeaveTypeName()}</React.Fragment>
    );
}


export default connector(LeaveTypeName)