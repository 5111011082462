
const convertFile = (file: File) => {
    return new Promise<string>((resolve, reject) => {
        // 建立FileReader物件
        let reader = new FileReader()
        // 註冊onload事件，取得result則resolve (會是一個Base64字串)
        reader.onload = () => {
            if (typeof reader.result === 'string') {
                resolve(reader.result.substr(reader.result.indexOf(',') + 1))
            }
            else throw Error('Type of result in-correct: ' + typeof reader.result)
        }
        // 註冊onerror事件，若發生error則reject
        reader.onerror = () => { reject(reader.error) }
        // 讀取檔案
        reader.readAsDataURL(file)
    })
}

export default convertFile