import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import i18next from "i18next";
import { Translation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Drawer } from '@material-ui/core';

import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ImageIcon from '@material-ui/icons/Image';
import TimelapseSharpIcon from '@material-ui/icons/TimelapseSharp';
import SettingsIcon from '@material-ui/icons/Settings';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PeopleIcon from '@material-ui/icons/People';
import LanguageIcon from '@material-ui/icons/Language';
import DescriptionIcon from '@material-ui/icons/Description';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

import SidebarNav from './SidebarNav';

import CurrentUserInfo from '../../component/CurrentUserInfo';

import { ApplicationState } from '../../store';
import { isNullOrUndefined } from 'util';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    // backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = (props: any) => {
  const { open, variant, onClose, onClick, className, ...rest } = props;

  const classes = useStyles();

  const isAdmin = (appState: ApplicationState): any => {
    if (isNullOrUndefined(appState.authenticate)) {
      return undefined;
    }
    return appState.authenticate.user && appState.authenticate.user.hradmin
  }

  const enableAttendance = (appState: ApplicationState): boolean => {
    if (isNullOrUndefined(appState.authenticate)
      || isNullOrUndefined(appState.authenticate.user)
      || isNullOrUndefined(appState.employeeType)) {
      return false;
    }

    let employeeTypeSetting = appState.employeeType.employeeTypeList.find(x => x.id == appState.authenticate!.user!.employeeTypeId);

    if (isNullOrUndefined(employeeTypeSetting)) {
      return false;
    }

    return employeeTypeSetting.enableAttendance;
  }

  const enableOvertime = (appState: ApplicationState): boolean => {
    if (isNullOrUndefined(appState.authenticate)
      || isNullOrUndefined(appState.authenticate.user)
      || isNullOrUndefined(appState.employeeType)) {
      return false;
    }

    let employeeTypeSetting = appState.employeeType.employeeTypeList.find(x => x.id == appState.authenticate!.user!.employeeTypeId);

    if (isNullOrUndefined(employeeTypeSetting)) {
      return false;
    }

    return employeeTypeSetting.enableOvertime;
  }


  const pages = (t: any) => [
    {
      name: 'leave_request',
      title: t('leave_request.title'),
      href: '/leave_request',
      icon: <LibraryBooksIcon />,
      display: (appState: ApplicationState): boolean | undefined => true,
      badge: (appState: ApplicationState): number | undefined => {
        if (isNullOrUndefined(appState.authenticate)
          || isNullOrUndefined(appState.authenticate.user)
          || isNullOrUndefined(appState.myStat)
          || isNullOrUndefined(appState.myStat.myStat)) {
          return undefined;
        }
        return appState.myStat.myStat.pendingLeaveRequest;
      },
    },
    {
      name: 'pre_approval',
      title: t('pre_approval.title'),
      href: '/pre_approval',
      icon: <EventAvailableIcon />,
      display: (appState: ApplicationState): boolean | undefined => true,
      badge: (appState: ApplicationState): number | undefined => {
        if (isNullOrUndefined(appState.authenticate)
          || isNullOrUndefined(appState.authenticate.user)
          || isNullOrUndefined(appState.myStat)
          || isNullOrUndefined(appState.myStat.myStat)) {
          return undefined;
        }
        let self: number = 0;
        let hr: number = 0;
        if (appState.authenticate.user.hradmin
          && appState.myStat.myStat.preApprovalPendingHraction) {
          hr = appState.myStat.myStat.preApprovalPendingHraction;
        }

        if (appState.myStat.myStat.pendingPreApproval) {
          self = appState.myStat.myStat.pendingPreApproval;
        }

        return self + hr;
      },
    },
    {
      name: 'attendance',
      title: t('attendance.title'),
      icon: <PeopleIcon />,
      display: (appState: ApplicationState): boolean | undefined => true, // enableAttendance(appState),
      children: [
        {
          title: t('attendance.view_attendnace'),
          href: '/attendance',
        },
        {
          title: t('attendance.approve_attendance'),
          href: '/attendancelist',
          badge: (appState: ApplicationState): number | undefined => {
            if (isNullOrUndefined(appState.authenticate)
              || isNullOrUndefined(appState.authenticate.user)
              || isNullOrUndefined(appState.myStat)
              || isNullOrUndefined(appState.myStat.myStat)) {
              return undefined;
            }
            return appState.myStat.myStat.pendingAttendanceApproval;
          },
        }
      ]
    },
    {
      name: 'overtime',
      title: t('overtimeRequest.title'),
      href: '/overtime',
      icon: <TimelapseSharpIcon />,
      display: (appState: ApplicationState): boolean | undefined => true, // enableOvertime(appState) || isAdmin(appState),
      badge: (appState: ApplicationState): number | undefined => {
        if (isNullOrUndefined(appState.authenticate)
          || isNullOrUndefined(appState.authenticate.user)
          || isNullOrUndefined(appState.myStat)
          || isNullOrUndefined(appState.myStat.myStat)) {
          return undefined;
        }
        let self: number = 0;
        let hr: number = 0;
        if (appState.authenticate.user.hradmin
          && appState.myStat.myStat.otclaimPendingHraction) {
          hr = appState.myStat.myStat.otclaimPendingHraction;
        }

        if (appState.myStat.myStat.pendingOtclaimApproval) {
          self = appState.myStat.myStat.pendingOtclaimApproval;
        }

        return self + hr;
      },
    },
    {
      name: 'calendar',
      title: t('calendar.title'),
      href: '/calendar',
      icon: <DateRangeIcon />,
      display: (appState: ApplicationState): boolean | undefined => true,
    },
    {
      name: 'administration',
      title: t('administration.title'),
      icon: <SettingsIcon />,
      display: (appState: ApplicationState): boolean | undefined => isAdmin(appState),
      children: [
        {
          title: t('administration.user.title'),
          href: '/userlist',
        },
        {
          title: t('administration.employeetype.title'),
          href: '/employeetypelist',
        }
      ]
    },
    {
      name: 'report',
      title: t('report.title'),
      icon: <DescriptionIcon />,
      display: (appState: ApplicationState): boolean | undefined => isAdmin(appState),
      children: [
        {
          title: t('report.attendance_by_date.title'),
          href: '/attendance_by_date_report',
        },
        {
          title: t('report.attendance_summary.title'),
          href: '/attendance_summary_report',
        },
        {
          title: t('report.raw_access.title'),
          href: '/raw_access_report',
        },
        {
          title: t('report.annual_leave_balance.title'),
          href: '/annual_leave_balance_report',
        },
        {
          title: t('report.leave_summary.title'),
          href: '/leave_summary_report',
        },
        {
          title: t('report.ot_claim_breakdown.title'),
          href: '/ot_breakdown_report',
        },
      ]
    },
    {
      name: 'settings',
      title: t('settings.title'),
      href: '/settings',
      icon: <LanguageIcon />,
      display: (appState: ApplicationState): boolean | undefined => true,
    },
    // {
    //   name: 'login',
    //   title: t('Switch User'),
    //   href: '/templogin',
    //   icon: <SupervisedUserCircleIcon />,
    //   display: (appState: ApplicationState): boolean | undefined => true,
    // },
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CurrentUserInfo />
        <Divider className={classes.divider} />
        <Translation>
          {
            (t, { i18n }) =>
              <SidebarNav
                className={classes.nav}
                pages={pages(t)}
                onClick={onClick}
              />
          }
        </Translation>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
