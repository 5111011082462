import React from "react"
import {
    MenuItem,
    Select,
} from '@material-ui/core';


type SelectFilterProps =
    {
        columnDef: any,
        onFilterChanged: (rowId: string, value: any) => void,
    }

const SelectFilter = (props: SelectFilterProps): React.ReactElement<any> => {
    const { columnDef, onFilterChanged } = props;

    if (columnDef == undefined || columnDef.lookup == undefined){
        throw Error('lookup values in Column definition must be defined');
    }

    return (
        <Select
            fullWidth
            value={props.columnDef.tableData.filterValue || ''}
            onChange={(event) => {
                props.onFilterChanged(props.columnDef.tableData.id, event.target.value);
            }}
        >
            <MenuItem value=''><em>--</em></MenuItem>
            {Object.keys(columnDef.lookup).map((idx: string) => (
                    <MenuItem key={idx} value={idx}>{columnDef.lookup[idx]}</MenuItem>)
                )
            }
        </Select>
    )
}

export default SelectFilter;
