import { i18n } from "i18next";
import { Localization } from 'material-table'

const MuiTable = (i18next: i18n): Localization => {
    let translate: Localization = {
        body: {
            emptyDataSourceMessage: i18next.t('MuiTable.body.emptyDataSourceMessage'),
            addTooltip: i18next.t('MuiTable.body.addTooltip'),
            deleteTooltip: i18next.t('MuiTable.body.deleteTooltip'),
            editTooltip: i18next.t('MuiTable.body.editTooltip'),
            filterRow: {
                filterTooltip: i18next.t('MuiTable.body.filterRow.filterTooltip'),
            },
            editRow: {
                deleteText: i18next.t('MuiTable.body.editRow.deleteText'),
                cancelTooltip: i18next.t('MuiTable.body.editRow.cancelTooltip'),
                saveTooltip: i18next.t('MuiTable.body.editRow.saveTooltip'),
            },
        },
        grouping: {
            placeholder: i18next.t('MuiTable.grouping.placeholder'),
            groupedBy: i18next.t('MuiTable.grouping.groupedBy'),
        },
        header: {
            actions: i18next.t('MuiTable.header.actions'),
        },
        pagination: {
            labelDisplayedRows: i18next.t('MuiTable.pagination.labelDisplayedRows'),
            labelRowsSelect: i18next.t('MuiTable.pagination.labelRowsSelect'),
            labelRowsPerPage: i18next.t('MuiTable.pagination.labelRowsPerPage'),
            firstAriaLabel: i18next.t('MuiTable.pagination.firstAriaLabel'),
            firstTooltip: i18next.t('MuiTable.pagination.firstTooltip'),
            previousAriaLabel: i18next.t('MuiTable.pagination.previousAriaLabel'),
            previousTooltip: i18next.t('MuiTable.pagination.previousTooltip'),
            nextAriaLabel: i18next.t('MuiTable.pagination.nextAriaLabel'),
            nextTooltip: i18next.t('MuiTable.pagination.nextTooltip'),
            lastAriaLabel: i18next.t('MuiTable.pagination.lastAriaLabel'),
            lastTooltip: i18next.t('MuiTable.pagination.lastTooltip'),
        },
        toolbar: {
            addRemoveColumns: i18next.t('MuiTable.toolbar.addRemoveColumns'),
            nRowsSelected: i18next.t('MuiTable.toolbar.nRowsSelected'),
            showColumnsTitle: i18next.t('MuiTable.toolbar.showColumnsTitle'),
            showColumnsAriaLabel: i18next.t('MuiTable.toolbar.showColumnsAriaLabel'),
            exportTitle: i18next.t('MuiTable.toolbar.exportTitle'),
            exportAriaLabel: i18next.t('MuiTable.toolbar.exportAriaLabel'),
            exportCSVName: i18next.t('MuiTable.toolbar.exportCSVName'),
            exportPDFName: i18next.t('MuiTable.toolbar.exportPDFName'),
            searchTooltip: i18next.t('MuiTable.toolbar.searchTooltip'),
            searchPlaceholder: i18next.t('MuiTable.toolbar.searchPlaceholder'),
        },
    };

    return translate;
}

export default MuiTable