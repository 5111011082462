import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

import { connect, ConnectedProps } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Button,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Divider,
	Grid,
	Select,
	MenuItem,
	Checkbox,
	TextField,
	FormControlLabel,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
	User as UserModel,
	Region as RegionModel,
	EmployeeType as EmployeeTypeModel,
} from '../../model';
import { ApplicationState } from '../../store';
import * as AuthenticateStore from '../../store/AuthenticateStore';
import * as Yup from 'yup';
import { Field, Form, Formik, FormikProps, FieldArray, ErrorMessage } from 'formik';
import Loading from '../../component/Loading';


const useStyles = makeStyles(() => ({
	root: {
	},
	calendarContent: {
		marginTop: 20
	},
	formControl: {
		float: 'right',
		marginTop: 40,
		marginBottom: 20,
		minWidth: 120,
	},
	actionButton: {
		float: 'right',
		marginRight: 10,
	},
}));

interface IUser {
	id?: number
	upn?: string
	username?: string
	email?: string
	employeetypeid?: number
	birthDay?: Date
	regionid?: number
	entitiy?: string
	entitledleave?: number
	hradmin?: boolean
	activestatus?: boolean
	token?: string
	reporttoid?: number
}

const mapStateToProps = (state: ApplicationState) => {
	return {
		auth: state.authenticate,
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return bindActionCreators({
		...AuthenticateStore.actionCreators,
	}, dispatch)
}

const connector = connect(
	mapStateToProps,
	mapDispatchToProps,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type UserProps =
	{
		[x: string]: any;
	}
	& PropsFromRedux


const User = (props: UserProps) => {
	const { className } = props;
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const [userRecord, setUserRecord] = useState({
		id: 0,
		upn: '',
		username: '',
		email: '',
		employeetypeid: undefined,
		regionid: undefined,
		entitledleave: 0,
		hradmin: false,
		reporttoid: undefined,
		activestatus: true,
	});

	const [master, setMaster] = useState({
		Peoples: [],
		Regions: [],
		EmployeeType: [],
	})

	const [isNew, setIsNew] = useState(true)

	const [isLoading, setIsLoading] = useState(false);

	// INIT
	useEffect(() => {
		setIsLoading(true);
		let routeRecordId: number
		if (props.match.params
			&& props.match.params.recordId
			&& (routeRecordId = parseInt(props.match.params.recordId, 10))
			&& routeRecordId > 0) {
			fetch('api/user/GetUserDetails/' + routeRecordId)
				.then(response => response.json())
				.then(data => {
					setUserRecord(data)
					setIsNew(false)
				})
		}

		Promise.all([
			fetch('/api/user?active=true'),
			fetch('/api/region'),
			fetch('/api/employeetype'),
		])
			.then(([res1, res2, res3]) => Promise.all([res1.json(), res2.json(), res3.json()]))
			.then(([data1, data2, data3]) => {
				setMaster({
					Peoples: data1,
					Regions: data2,
					EmployeeType: data3,
				})
				setIsLoading(false);
			})
	}, []);


	const validationSchema = () => {
		var obj = Yup.object({
			upn: Yup.string()
				.required(t('administration.user.form.required_upn')),
			// .test(
			// 	'upn',
			// 	t('administration.user.form.duplicate_upn'),
			// 	async (value: any) => (await fetch(`api/user/check/${value}`)).json() === 'true',
			// ),
			username: Yup.string().nullable()
				.required(t('administration.user.form.required_username')),
			email: Yup.string().nullable()
				.required(t('administration.user.form.required_email'))
				.email(t('administration.user.form.format_email')),
			employeetypeid: Yup.number()
				.required(t('administration.user.form.required_employee_type')),
			regionid: Yup.number()
				.required(t('administration.user.form.required_region')),
			entitledleave: Yup.number()
				.required(t('administration.user.form.required_entitled_leave'))
				.min(0, t('administration.user.form.min_leave_num'))
				.test(
					'entitledleave',
					t('administration.user.form.invalid_leave_num'),
					(value: number) => (value % 0.5) === 0
				)
			// reporttoid: Yup.number()
			// 	.required(t('administration.user.form.required_report_to')),
		})

		return obj
	}

	const onSubmit = (values: IUser) => {
		setIsLoading(true);
		let method, url;
		if (isNew) {
			method = 'POST'
			url = 'api/user'
		}
		else {
			method = 'PUT'
			url = 'api/user/' + userRecord.id
		}

		fetch(url, {
			method: method,
			cache: 'no-cache',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(values)
		})
			.then(response => {
				if (response.ok) {
					alert(t("administration.user.message.success"))
					history.push('/userlist');
				}
				else if (response.status == 409) {
					alert(t("administration.user.message.duplicated"))
					setIsLoading(false);
				}
			})
			.catch(e => console.warn(e))
	};

	return (
		<>
			<Formik
				enableReinitialize={true}
				initialValues={userRecord}
				validationSchema={validationSchema}
				onSubmit={onSubmit}>
				{
					(props: any) => {
						const {
							values,
							touched,
							errors,
							dirty,
							isSubmitting,
							handleChange,
							handleBlur,
							handleSubmit,
							handleReset,
						} = props;
						return (
							<form onSubmit={handleSubmit}>
								<Card
									className={clsx(classes.root, className)}
									style={{ margin: '0px auto', maxWidth: 1200 }}
								>
									<CardHeader title={t("administration.user.title")} />
									<Divider />
									<CardContent>
										<Grid container spacing={3}>
											<Grid item md={6} xs={12}>
												<TextField name="upn"
													fullWidth
													label={t('administration.user.upn')}
													value={values.upn}
													onChange={handleChange}
													onBlur={handleBlur}
													helperText={(errors.upn && touched.upn) && errors.upn}
													error={errors.upn && touched.upn}
													margin="dense"
													InputProps={{ readOnly: !isNew, }}
												/>
											</Grid>
											<Grid item md={6} xs={12}>
												<TextField name="username"
													fullWidth
													label={t('administration.user.username')}
													value={values.username}
													onChange={handleChange}
													onBlur={handleBlur}
													helperText={(errors.username && touched.username) && errors.username}
													error={errors.username && touched.username}
													margin="dense"
												/>
											</Grid>
											<Grid item md={6} xs={12}>
												<TextField name="email"
													fullWidth
													label={t('administration.user.email')}
													value={values.email}
													onChange={handleChange}
													onBlur={handleBlur}
													helperText={(errors.email && touched.email) && errors.email}
													error={errors.email && touched.email}
													margin="dense"
												/>
											</Grid>
											<Grid item md={6} xs={12}>
												<TextField name="employeetypeid"
													select
													fullWidth
													label={t('administration.user.employee_type')}
													value={values.employeetypeid || ''}
													onChange={handleChange}
													onBlur={handleBlur}
													helperText={(errors.employeetypeid && touched.employeetypeid) && errors.employeetypeid}
													error={errors.employeetypeid && touched.employeetypeid}
													margin="dense"
												>
													<MenuItem value=""><em>None</em></MenuItem>
													{master.EmployeeType.map((option: EmployeeTypeModel) =>
														<MenuItem key={option.id} value={option.id}>
															{option.employeeTypeName}
														</MenuItem>
													)}
												</TextField>
											</Grid>
											<Grid item md={6} xs={12}>
												<TextField name="regionid"
													select
													fullWidth
													label={t('administration.user.region')}
													value={values.regionid || ''}
													onChange={handleChange}
													onBlur={handleBlur}
													helperText={(errors.regionid && touched.regionid) && errors.regionid}
													error={errors.regionid && touched.regionid}
													margin="dense"
												>
													<MenuItem value=""><em>None</em></MenuItem>
													{master.Regions.map((option: RegionModel) =>
														<MenuItem key={option.id} value={option.id}>
															{t('region.' + option.name)}
														</MenuItem>
													)}
												</TextField>
											</Grid>
											<Grid item md={6} xs={12}>
												<TextField name="entitledleave"
													fullWidth
													label={t('administration.user.entitled_leave')}
													value={values.entitledleave}
													onChange={handleChange}
													onBlur={handleBlur}
													helperText={(errors.entitledleave && touched.entitledleave) && errors.entitledleave}
													error={errors.entitledleave && touched.entitledleave}
													type="number"
													// inputProps={{
													// 	step: 0.5,
													// }}
													margin="dense"
												/>
											</Grid>
											<Grid item md={6} xs={12}>
												<TextField name="reporttoid"
													select
													fullWidth
													label={t('administration.user.report_to')}
													value={values.reporttoid || ''}
													// onChange={handleChange}
													onChange={(e: any) => {
														e.persist();
														props.setFieldValue('reporttoid', e.target.value || null)
													}}
													onBlur={handleBlur}
													// helperText={(errors.reporttoid && touched.reporttoid) && errors.reporttoid}
													// error={errors.reporttoid && touched.reporttoid}
													margin="dense"
												>
													<MenuItem value=""><em>None</em></MenuItem>
													{master.Peoples.map((option: UserModel) =>
														<MenuItem key={option.id} value={option.id}>
															{option.upn}
														</MenuItem>
													)}
												</TextField>
											</Grid>
											<Grid item md={6} xs={12}></Grid>
											<Grid item md={6} xs={12}>
												<FormControlLabel name="hradmin"
													label={t('administration.user.hr_admin')}
													control={
														<Checkbox
															color="primary"
															checked={values.hradmin}
															onChange={handleChange}
														/>
													}
												/>
											</Grid>
											<Grid item md={6} xs={12}>
												<FormControlLabel name="activestatus"
													label={t('administration.user.status')}
													control={
														<Checkbox
															color="primary"
															checked={values.activestatus}
															onChange={handleChange}
														/>
													}
												/>
											</Grid>
										</Grid>
									</CardContent>
									<Divider />
									<CardActions className={classes.actionButton}>
										<Button variant="outlined" component={Link} to="/userlist">{t('back')}</Button>
										<Button color="primary" variant="contained" type="submit">{t('save')}</Button>
									</CardActions>
									<div>
										<Loading loading={isLoading} />
									</div>
								</Card>
							</form>
						);
					}
				}
			</Formik>
		</>
	)
}

export default compose(
	connector,
)(User as any) 