import { Action, Reducer } from 'redux'
import { AppThunkAction } from '.'

import moment from 'moment'
import { PreApprovalBalanceSpend as PreApprovalBalanceSpendModel } from '../model'
import { setJWT } from '../helper';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface PreApprovalBalanceSpendState {
    initLoad: boolean
    isLoading: boolean
    PreApprovalBalanceSpendList: PreApprovalBalanceSpendModel[]
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestPreApprovalBalanceSpendAction {
    type: 'REQUEST_PREAPPROVALVBALANCESPENDREQUEST'
}

interface ReceivePreApprovalBalanceSpendAction {
    type: 'RECEIVE_PREAPPROVALVBALANCESPENDREQUEST'
    userId?: number | undefined
    preApprovalRequestList: PreApprovalBalanceSpendModel[]
}

interface ReceiveOnePreApprovalBalaceSpendAction {
    type: 'RECEIVEONE_PREAPPROVALVBALANCESPENDREQUEST'
    preApprovalRequest: PreApprovalBalanceSpendModel
    recordId: number
}
interface ReceiveNonPreApprovalBalaceSpendAction {
    type: 'RECEIVENON_PREAPPROVALVBALANCESPENDREQUEST'
    recordId: number
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
    RequestPreApprovalBalanceSpendAction
    | ReceivePreApprovalBalanceSpendAction
    | ReceiveOnePreApprovalBalaceSpendAction
    | ReceiveNonPreApprovalBalaceSpendAction
    ;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestPreApprovalBalanceSpend: (userid?: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.preApprovalBalanceSpend) {
            let fetchDest = 'api/preApprovalBalanceSpend';

            if (userid) {
                fetchDest += '/user/' + userid;
            }

            let httpOptions: any = {};

            setJWT(httpOptions, appState);

            fetch(fetchDest, httpOptions)
                .then(response => response.json() as Promise<PreApprovalBalanceSpendModel[]>)
                .then(data => {
                    data.forEach(x => {
                        if (x.expiryDate)
                            x.expiryDate = moment(x.expiryDate);
                    })
                    return data;
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_PREAPPROVALVBALANCESPENDREQUEST', preApprovalRequestList: data });
                })
                .catch(() => {
                    dispatch({ type: 'RECEIVE_PREAPPROVALVBALANCESPENDREQUEST', preApprovalRequestList: [] });
                })

            dispatch({ type: 'REQUEST_PREAPPROVALVBALANCESPENDREQUEST' });
        }
    },
    requestUserPreApprovalBalanceSpend: (userid: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.preApprovalBalanceSpend) {
            let fetchDest = 'api/preApprovalBalanceSpend/user/' + userid;

            let httpOptions: any = {};

            setJWT(httpOptions, appState);

            fetch(fetchDest, httpOptions)
                .then(response => response.json() as Promise<PreApprovalBalanceSpendModel[]>)
                .then(data => {
                    data.forEach(x => {
                        if (x.expiryDate)
                            x.expiryDate = moment(x.expiryDate);
                    })
                    return data;
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_PREAPPROVALVBALANCESPENDREQUEST', userId: userid, preApprovalRequestList: data });
                })
                .catch(() => {
                    dispatch({ type: 'RECEIVE_PREAPPROVALVBALANCESPENDREQUEST', userId: userid, preApprovalRequestList: [] });
                })

            dispatch({ type: 'REQUEST_PREAPPROVALVBALANCESPENDREQUEST' });
        }
    },
    reuqestOnePreApprovalBlanceSpend: (recordId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.preApprovalBalanceSpend) {
            let fetchDest = 'api/preApprovalBalanceSpend/' + recordId;

            let httpOptions: any = {};

            setJWT(httpOptions, appState);

            fetch(fetchDest, httpOptions)
                .then(response => response.json() as Promise<PreApprovalBalanceSpendModel>)
                .then(data => {
                    if (data.expiryDate)
                        data.expiryDate = moment(data.expiryDate);
                    return data;
                })
                .then(data => {
                    dispatch({ type: 'RECEIVEONE_PREAPPROVALVBALANCESPENDREQUEST', preApprovalRequest: data, recordId: recordId });
                })
                .catch(() => {
                    dispatch({ type: 'RECEIVENON_PREAPPROVALVBALANCESPENDREQUEST', recordId: recordId });
                })

            dispatch({ type: 'REQUEST_PREAPPROVALVBALANCESPENDREQUEST' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: PreApprovalBalanceSpendState = { PreApprovalBalanceSpendList: [], isLoading: false, initLoad: false };

export const reducer: Reducer<PreApprovalBalanceSpendState> = (state: PreApprovalBalanceSpendState | undefined, incomingAction: Action): PreApprovalBalanceSpendState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_PREAPPROVALVBALANCESPENDREQUEST':
            return {
                PreApprovalBalanceSpendList: state.PreApprovalBalanceSpendList,
                isLoading: true,
                initLoad: true,
            };
        case 'RECEIVE_PREAPPROVALVBALANCESPENDREQUEST':

            if (action.userId) {
                return {
                    PreApprovalBalanceSpendList: state.PreApprovalBalanceSpendList
                        .filter(x => x.preApprovalApplicant != action.userId)
                        .concat(action.preApprovalRequestList),
                    isLoading: false,
                    initLoad: true,
                }
            } else {
                return {
                    PreApprovalBalanceSpendList: action.preApprovalRequestList,
                    isLoading: false,
                    initLoad: true,
                }
            }
        case 'RECEIVEONE_PREAPPROVALVBALANCESPENDREQUEST':
            return {
                PreApprovalBalanceSpendList: [action.preApprovalRequest, ...(state.PreApprovalBalanceSpendList.filter(x => x.preApprovalId != action.recordId))],
                isLoading: false,
                initLoad: true,
            };
        case 'RECEIVENON_PREAPPROVALVBALANCESPENDREQUEST':
            return {
                PreApprovalBalanceSpendList: state.PreApprovalBalanceSpendList.filter(x => x.preApprovalId != action.recordId),
                isLoading: false,
                initLoad: true,
            };
    }

    return state;
};
