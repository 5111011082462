import React, { forwardRef, Fragment, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Button, colors, ListItemIcon, ListItemText, Typography, Collapse, Badge, createMuiTheme, MuiThemeProvider, } from '@material-ui/core';


import { ApplicationState } from '../../store';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
    fontSize: 14,
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    // color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    minWidth: 30,
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  nested: {
    paddingLeft: 38,
  },
  margin: {
    margin: theme.spacing(1)
  },
  customBadge: {
    backgroundColor: "#ff7e7e",
    color: "white"
  },
  customTypography: {
    marginRight: 10,
    fontWeight: 500,
  }
}));

const CustomRouterLink = forwardRef((props: any, ref: any) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));
const mapStateToProps = (state: ApplicationState) => {
  return {
    appState: state,
  }
}

const connector = connect(
  mapStateToProps,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type SidebarNavProps =
  {
    [x: string]: any
  }
  & PropsFromRedux

const SidebarNav = (props: SidebarNavProps) => {
  const { pages, className, onClick, appState, dispatch, ...rest } = props;

  const classes = useStyles();
  const [openAttendanceMenu, setOpenAttendanceMenu] = React.useState(true);
  const [openAdminMenu, setOpenAdminMenu] = React.useState(true);
  const [openReportMenu, setOpenReportMenu] = React.useState(true);

  const handleClick = (name: any) => {
    if (name === 'administration')
      setOpenAdminMenu(!openAdminMenu);
    else if (name === 'attendance')
      setOpenAttendanceMenu(!openAttendanceMenu);
    else if (name === 'report')
      setOpenReportMenu(!openReportMenu);
  };

  const openControl = (name: any) => {
    if (name === 'administration')
      return openAdminMenu;
    else if (name === 'attendance')
      return openAttendanceMenu;
    else if (name === 'report')
      return openReportMenu;
  }

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
    >
      {pages.filter((x: any) => x.display(appState)).map((page: any) => {
        return (
          !page.children ?
            <ListItem activeClassName={classes.active} button key={page.title} component={CustomRouterLink} onClick={onClick} to={page.href} disableGutters>
              <ListItemIcon className={classes.icon}>
                {page.icon}
              </ListItemIcon>
              <ListItemText disableTypography>
                <Badge badgeContent={page.badge == undefined ? undefined : page.badge(appState)} max={999} classes={{ badge: classes.customBadge }}>
                  <Typography className={classes.customTypography}>{page.title}</Typography>
                </Badge>
              </ListItemText>
            </ListItem>
            :
            <Fragment key={page.title}>
              <ListItem button onClick={() => handleClick(page.name)} disableGutters>
                <ListItemIcon className={classes.icon}>
                  {page.icon}
                </ListItemIcon>
                <ListItemText disableTypography >
                  <Badge badgeContent={page.badge == undefined ? undefined : page.badge(appState)} max={999} classes={{ badge: classes.customBadge }}>
                    <Typography className={classes.customTypography}>{page.title}</Typography>
                  </Badge>
                </ListItemText>
                {openControl(page.name) ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={openControl(page.name)} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {page.children.map((subpage: any) =>
                    <ListItem activeClassName={classes.active} button key={subpage.title} component={CustomRouterLink} onClick={onClick} to={subpage.href} disableGutters>
                      <ListItemText disableTypography className={classes.nested}>
                        <Badge badgeContent={subpage.badge == undefined ? undefined : subpage.badge(appState)} max={999} classes={{ badge: classes.customBadge }}>
                          <Typography className={classes.customTypography}>{subpage.title}</Typography>
                        </Badge>
                      </ListItemText>
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </Fragment>
        )
      }
      )}
    </List>
  );
};

// const SidebarNav = (props: any) => {
//   const { pages, className, ...rest } = props;

//   const classes = useStyles();

//   return (
//     <List
//       {...rest}
//       className={clsx(classes.root, className)}
//     >
//       {pages.map((page: any) => (
//         <ListItem
//           className={classes.item}
//           disableGutters
//           key={page.title}
//         >
//           <Button
//             activeClassName={classes.active}
//             className={classes.button}
//             component={CustomRouterLink}
//             to={page.href}
//           >
//             <div className={classes.icon}>{page.icon}</div>
//             {page.title}
//           </Button>
//         </ListItem>
//       ))}
//     </List>
//   );
// };



SidebarNav.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  pages: PropTypes.array.isRequired
};

export default connector(SidebarNav);
