import React, { useEffect, useLayoutEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

// import { RouteWithLayout } from './components';
import {
  Main as MainLayout,
  Minimal as MinimalLayout
} from './layouts';

import {
  Attendance as AttendanceView,
  AttendanceList as AttendanceListView,
  LeaveRequest as LeaveRequestView,
  Calendar as CalendarView,
  OvertimeRequest as OvertimeRequestView,
  PreApproval as PreApprovalView,
  Typography as TypographyView,
  NotFound as NotFoundView,
  Unauthorized as UnauthorizedView,
  Settings as SettingsView,
  SignIn as SignInView,
  UserList as UserListView,
  User as UserView,
  EmployeeTypeList as EmployeeTypeListView,
  EmployeeType as EmployeeTypeView,
  AttendanceSummaryReport as AttendanceSummaryReportView,
  RawAccessReport as RawAccessReportView,
  AnnualLeaveBalanceByYearReport as AnnualLeaveBalanceByYearReportView,
  AttendanceByDateReport as AttendanceByDateReportView,
  LeaveSummaryReport as LeaveSummaryReportView,
  OTClaimBreakdownReport as OTClaimBreakdownReportView,
} from './views';

import LeaveRequestForm from './component/LeaveRequestForm';
import OvertimeRequestForm from './component/OvertimeRequestForm';
import PreApprovalForm from './component/PreApprovalForm';
import loginForm from './component/LoginForm';

import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ApplicationState } from './store';
import * as AuthenticateStore from './store/AuthenticateStore';
import * as UserStore from './store/UserStore';

const RouteWithLayout = (props: any) => {
  const { layout: Layout, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
  exact: PropTypes.bool, // @ todo extend RouteProp here
};

const mapStateToProps = (state: ApplicationState) => {
  const {
    authenticate,
  } = state;
  return {
    auth: authenticate,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({
    ...AuthenticateStore.actionCreators,
    ...UserStore.actionCreators,
  }, dispatch)
}

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type RouteProps =
  {
    [x: string]: any;
  }
  & PropsFromRedux

const Routes = (props: RouteProps) => {

  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/calendar"
      />
      {props.auth && props.auth.user && props.auth.user.hradmin &&
        <RouteWithLayout
          component={UserListView}
          layout={MainLayout}
          exact
          path="/userlist"
        />
      }
      {props.auth && props.auth.user && props.auth.user.hradmin &&
        <RouteWithLayout
          component={UserView}
          layout={MainLayout}
          exact
          path="/user/:recordId"
        />
      }
      {props.auth && props.auth.user && props.auth.user.hradmin &&
        <RouteWithLayout
          component={EmployeeTypeListView}
          layout={MainLayout}
          exact
          path="/employeetypelist"
        />
      }
      {props.auth && props.auth.user && props.auth.user.hradmin &&
        <RouteWithLayout
          component={EmployeeTypeView}
          layout={MainLayout}
          exact
          path="/employeetype/:recordId"
        />
      }
      {props.auth && props.auth.user && props.auth.user.hradmin &&
        <RouteWithLayout
          component={AttendanceSummaryReportView}
          layout={MainLayout}
          exact
          path="/attendance_summary_report"
        />
      }
      {props.auth && props.auth.user && props.auth.user.hradmin &&
        <RouteWithLayout
          component={RawAccessReportView}
          layout={MainLayout}
          exact
          path="/raw_access_report"
        />
      }
      {props.auth && props.auth.user && props.auth.user.hradmin &&
        <RouteWithLayout
          component={AnnualLeaveBalanceByYearReportView}
          layout={MainLayout}
          exact
          path="/annual_leave_balance_report"
        />
      }
      {props.auth && props.auth.user && props.auth.user.hradmin &&
        <RouteWithLayout
          component={AttendanceByDateReportView}
          layout={MainLayout}
          exact
          path="/attendance_by_date_report"
        />
      }
      {props.auth && props.auth.user && props.auth.user.hradmin &&
        <RouteWithLayout
          component={LeaveSummaryReportView}
          layout={MainLayout}
          exact
          path="/leave_summary_report"
        />
      }
      {props.auth && props.auth.user && props.auth.user.hradmin &&
        <RouteWithLayout
          component={OTClaimBreakdownReportView}
          layout={MainLayout}
          exact
          path="/ot_breakdown_report"
        />
      }
      <RouteWithLayout
        component={AttendanceView}
        layout={MainLayout}
        exact
        path="/attendance"
      />
      <RouteWithLayout
        component={AttendanceListView}
        layout={MainLayout}
        exact
        path="/attendancelist"
      />
      <RouteWithLayout
        component={AttendanceView}
        layout={MainLayout}
        exact
        path="/attendance/:recordId"
      />
      <RouteWithLayout
        component={LeaveRequestForm}
        layout={MainLayout}
        path="/leave_request/add"
      />
      <RouteWithLayout
        component={LeaveRequestView}
        layout={MainLayout}
        exact
        path="/leave_request"
      />
      <RouteWithLayout
        component={LeaveRequestForm}
        layout={MainLayout}
        exact
        path="/leave_request/:recordId"
      />
      <RouteWithLayout
        component={CalendarView}
        layout={MainLayout}
        exact
        path="/calendar"
      />
      <RouteWithLayout
        component={PreApprovalView}
        layout={MainLayout}
        exact
        path="/pre_approval"
      />
      <RouteWithLayout
        component={PreApprovalForm}
        layout={MainLayout}
        exact
        path="/pre_approval/add"
      />
      <RouteWithLayout
        component={PreApprovalForm}
        layout={MainLayout}
        exact
        path="/pre_approval/:recordId"
      />
      <RouteWithLayout
        component={SettingsView}
        layout={MainLayout}
        exact
        path="/settings"
      />
      <RouteWithLayout
        component={loginForm}
        layout={MainLayout}
        exact
        path="/templogin"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={OvertimeRequestView}
        layout={MainLayout}
        exact
        path="/overtime"
      />
      <RouteWithLayout
        component={OvertimeRequestForm}
        layout={MainLayout}
        exact
        path="/overtime/add"
      />
      <RouteWithLayout
        component={OvertimeRequestForm}
        layout={MainLayout}
        exact
        path="/overtime/:recordId"
      />
      <RouteWithLayout
        component={UnauthorizedView}
        layout={MinimalLayout}
        path="/unauthorized"
      />
      <RouteWithLayout
        component={NotFoundView}
        layout={MainLayout}
        path="*"
      />
    </Switch>
  );
};

export default compose(
  connector,
)(Routes as any) 