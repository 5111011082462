import { Action, Reducer } from 'redux'
import { AppThunkAction } from '.'

import moment from 'moment'
import { PreApprovalRequest as PreApprovalRequestModel } from '../model'
import { setJWT } from '../helper';
import { isNullOrUndefined } from 'util';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface PreApprovalRequestState {
    isLoading: boolean
    preApprovalRequestList: PreApprovalRequestModel[]
    authorized: boolean
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface SubmitPreApprovalRequestAction {
    type: 'SUBMIT_PREAPPROVALREQUEST'
    //preApprovalRequest: PreApprovalRequestModel
}
interface SubmitResultPreApprovalRequestAction {
    type: 'SUBMITRESULT_PREAPPROVALREQUEST'
    preApprovalRequest: PreApprovalRequestModel | undefined
}

interface UpdatePreApprovalRequestAction {
    type: 'UPDATE_PREAPPROVALREQUEST'
}

interface UpdateResultPreApprovalRequestAction {
    type: 'UPDATERESULT_PREAPPROVALREQUESTACTION'
    preApprovalRequest: PreApprovalRequestModel
}

interface ResultPreApprovalRequestAction {
    type: 'RESULT_PREAPPROVALREQUEST'
    preApprovalRequest: PreApprovalRequestModel

}

interface RequestPreApprovalRequestAction {
    type: 'REQUEST_PREAPPROVALREQUEST'
}

interface ReceivePreApprovalRequestAction {
    type: 'RECEIVE_PREAPPROVALREQUEST'
    preApprovalRequestList: PreApprovalRequestModel[]
}

interface RequestOnePreApprovalRequestAction {
    type: 'REQUESTONE_PREAPPROVALREQUEST'
}

interface ReceiveOnePreApprovalRequestAction {
    type: 'RECEIVEONE_PREAPPROVALREQUEST'
    preApprovalRequest: PreApprovalRequestModel
}

interface ReceiveOnePreApprovalRequestFailAction {
    type: 'RECEIVEONE_PREAPPROVALREQUEST_FAIL'
    statusCode: number
}

interface RequestPreApprovalWithdrawAction {
    type: 'REQUEST_PREAPPROVALWITHDRAW'
    recordId: number;
}

interface ResultPreApprovalWithdrawAction {
    type: 'RESULT_PREAPPROVALWITHDRAW'
    recordId: number;
    successful: boolean;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
    SubmitPreApprovalRequestAction
    | SubmitResultPreApprovalRequestAction
    | UpdatePreApprovalRequestAction
    | UpdateResultPreApprovalRequestAction
    | ResultPreApprovalRequestAction
    | RequestPreApprovalRequestAction
    | ReceivePreApprovalRequestAction
    | RequestOnePreApprovalRequestAction
    | ReceiveOnePreApprovalRequestAction
    | ReceiveOnePreApprovalRequestFailAction
    | RequestPreApprovalWithdrawAction
    | ResultPreApprovalWithdrawAction
    ;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    createPreApprovalRequest: (record: PreApprovalRequestModel, callback?: (goodresult: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.preApprovalRequest) {
            let fetchDest = 'api/preApprovalRequest';

            let httpOptions: any = {};
            httpOptions.method = 'POST';
            httpOptions.headers = {
                'Content-Type': 'application/json'
            }
            httpOptions.body = JSON.stringify(record);

            setJWT(httpOptions, appState);

            fetch(fetchDest, httpOptions)
                .then(response => {
                    if (response.ok && response.status == 201) {
                        return response.json() as Promise<PreApprovalRequestModel>;
                    } else {
                        throw new Error('Not ok, request not accept by server');
                    }
                })
                .then(data => {
                    data.createdDate = moment(data.createdDate)
                    data.modifiedDate = moment(data.modifiedDate)
                    dispatch({ type: 'SUBMITRESULT_PREAPPROVALREQUEST', preApprovalRequest: data });
                    if (callback) callback(true);
                })
                .catch((e) => {
                    console.warn(e);
                    dispatch({ type: 'SUBMITRESULT_PREAPPROVALREQUEST', preApprovalRequest: undefined });
                    if (callback) callback(false);
                });

            dispatch({ type: 'SUBMIT_PREAPPROVALREQUEST' });
        }
    },
    updatePreApprovalRequest: (record: PreApprovalRequestModel, callback?: (goodresult: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.preApprovalRequest) {
            let fetchDest = 'api/preApprovalRequest/' + record.id;

            let httpOptions: any = {};
            httpOptions.method = 'PUT';
            httpOptions.headers = {
                'Content-Type': 'application/json'
            }
            httpOptions.body = JSON.stringify(record);

            setJWT(httpOptions, appState);

            let result: boolean = false;
            fetch(fetchDest, httpOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Not ok, request not accept by server');
                    }
                    switch (response.status) {
                        case 201:
                            return response.json() as Promise<PreApprovalRequestModel>;
                            break;
                        case 204:
                        default:
                            return record
                            break;
                    }
                })
                .then(data => {
                    data.createdDate = moment(data.createdDate)
                    data.modifiedDate = moment(data.modifiedDate)
                    dispatch({ type: 'UPDATERESULT_PREAPPROVALREQUESTACTION', preApprovalRequest: data });
                    result = true;
                })
                .catch((e) => {
                    console.warn(e);
                    dispatch({ type: 'UPDATERESULT_PREAPPROVALREQUESTACTION', preApprovalRequest: record });
                })
                .finally(() => {
                    if (callback) callback(result);
                });

            dispatch({ type: 'UPDATE_PREAPPROVALREQUEST' });
        }
    },
    requestPreApprovalRequest: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();


        if (appState && appState.preApprovalRequest) {
            let fetchDest = 'api/preApprovalRequest'

            let httpOptions: any = {};

            setJWT(httpOptions, appState);

            fetch(fetchDest, httpOptions)
                .then(response => response.json() as Promise<PreApprovalRequestModel[]>)
                .then(data => {
                    data.forEach(x => {
                        x.createdDate = moment(x.createdDate);
                        x.modifiedDate = moment(x.modifiedDate);

                        if (x.refDate) {
                            x.refDate.forEach(y => {
                                if (y.date !== null)
                                    y.date = moment(y.date);
                            })
                        }
                    })
                    return data;
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_PREAPPROVALREQUEST', preApprovalRequestList: data });
                })
                .catch(() => {
                    dispatch({ type: 'RECEIVE_PREAPPROVALREQUEST', preApprovalRequestList: [] });
                })

            dispatch({ type: 'REQUEST_PREAPPROVALREQUEST' });
        }
    },
    requestOnePreApprovalRequest: (recordId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.preApprovalRequest) {
            let fetchDest: string = 'api/preApprovalRequest/' + recordId;

            let httpOptions: any = {};

            setJWT(httpOptions, appState);

            fetch(fetchDest, httpOptions)
                .then(response => {
                    if (response.ok && response.status == 200) {
                        return response.json() as Promise<PreApprovalRequestModel>
                    } else {
                        dispatch({ type: 'RECEIVEONE_PREAPPROVALREQUEST_FAIL', statusCode: response.status });
                    }
                })
                .then(data => {
                    if (isNullOrUndefined(data)) { return }
                    data.createdDate = moment(data.createdDate);
                    data.modifiedDate = moment(data.modifiedDate);
                    if (data.refDate) {
                        data.refDate.forEach(x => {
                            if (x.date !== null)
                                x.date = moment(x.date);
                        })
                    }
                    dispatch({ type: 'RECEIVEONE_PREAPPROVALREQUEST', preApprovalRequest: data });
                })
                .catch((ex) => {
                    console.warn(ex);
                    dispatch({ type: 'RECEIVEONE_PREAPPROVALREQUEST_FAIL', statusCode: 0 });
                })

            dispatch({ type: 'REQUESTONE_PREAPPROVALREQUEST' });
        }
    },
    withdrawPreApprovalRequest: (recordId: number, callback?: (resutl: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.preApprovalRequest) {
            let fetchDest: string = 'api/preApprovalRequest/' + recordId + '/withdraw';

            let httpOptions: any = {
                method: 'POST',
                headers: {

                },
            };

            setJWT(httpOptions, appState);

            fetch(fetchDest, httpOptions).then(response => {
                if (response.ok && response.status == 204) {
                    dispatch({ type: 'RESULT_PREAPPROVALWITHDRAW', recordId: recordId, successful: true, });
                    if (callback)
                        callback(true);
                } else {
                    dispatch({ type: 'RESULT_PREAPPROVALWITHDRAW', recordId: recordId, successful: false, });
                    if (callback)
                        callback(false);
                }
            })
            dispatch({ type: 'REQUEST_PREAPPROVALWITHDRAW', recordId: recordId });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: PreApprovalRequestState = { preApprovalRequestList: [], isLoading: false, authorized: true };

export const reducer: Reducer<PreApprovalRequestState> = (state: PreApprovalRequestState | undefined, incomingAction: Action): PreApprovalRequestState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SUBMIT_PREAPPROVALREQUEST':
            return {
                preApprovalRequestList: state.preApprovalRequestList,
                isLoading: true,
                authorized: true,
            };
        case 'SUBMITRESULT_PREAPPROVALREQUEST':
            if (action.preApprovalRequest) {
                var le = action.preApprovalRequest;
                return {
                    preApprovalRequestList: [...state.preApprovalRequestList, le],
                    isLoading: false,
                    authorized: true,
                };
            } else {
                return {
                    preApprovalRequestList: state.preApprovalRequestList,
                    isLoading: false,
                    authorized: true,
                };
            }
        case 'UPDATE_PREAPPROVALREQUEST':
            return {
                preApprovalRequestList: state.preApprovalRequestList,
                isLoading: true,
                authorized: true,
            };
        case 'UPDATERESULT_PREAPPROVALREQUESTACTION':
            return {
                preApprovalRequestList: [action.preApprovalRequest, ...state.preApprovalRequestList.filter(x => x.id != action.preApprovalRequest.id)],
                isLoading: false,
                authorized: true,
            };
        case 'RESULT_PREAPPROVALREQUEST':
            return {
                preApprovalRequestList: state.preApprovalRequestList,
                isLoading: false,
                authorized: true,
            };

        case 'REQUEST_PREAPPROVALREQUEST':
            return {
                preApprovalRequestList: [],
                isLoading: true,
                authorized: true,
            };

        case 'RECEIVE_PREAPPROVALREQUEST':
            return {
                preApprovalRequestList: action.preApprovalRequestList,
                isLoading: false,
                authorized: true,
            };
        case 'REQUESTONE_PREAPPROVALREQUEST':
            return {
                preApprovalRequestList: state.preApprovalRequestList,
                isLoading: true,
                authorized: true,
            };
        case 'RECEIVEONE_PREAPPROVALREQUEST':
            if (action.preApprovalRequest) {
                var le = action.preApprovalRequest;
                if (state.preApprovalRequestList.find(x => x.id == le.id)) {
                    return {
                        preApprovalRequestList: state.preApprovalRequestList.map(
                            (preApproval, index) => preApproval.id == le.id ? le : preApproval
                        ),
                        isLoading: false,
                        authorized: true,
                    };
                } else {
                    return {
                        preApprovalRequestList: state.preApprovalRequestList.filter(x => x.id != le.id).concat([le]),
                        isLoading: false,
                        authorized: true,
                    };
                }
            } else {
                return {
                    preApprovalRequestList: state.preApprovalRequestList,
                    isLoading: false,
                    authorized: true,
                };
            }
        case 'RECEIVEONE_PREAPPROVALREQUEST_FAIL':
            return {
                preApprovalRequestList: state.preApprovalRequestList,
                isLoading: false,
                authorized: false,
            };

    }

    return state;
};
