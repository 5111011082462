import { LeaveDate } from './LeaveDate'
import { LeaveAttachment } from './LeaveAttachment'
import { TaskApproval } from './TaskApproval'
import { Moment } from 'moment'

export enum LeaveRequestStatus {
    Draft = 'draft',
    PendingApprove = 'pending_approval',
    Approved = 'approved',
    Reject = 'rejected',
    Withdraw = 'withdraw',
}

export interface LeaveRequest {
    id: number
    requestId: string
    leaveTypeId?: number
    applicant?: number
    remarks: string
    status: LeaveRequestStatus
    nextApprover?: number
    createdBy?: number
    createdDate?: Moment
    modifiedBy?: number
    modifiedDate?: Moment
    leaveBalanceId?: number

    leaveDate?: LeaveDate[]
    leaveAttachment?: LeaveAttachment[]
    reportingLine?: TaskApproval[]
}
