import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import moment from 'moment'
import { LeaveRequest as LeaveRequestModel, LeaveRequestStatus } from '../model';
import { LeaveRequest } from '../views';
import { setJWT } from '../helper';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface LeaveRequestState {
    isLoading: boolean;
    focuseRequestId?: number;
    staffId?: string;
    leaveRequestList: LeaveRequestModel[];
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface SubmitLeaveRequestAction {
    type: 'SUBMIT_LEAVEREQUEST';
    leaveRecord: LeaveRequestModel;
}

interface ResultLeaveRequestAction {
    type: 'RESULT_LEAVEREQUEST';
    leaveRecord: LeaveRequestModel;
}

interface DeleteLeaveRequestAction {
    type: 'DELETE_LEAVEREQUEST'
    id: string;
}

interface RequestOneLeaveRequestACtion {
    type: 'REQUESTONE_LEAVEREQUEST';
    id: string;
}

interface RequestLeaveRequestAction {
    type: 'REQUEST_LEAVEREQUEST';
    staffId?: string;
}

interface ReceiveLeaveRequestAction {
    type: 'RECEIVE_LEAVEREQUEST';
    leaveRequestList: LeaveRequestModel[];
}

interface RequsetWithdrawAction {
    type: 'REQUEST_WITHDRAW';
    recordId: number;
}

interface ResultWithdrawAction {
    type: 'RESULT_WITHDRAW';
    recordId: number;
    successful: boolean;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
    SubmitLeaveRequestAction
    | ResultLeaveRequestAction
    | DeleteLeaveRequestAction
    | RequestOneLeaveRequestACtion
    | RequestLeaveRequestAction
    | ReceiveLeaveRequestAction
    | RequsetWithdrawAction
    | ResultWithdrawAction
    ;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestLeaveRecord: (StaffId?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        let fetchDest = '';

        if (appState && appState.leavedRequest) {
            if (StaffId) {
                fetchDest = 'api/staff/' + StaffId + '/leaveRcord'
            } else {
                fetchDest = 'api/leaveRequest'
            }
            fetchDest = 'api/leaveRequest'

            let httpOptions: any = {};

            setJWT(httpOptions, appState);

            fetch(fetchDest, httpOptions)
                .then(response => response.json() as Promise<LeaveRequestModel[]>)
                .then(data => {
                    data.forEach(element => {
                        if (element.createdDate)
                            element.createdDate = moment(element.createdDate)
                        if (element.modifiedDate)
                            element.modifiedDate = moment(element.modifiedDate)
                    });
                    dispatch({ type: 'RECEIVE_LEAVEREQUEST', leaveRequestList: data });
                })
                .catch(() => {

                    dispatch({ type: 'RECEIVE_LEAVEREQUEST', leaveRequestList: [] });
                })

            dispatch({ type: 'REQUEST_LEAVEREQUEST' });
        }
    },
    requestOneLeaveRequest: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState()

        let fetchDest = 'api/leaveRecord/' + id

        let httpOptions: any = {};

        setJWT(httpOptions, appState);

        fetch(fetchDest, httpOptions)
            .then(response => {
                if (!response.ok || response.status != 200) {
                    console.debug('fetch data response not ok && 200', response)
                    throw Error("fetch data not ssuccess: " + fetchDest)
                }
                return response.json() as Promise<LeaveRequestModel>
            })
            .then(LeaveRequest => { })
            .catch(error => {
                console.warn(error)
            })
    },
    createLeaveRecord: (leaveRecord: LeaveRequestModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        let fetchDest = 'api/leaveRecord'

        let httpOptions: any = {};
        httpOptions.method = 'POST';
        httpOptions.headers = {
            'Content-Type': 'application/json'
        }
        httpOptions.body = JSON.stringify({
            leaveRecord: leaveRecord
        })

        setJWT(httpOptions, appState);

        fetch(fetchDest, httpOptions)
            .then(response => response.json() as Promise<LeaveRequestModel>)
            .then(data => {
                dispatch({ type: 'RESULT_LEAVEREQUEST', leaveRecord: data });
            });

        dispatch({ type: 'SUBMIT_LEAVEREQUEST', leaveRecord: leaveRecord });
    },
    withdrawLeavaRecord: (recordID: number, callback?: (result: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        let fetchDest = 'api/leaveRequest/' + recordID + '/withdraw';
        let httpOptions: any = {};
        httpOptions.method = 'POST';
        httpOptions.headers = {};
        setJWT(httpOptions, appState);

        fetch(fetchDest, httpOptions).then(response => {
            if (response.ok && response.status == 204) {
                dispatch({ type: 'RESULT_WITHDRAW', recordId: recordID, successful: true, });
                if (callback)
                    callback(true);
            } else {
                dispatch({ type: 'RESULT_WITHDRAW', recordId: recordID, successful: false, });
                if (callback)
                    callback(false);
            }
        });

        dispatch({ type: 'REQUEST_WITHDRAW', recordId: recordID });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: LeaveRequestState = { leaveRequestList: [], isLoading: false };

export const reducer: Reducer<LeaveRequestState> = (state: LeaveRequestState | undefined, incomingAction: Action): LeaveRequestState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_LEAVEREQUEST':
            return {
                staffId: action.staffId,
                leaveRequestList: state.leaveRequestList,
                isLoading: true,
            };

        case 'RECEIVE_LEAVEREQUEST':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            return {
                leaveRequestList: action.leaveRequestList,
                isLoading: false,
            };

        case 'SUBMIT_LEAVEREQUEST':
            return {
                leaveRequestList: state.leaveRequestList,
                isLoading: true,
            };
        case 'RESULT_LEAVEREQUEST':
            return {
                focuseRequestId: action.leaveRecord.id,
                leaveRequestList: [],
                isLoading: false,
            };
        case 'REQUEST_WITHDRAW':
            return {
                focuseRequestId: action.recordId,
                leaveRequestList: state.leaveRequestList,
                isLoading: true,
            };
        case 'RESULT_WITHDRAW':
            if (action.successful) {
                let recordIndex = state.leaveRequestList.findIndex(x => x.id == action.recordId);
                if (recordIndex == -1)
                    return {
                        focuseRequestId: action.recordId,
                        leaveRequestList: state.leaveRequestList,
                        isLoading: false,
                    };
                let temp = state.leaveRequestList;
                temp[recordIndex].status = LeaveRequestStatus.Withdraw;
                return {
                    focuseRequestId: action.recordId,
                    leaveRequestList: temp,
                    isLoading: false,
                };
            } else
                return {
                    focuseRequestId: action.recordId,
                    leaveRequestList: state.leaveRequestList,
                    isLoading: false,
                };
    }

    return state;
};
