import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
// import validate from 'validate.js';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Grid,
    Button,
    IconButton,
    TextField,
    Link,
    Typography,
    Card,
    CardContent,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import theme from '../../theme';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        backgroundImage: 'linear-gradient(to bottom right,  #f7ece8, #ecf3f8, #f7f4e6, #eaf2ea)'
    },
    grid: {
        height: '100%'
    },
    quoteContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    quote: {
        // backgroundColor: theme.palette.neutral,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(/images/auth.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    quoteInner: {
        textAlign: 'center',
        flexBasis: '600px'
    },
    quoteText: {
        // color: theme.palette.white,
        fontWeight: 300
    },
    name: {
        marginTop: theme.spacing(3),
        // color: theme.palette.white
    },
    bio: {
        // color: theme.palette.white
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    logoImage: {
        marginLeft: theme.spacing(4),
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    form: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingBottom: 125,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    title: {
        marginTop: theme.spacing(3)
    },
    socialButtons: {
        marginTop: theme.spacing(3)
    },
    socialIcon: {
        marginRight: theme.spacing(1)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
}));

const SignIn = (props: any) => {
    const { history } = props;

    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });

    useEffect(() => {
        // const errors = validate(formState.values, schema);

        // setFormState(formState => ({
        //   ...formState,
        //   isValid: errors ? false : true,
        //   errors: errors || {}
        // }));
    }, [formState.values]);

    const handleSignIn = (event: any) => {
        event.preventDefault();
        window.location.assign('/Auth/oAuthLogin?returnUrl=' + window.location.pathname);
    };

    return (
        <div className={classes.root} >
            <Grid
                className={classes.grid}
                container
            >
                <Grid
                    className={classes.content}
                    item
                    xs={12}
                >
                    <div className={classes.content}>
                        <div
                            className={classes.contentBody}
                        >
                            <form
                                className={classes.form}
                                onSubmit={handleSignIn}
                            >
                                <Typography variant="h3" style={{ textAlign: 'center', marginBottom: 12, marginLeft: 12, marginRight: 12, fontSize: 22, fontWeight: 700, minWidth: 350 }}>
                                    {t('login.title')}
                                </Typography>
                                <Card>
                                    <CardContent style={{ padding: 24 }}>
                                        <div><img src='/images/logos/logo.png' /></div>
                                        <Typography variant="h6" className={classes.title}>
                                            {t('login.private_system')}
                                        </Typography>
                                        <Typography variant="h6">
                                            {t('login.prohibited')}
                                        </Typography>
                                        <Typography variant="h6" className={classes.title}>
                                            {t('login.credential')}
                                        </Typography>
                                        <Button
                                            className={classes.signInButton}
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                        >
                                            {t('login.signin')}
                                        </Button>
                                    </CardContent>
                                </Card>
                            </form>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div >
    );
};

SignIn.propTypes = {
    history: PropTypes.object
};

export default withRouter(SignIn);
