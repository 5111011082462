import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import clsx from 'clsx';
import moment, { Moment } from 'moment';
import * as ReactMoment from 'react-moment';
import { Field, Form, Formik, FormikProps, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import "filepond/dist/filepond.min.css";

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

import { tableIcons } from '../bug-fix/mui-table-tableicons';

import { ApplicationState } from '../store';
import * as AuthenticateStore from '../store/AuthenticateStore';
import * as UserStore from '../store/UserStore';
import * as LeaveRequestStore from '../store/LeaveRequestStore';
import * as LeaveTypeStore from '../store/LeaveTypeStore';
import * as LeaveTypeEmployeeTypeStore from '../store/LeaveTypeEmployeeTypeStore';
import * as PreApprovalBalanceSpendStore from '../store/PreApprovalBalanceSpendStore';
import * as UserAnnuallyLeaveTakedStore from '../store/UserAnnuallyLeaveTakedStore';

import { Autocomplete } from 'material-ui-formik-components/Autocomplete'
import { TextField } from 'material-ui-formik-components/TextField'
import { Select } from 'material-ui-formik-components/Select'
import { KeyboardDatePicker } from 'material-ui-formik-components/KeyboardDatePicker'
import { DatePicker } from 'material-ui-formik-components/DatePicker'
import Loading from '../component/Loading';
import ConfirmDialog from '../component/ConfirmDialog';
import RejectReasonDialog from '../component/RejectReasonDialog';

import Unauthorized from '../views/Unauthorized';


import MaterialTable, { Column } from 'material-table';
import MuiTable from '../i18n/MuiTable';
import { RouteComponentProps } from 'react-router';

import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardActions,
    CardContent,
    CircularProgress,
    Divider,
    FormHelperText,
    Grid,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField as MuiTextField,
    Typography,
    MenuItem,
} from '@material-ui/core';
import {
    Add as AddIcon,
    Edit as EditIcon,
    Clear as DeleteIcon,
    CloudDownload as DownloadIcon,
} from '@material-ui/icons';

import {
    LeaveAttachment,
    LeaveDate,
    LeaveRequest,
    LeaveRequestStatus,
    LeaveType as LeaveTypeModel,
    LeaveTypeEmployeeType as LeaveTypeEmployeeTypeModel,
    User as UserModel,
    TaskApproval as TaskApprovalModel,
    PreApprovalBalanceSpend as PreApprovalBalanceSpendModel,
} from '../model'

import { Base64 } from 'js-base64'
import { type } from 'os';
import { isNullOrUndefined } from 'util';
import ReportingLineTable from './ReportingLineTable';
import AttachmentList from './AttachmentList';
import { setJWT } from '../helper';

const { FilePond, registerPlugin } = require('react-filepond');

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);


function convertFile(file: File) {
    return new Promise<string>((resolve, reject) => {
        // 建立FileReader物件
        let reader = new FileReader()
        // 註冊onload事件，取得result則resolve (會是一個Base64字串)
        reader.onload = () => {
            if (typeof reader.result === 'string') {

                resolve(reader.result.substr(reader.result.indexOf(',') + 1))
            }
            else throw Error('Type of result in-correct: ' + typeof reader.result)
        }
        // 註冊onerror事件，若發生error則reject
        reader.onerror = () => { reject(reader.error) }
        // 讀取檔案
        reader.readAsDataURL(file)
    })
}

const sameDay = (d1: Moment, d2: Moment): boolean => {
    return d1.isSame(d2, 'day')
}

const mapStateToProps = (state: ApplicationState) => {
    const {
        authenticate,
        leaveType,
        leaveTypeEmployeeType,
        preApprovalBalanceSpend,
        user,
        userAnnuallyLeaveTaked,
    } = state;
    return {
        auth: authenticate,
        leaveType: leaveType,
        leaveTypeEmployeeType: leaveTypeEmployeeType,
        preApprovalBalanceSpend: preApprovalBalanceSpend,
        user: user,
        userAnnuallyLeaveTaked: userAnnuallyLeaveTaked,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        ...UserStore.actionCreators,
        ...LeaveRequestStore.actionCreators,
        ...LeaveTypeStore.actionCreators,
        ...LeaveTypeEmployeeTypeStore.actionCreators,
        ...PreApprovalBalanceSpendStore.actionCreators,
        ...UserAnnuallyLeaveTakedStore.actionCreators
    }, dispatch)
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type LeaveRequestProps =
    {
        classes: {
            root: string;
            container: string;
            TableCell: string;
        },
    }
    & RouteComponentProps<{ recordId?: string }> // ... plus incoming routing parameters
    & WithTranslation
    & PropsFromRedux


const useStyles = {
    root: {
        width: '100%',
        'font-size': '50px',
    },
    container: {
        maxHeight: '100%',
    },
    '@media (max-width: 599px)': {
        'TableCell': {
            'padding': '3px 6px 3px 4px',
        },
    },
};

type LeaveRequestState = {
    isNew: boolean,
    focuseRecordId?: number,
    leaveRequest: LeaveRequest,
    authorized: boolean,
    isLoading: boolean,
}

const initialValues: LeaveRequest = {
    id: 0,
    requestId: '',
    applicant: 0,
    leaveTypeId: 0,
    nextApprover: 0,
    status: LeaveRequestStatus.Draft,
    leaveBalanceId: 0,
    remarks: '',
    leaveDate: [
        {
            id: 0,
            leaveDateFrom: null,
            leaveDateTo: null,
            slot: 'FD',
        },
    ],
    leaveAttachment: []
}

class LeaveRequestForm extends React.Component<LeaveRequestProps, LeaveRequestState> {

    constructor(prop: LeaveRequestProps) {
        super(prop)

        let initLeaveRequest = initialValues;
        if (this.props.auth && this.props.auth.user) {
            initLeaveRequest.applicant = this.props.auth.user.id;
        }
        this.state = {
            isNew: true,
            leaveRequest: initLeaveRequest,
            authorized: true,
            isLoading: false,
        }
    }

    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched()
        let routeRecordId: number

        if (this.props.match.params
            && this.props.match.params.recordId
            && (routeRecordId = parseInt(this.props.match.params.recordId, 10))
            && routeRecordId > 0) {
            this.loadLeaveRecord(routeRecordId)
        }
        this.cleanAsNewRecord()
    }

    private createByUserName(): string {
        var createBy = '';
        var createById = this.state.leaveRequest.createdBy;

        if (createById && this.props.user) {

            var createByUser = this.props.user.userList.find(x => x.id == createById)

            if (createByUser && createByUser.userName) {
                createBy = createByUser.userName;
            }
        }

        return createBy
    }

    private lastModifyUserName(): string {
        var lastModify = '';
        var lastModifyId = this.state.leaveRequest.modifiedBy;

        if (lastModifyId && this.props.user) {

            var lastModifyUser = this.props.user.userList.find(x => x.id == lastModifyId)

            if (lastModifyUser && lastModifyUser.userName) {
                lastModify = lastModifyUser.userName;
            }
        }

        return lastModify
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
    }

    private ensureDataFetched() {
        if (!this.props.user || !this.props.user.userList || this.props.user.userList.length == 0) {
            this.props.requestUserRecord()
        }
        if (!this.props.leaveType || !this.props.leaveType.leaveTypeList || this.props.leaveType.leaveTypeList.length == 0) {
            this.props.requestLeaveType()
        }
        this.props.requestLeaveTypeEmployeeType();
        this.props.requestPreApprovalBalanceSpend();
        this.props.requestUserAnnuallyLeaveTaked();
    }

    private cleanAsNewRecord() {
        this.setState((state, props) => {
            return {
                isNew: true,
                leaveRequest: initialValues,
            }
        });
    }

    private loadLeaveRecord(recordId: number) {
        if (!this.props.auth || !this.props.auth.user) {
            throw Error('Login required');
        }

        fetch('/api/leaveRequest/' + recordId, {
            headers: {
                Authorization: 'Bearer ' + this.props.auth.user.token,
            }
        })
            .then(response => {
                if (!response.ok || response.status != 200) {
                    this.setState({ authorized: false });
                    throw Error("response not ok")
                }

                return response.json() as Promise<LeaveRequest>
            })
            .then(leaveRequest => {
                leaveRequest.leaveDate!.forEach(element => {
                    if (element.leaveDateFrom !== null)
                        element.leaveDateFrom = moment(element.leaveDateFrom)
                    if (element.leaveDateTo !== null)
                        element.leaveDateTo = moment(element.leaveDateTo)

                    if (element.slot === null) {
                        element.slot = 'FD'
                    }
                });

                leaveRequest.reportingLine!.forEach(element => {
                    if (element.createdDate !== null)
                        element.createdDate = moment(element.createdDate)
                    if (element.modifiedDate !== null)
                        element.modifiedDate = moment(element.modifiedDate)
                });

                this.setState((state, props) => {
                    return {
                        isNew: false,
                        focuseRecordId: leaveRequest.id,
                        leaveRequest: leaveRequest,
                    }
                });
            }).catch(c => { })
            .finally(() => {
                this.setState({ isLoading: false });
            });
        this.setState({ isLoading: true });
    }

    private submit(leaveRequest: LeaveRequest) {
        if (!this.props.auth || !this.props.auth.user) {
            throw Error('Login required');
        }
        leaveRequest.leaveDate!.forEach(element => {
            if (element.slot == 'FD') {
                element.slot = null
            }
        });

        let fetchDest = '/api/leaveRequest'
        let method = 'POST'

        if (!this.state.isNew && this.state.focuseRecordId) {
            fetchDest += '/' + this.state.focuseRecordId
            method = 'PUT'
        }

        fetch(fetchDest, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Bearer ' + this.props.auth.user.token,
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url        
            body: JSON.stringify(leaveRequest)
        }).then((response) => {

            if (response.ok && response.status == 200) {
                alert(this.props.i18n.t("leave_request.message.submit_success"))
                this.props.history.push('/leave_request')
                return
                //return response.json() as Promise<LeaveRecord>
            } else if (response.ok && response.status == 201) {
                alert(this.props.i18n.t("leave_request.message.submit_success"))
                this.props.history.push('/leave_request')
                return
            } else if (response.ok && response.status == 204) {
                alert(this.props.i18n.t("leave_request.message.submit_success"))
                this.props.history.push('/leave_request')
                return
            } else {
                throw new Error("response isnt ok")
            }
        })
            .finally(() => {
                this.setState({ isLoading: false });
            });
        this.setState({ isLoading: true });
    }

    private approve() {
        if (!this.props.auth || !this.props.auth.user) {
            throw Error('Login required');
        }

        if (this.state.isNew) {
            throw new Error("Cannot approve brand new request");
        }

        if (isNullOrUndefined(this.state.leaveRequest.reportingLine)) {
            throw new Error("Reporting line is null");
        }

        var readyup = this.state.leaveRequest.reportingLine.sort(x => x.approvalLevel ? x.approvalLevel : 0).find(x => isNullOrUndefined(x.action));

        if (isNullOrUndefined(readyup)) {
            throw new Error("No up-coming approve action in current request");
        }

        // hardcode user
        if (readyup.actionBy != this.props.auth.user.id) {
            throw new Error("You are not the next approver on this request");
        }

        let fetchDest = '/api/taskApproval/' + readyup.id;
        let method = 'PUT'

        readyup.action = "Approve";

        fetch(fetchDest, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.props.auth.user.token,
            },
            body: JSON.stringify(readyup)
        }).then((response) => {

            if (response.ok && response.status == 200) {
                alert(this.props.i18n.t("leave_request.message.approve_success"))
                this.props.history.push('/leave_request')
                return
                //return response.json() as Promise<LeaveRecord>
            } else if (response.ok && response.status == 201) {
                alert(this.props.i18n.t("leave_request.message.approve_success"))
                this.props.history.push('/leave_request')
                return
            } else if (response.ok && response.status == 204) {
                alert(this.props.i18n.t("leave_request.message.approve_success"))
                this.props.history.push('/leave_request')
                return
            } else {
                throw new Error("response isnt ok")
            }
        })
            .finally(() => {
                this.setState({ isLoading: false });
            });
        this.setState({ isLoading: true });
    }

    private reject(reason?: string) {
        if (!this.props.auth || !this.props.auth.user) {
            throw Error('Login required');
        }

        if (this.state.isNew) {
            throw new Error("Cannot reject brand new request")
        }

        if (isNullOrUndefined(this.state.leaveRequest.reportingLine)) {
            throw new Error("Reporting line is null");
        }

        var readyup = this.state.leaveRequest.reportingLine.sort(x => x.approvalLevel ? x.approvalLevel : 0).find(x => isNullOrUndefined(x.action));

        if (isNullOrUndefined(readyup)) {
            throw new Error("No up-coming approve action in current request");
        }

        // hardcode user
        if (readyup.actionBy != this.props.auth.user.id) {
            throw new Error("You are not the next approver on this request");
        }

        let fetchDest = '/api/taskApproval/' + readyup.id;
        let method = 'PUT'

        readyup.action = "Reject";
        if (!isNullOrUndefined(reason)) {
            readyup.remarks = reason;
        }

        fetch(fetchDest, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.props.auth.user.token,
            },
            body: JSON.stringify(readyup)
        }).then((response) => {
            if (response.ok && response.status == 200) {
                alert(this.props.i18n.t("leave_request.message.reject_success"))
                this.props.history.push('/leave_request')
                return
                //return response.json() as Promise<LeaveRecord>
            } else if (response.ok && response.status == 201) {
                alert(this.props.i18n.t("leave_request.message.reject_success"))
                this.props.history.push('/leave_request')
                return
            } else if (response.ok && response.status == 204) {
                alert(this.props.i18n.t("leave_request.message.reject_success"))
                this.props.history.push('/leave_request')
                return
            } else {
                throw new Error("response isnt ok")
            }
        })
            .finally(() => {
                this.setState({ isLoading: false });
            });
        this.setState({ isLoading: true });
    }

    private withdraw() {
        if (this.state.isNew) {
            throw new Error("Cannot reject brand new request")
        }

        this.props.withdrawLeavaRecord(this.state.leaveRequest.id, (result: boolean) => {
            if (result) {
                alert(this.props.i18n.t("leave_request.message.withdraw_success"))
                this.props.history.push('/leave_request')

            } else {
                alert(this.props.i18n.t("leave_request.message.withdraw_failure"))
                this.props.history.push('/leave_request')
            }

            this.setState({ isLoading: false });
        });

        this.setState({ isLoading: true });
    }

    private preApprovalBalanceSpendFilter(applicantId?: number | undefined, leaveTypeId?: number | undefined, selected?: number | undefined) {
        return (x: PreApprovalBalanceSpendModel): boolean => {
            // Selected value, bypass all rules
            if (!isNullOrUndefined(selected) && x.leaveBalanceId == selected) {
                return true;
            }

            // Leave Type not match
            if (x.preApprovalLeaveTypeId !== leaveTypeId)
                return false;

            // Applicant not match
            if (x.preApprovalApplicant != applicantId)
                return false;

            // All taked cannot be select
            if (x.leaveTake >= x.totalBalance)
                return false;

            // Expired cannot be select
            if (x.expiryDate && x.expiryDate.diff(moment(), 'days') < 0)
                return false;

            // if Leave Type is "consecutive", only one leave can be select
            if (x.leaveTake > 0 && this.props.user && this.props.leaveTypeEmployeeType) {

                let applicant = this.props.user.userList
                    .find(x => x.id == applicantId);
                // pessimistic
                if (!applicant) {
                    return false;
                }

                let nvdsa = this.props.leaveTypeEmployeeType.leaveTypePropsList
                    .find(x => x.leaveTypeId == leaveTypeId && x.employeeTypeId == applicant!.employeeTypeId);
                // pessimistic
                if (!nvdsa) {
                    return false;
                }

                if (nvdsa.consecutive) {
                    return false;
                }
            }

            return true;
        }
    }

    private leaveTypeFilter(applicantId: number | undefined, selected: number | undefined): (x: LeaveTypeModel) => boolean {
        if (isNullOrUndefined(applicantId) || isNullOrUndefined(this.props.user) || isNullOrUndefined(this.props.leaveTypeEmployeeType)) {
            return () => false;
        }

        let applicant = this.props.user.userList.find(x => x.id == applicantId);
        if (isNullOrUndefined(applicant)) {
            return () => false;
        }
        let leaveTypeList = this.props.leaveTypeEmployeeType.leaveTypePropsList.filter(x => x.employeeTypeId == applicant!.employeeTypeId).map(x => x.leaveTypeId)

        if (isNullOrUndefined(selected)) {
            return (leaveType: LeaveTypeModel): boolean => {
                return leaveTypeList.includes(leaveType.id);
            }
        } else {
            return (leaveType: LeaveTypeModel): boolean => {
                return selected == leaveType.id || leaveTypeList.includes(leaveType.id);
            }
        }
    }

    private applicantALTaken(applicantId: number) {
        if (isNullOrUndefined(this.props.userAnnuallyLeaveTaked) || isNullOrUndefined(this.props.leaveType) || isNullOrUndefined(this.props.user)) {
            return null;
        }

        if (this.props.userAnnuallyLeaveTaked.isLoading) {
            return (<CircularProgress size="2rem" />);
        }

        let applicant = this.props.user.userList.find(x => x.id == applicantId);

        if (isNullOrUndefined(applicant)) {
            return (<>Applicant invalid</>);
        }
        let leaveType_al = this.props.leaveType.leaveTypeList.find(x => x.leaveTypeName == 'annual_leave');

        if (isNullOrUndefined(leaveType_al)) {
            console.warn('Leave Type - AL not found');
            return (<>Error</>);
        }

        let currentYear = new Date().getFullYear();

        let LeaveTaken = this.props.userAnnuallyLeaveTaked.UserAnnuallyLeaveTakedList.find(x => x.year == currentYear && x.userId == applicantId && x.leaveTypeId == leaveType_al!.id);

        let leaveTakenNum = 0;

        if (!isNullOrUndefined(LeaveTaken) && !isNullOrUndefined(LeaveTaken.leaveTake)) {
            leaveTakenNum = LeaveTaken.leaveTake;
        }
        return (<>{this.props.i18n.t('leave_request.form.al_taken', { year: currentYear, taken: leaveTakenNum, total: applicant!.entitledLeave })}</>);
    }

    private needPreApproval(applicantId: number, leaveTypeId: number): boolean {
        if (this.props.leaveTypeEmployeeType == undefined) {
            return false;
        }
        if (this.props.user == undefined) {
            return false;
        }
        let applicant = this.props.user.userList.find(x => x.id == applicantId);
        if (applicant == undefined) {
            return false;
        }

        let leaveTypePorps = this.props.leaveTypeEmployeeType.leaveTypePropsList.find(x => x.leaveTypeId == leaveTypeId && x.employeeTypeId == applicant!.employeeTypeId);

        if (leaveTypePorps == undefined) {
            return false;
        }

        return leaveTypePorps.preApproval ? true : false;
    }

    private validationSchema() {
        var momentDayNow = moment(new Date()).startOf('day');

        var leaveDateFrom = Yup.date()
            .typeError(this.props.i18n.t('leave_request.form.leave_date.start_type_error'))
            .required(this.props.i18n.t('leave_request.form.leave_date.start_required'))
            .max(Yup.ref('leaveDateTo'), this.props.i18n.t('leave_request.form.leave_date.start_max'))

        var leaveDateTo = Yup.date()
            .typeError(this.props.i18n.t('leave_request.form.leave_date.end_type_error'))
            .required(this.props.i18n.t('leave_request.form.leave_date.end_required'))
            .min(Yup.ref('leaveDateFrom'), this.props.i18n.t('leave_request.form.leave_date.end_min'))

        var yupobj = Yup.object({
            applicant: Yup.number()
                .required(this.props.i18n.t('leave_request.form.required_applicant'))
                .min(1, this.props.i18n.t('leave_request.form.required_applicant')),
            leaveTypeId: Yup.number()
                .required(this.props.i18n.t('leave_request.form.required_leave_type'))
                .min(1, this.props.i18n.t('leave_request.form.required_leave_type')),
            leaveBalanceId: Yup.number()
                .when(['applicant', 'leaveTypeId'], {
                    is: (applicant: any, leaveTypeId: any) => {
                        if (!this.props.leaveTypeEmployeeType || !this.props.leaveTypeEmployeeType.leaveTypePropsList || !this.props.user) {
                            return false;
                        }

                        let userObj = this.props.user.userList.find(x => x.id == applicant);
                        if (isNullOrUndefined(userObj)) {
                            return false;
                        }

                        var leaveProp = this.props.leaveTypeEmployeeType.leaveTypePropsList
                            .find(x => x.employeeTypeId == userObj!.employeeTypeId && x.leaveTypeId == leaveTypeId);
                        if (leaveProp && leaveProp.preApproval) {
                            return true;
                        }
                        return false;
                    },
                    then: Yup.number()
                        .required(this.props.i18n.t('leave_request.form.required_leave_balance'))
                        .min(1, this.props.i18n.t('leave_request.form.required_leave_balance')),
                    otherwise: Yup.number(),
                }),
            leaveDate: Yup.array()
                .required(this.props.i18n.t('leave_request.form.leave_date.must_provide'))
                .min(1, this.props.i18n.t('leave_request.form.leave_date.min_one_select'))
                .of(Yup.object().shape({
                    leaveDateFrom: leaveDateFrom,
                    leaveDateTo: leaveDateTo,
                }))
                .when('leaveBalanceId', (leaveBalanceId: number | undefined, schema: Yup.ArraySchema<{
                    leaveDateFrom: Date;
                    leaveDateTo: Date;
                }>): Yup.ArraySchema<{
                    leaveDateFrom: Date;
                    leaveDateTo: Date;
                }> => {
                    if (!leaveBalanceId || !(leaveBalanceId > 0))
                        return schema

                    if (!this.props.preApprovalBalanceSpend) {
                        console.warn('Leave balance not found');
                        return schema;
                    }
                    var leaveBalanceSpend = this.props.preApprovalBalanceSpend.PreApprovalBalanceSpendList.find(x => x.leaveBalanceId == leaveBalanceId);

                    if (leaveBalanceSpend === undefined) {
                        console.warn('Leave balance not found');
                        return schema;
                    }

                    var remain: number = leaveBalanceSpend.totalBalance - leaveBalanceSpend.leaveTake;


                    schema = schema.test({
                        name: 'Pre-approval balance exceed',
                        test: (value: LeaveDate[]) => {
                            var apply: number = 0;

                            value.forEach(element => {
                                if (!element.leaveDateFrom || !element.leaveDateTo)
                                    return;
                                var start = moment(element.leaveDateFrom);
                                var end = moment(element.leaveDateTo);

                                if (end.diff(start, 'days') == 0) {
                                    switch (element.slot) {
                                        case 'FD':
                                            apply += 1;
                                            break;
                                        case 'AM':
                                        case 'PM':
                                            apply += 0.5;
                                            break;
                                    }
                                } else {
                                    apply += end.diff(start, 'days') + 1;
                                }
                            });

                            return apply <= remain;
                        },
                        params: { remain },
                        message: this.props.i18n.t('leave_request.form.leave_date.apply_over_remain', { remain: remain }),
                    })

                    return schema;
                })
                .when(['applicant', 'leaveTypeId'], (applicant: number | undefined, leaveTypeId: number | undefined, schema: Yup.ArraySchema<{
                    leaveDateFrom: Date;
                    leaveDateTo: Date;
                }>): Yup.ArraySchema<{
                    leaveDateFrom: Date;
                    leaveDateTo: Date;
                }> => {
                    if (leaveTypeId !== 1) { // 2020-11-04 Annual Leave type id will alway '1'.
                        return schema;
                    }

                    if (!applicant || !leaveTypeId) {
                        return schema;
                    }

                    if (this.props.user === undefined) {
                        console.warn('user not found');
                        return schema;
                    }

                    if (this.props.leaveType === undefined) {
                        console.warn('Leave Type not found');
                        return schema;
                    }

                    var user = this.props.user.userList.find(x => x.id == applicant);
                    var leaveType = this.props.leaveType.leaveTypeList.find(x => x.id == leaveTypeId);

                    if (user === undefined) {
                        console.warn('user not found');
                        return schema;
                    }

                    if (leaveType === undefined) {
                        console.warn('Leave Type not found');
                        return schema;
                    }
                    var remain = user.entitledLeave;

                    if (this.props.userAnnuallyLeaveTaked) {

                        let takenRecord = this.props.userAnnuallyLeaveTaked.UserAnnuallyLeaveTakedList.find(
                            x => x.userId == applicant
                                && x.year
                                && x.year.toString() == moment().format('YYYY')
                                && x.leaveTypeId == leaveTypeId
                        );

                        if (takenRecord && takenRecord.leaveTake) {
                            remain -= takenRecord.leaveTake;
                        }
                    }

                    schema = schema.test({
                        name: 'AL balance exceed',
                        test: (value: LeaveDate[]) => {
                            var apply: number = 0;

                            value.forEach(element => {
                                if (!element.leaveDateFrom || !element.leaveDateTo)
                                    return;
                                var start = moment(element.leaveDateFrom);
                                var end = moment(element.leaveDateTo);

                                if (moment().startOf('year').diff(start.startOf('day'), 'days') > 0) {
                                    start = moment().startOf('year');
                                }

                                if (end.startOf('day').diff(moment().endOf('year'), 'days') > 0) {
                                    end = moment().endOf('year');
                                }

                                if (end.diff(start, 'days') == 0) {
                                    switch (element.slot) {
                                        case 'FD':
                                            apply += 1;
                                            break;
                                        case 'AM':
                                        case 'PM':
                                            apply += 0.5;
                                            break;
                                    }
                                } else {
                                    apply += end.diff(start, 'days') + 1;
                                }
                            });

                            return apply <= remain;
                        },
                        params: { remain },
                        message: this.props.i18n.t('leave_request.form.leave_date.apply_over_remain', { remain: remain }),
                    })

                    return schema
                })
                .when([
                    'applicant',
                    'leaveBalanceId',
                    'leaveTypeId',
                ], (
                    applicantId: number | undefined,
                    leaveBalanceId: number | undefined,
                    leaveTypeId: number | undefined,
                    schema: Yup.ArraySchema<{
                        leaveDateFrom: Date;
                        leaveDateTo: Date;
                    }>,
                ): Yup.ArraySchema<{
                    leaveDateFrom: Date;
                    leaveDateTo: Date;
                }> => {
                    if (!leaveTypeId || !(leaveTypeId > 0)) {
                        console.warn('leaveType id value incorrect');
                        return schema;
                    }

                    if (this.props.leaveType === undefined) {
                        console.warn('leaveType store undefined');
                        return schema;
                    }
                    if (this.props.leaveTypeEmployeeType === undefined) {
                        console.warn('leaveTypeEmployeeType store undefined');
                        return schema;
                    }

                    if (this.props.preApprovalBalanceSpend === undefined) {
                        console.warn('BalanceSpend store undefined');
                        return schema;
                    }

                    if (this.props.user === undefined) {
                        console.warn('user store undefined');
                        return schema;
                    }

                    let applicant = this.props.user.userList.find(x => x.id == applicantId);

                    if (applicant === undefined) {
                        console.warn('Applicant not found');
                        return schema;
                    }

                    let leaveType = this.props.leaveType.leaveTypeList.find(x => x.id == leaveTypeId)
                    let leaveTypeProp = this.props.leaveTypeEmployeeType.leaveTypePropsList.find(x => x.leaveTypeId == leaveTypeId && x.employeeTypeId == applicant!.employeeTypeId);
                    let leaveBalance = this.props.preApprovalBalanceSpend.PreApprovalBalanceSpendList.find(x => x.leaveBalanceId == leaveBalanceId);

                    if (leaveType === undefined) {
                        console.warn('Leave Type not found');
                        return schema;
                    }
                    if (leaveTypeProp === undefined) {
                        console.warn('Leave Type not found');
                        return schema;
                    }

                    let lLeaveDateFrom = leaveDateFrom;
                    let lLeaveDateTo = leaveDateTo;

                    if (leaveTypeProp.allowDateBack === false) {
                        lLeaveDateFrom = lLeaveDateFrom.min(moment(new Date()).startOf('day').toDate(), this.props.i18n.t('leave_request.form.leave_date.start_no_dateback'));
                    }

                    if (leaveTypeProp.consecutive === true) {
                        schema = schema.max(1, this.props.i18n.t('leave_request.form.leave_date.consecutive'));
                    }

                    if (leaveTypeProp.validFrom !== undefined && leaveTypeProp.validFrom !== null) {
                        var validFromMoment = moment(new Date()).startOf('day').add(-leaveTypeProp.validFrom, 'days');

                        lLeaveDateFrom = lLeaveDateFrom.min(validFromMoment.toDate(), this.props.i18n.t('leave_request.form.leave_date.start_later', {
                            'date': validFromMoment.format("YYYY-MM-DD")
                        }));
                        /*lLeaveDateTo = lLeaveDateTo.min(validFromMoment.toDate(), this.props.i18n.t('leave_request.form.leave_date.end_later', {
                            'date': validFromMoment.format("YYYY-MM-DD")
                        }));*/

                    }

                    var validToMoment: Moment | undefined = undefined;
                    if (leaveBalance !== undefined) {
                        if (leaveBalance.expiryDate !== undefined && leaveBalance.expiryDate !== null) {
                            validToMoment = leaveBalance.expiryDate;
                        }
                    } else if (leaveTypeProp.validTo !== undefined && leaveTypeProp.validTo !== null) {
                        validToMoment = moment(new Date()).startOf('day').add(leaveTypeProp.validTo, 'days');
                    }

                    if (validToMoment) {
                        /*leaveDateFrom = leaveDateFrom.max(validToMoment.toDate(), this.props.i18n.t('leave_request.form.leave_date.start_earlier', {
                            'date': validToMoment.format("YYYY-MM-DD")
                        }));*/
                        lLeaveDateTo = lLeaveDateTo.max(validToMoment.toDate(), this.props.i18n.t('leave_request.form.leave_date.end_earlier', {
                            'date': validToMoment.format("YYYY-MM-DD")
                        }));
                    }

                    schema = schema.of(Yup.object().shape({
                        leaveDateFrom: lLeaveDateFrom,
                        leaveDateTo: lLeaveDateTo,
                    }))

                    return schema;
                }),
        });
        return yupobj;
    }

    public render() {
        if (this.state.authorized == false) {
            return (<Unauthorized />);
        }
        return (
            <React.Fragment>
                <Formik
                    enableReinitialize={true}
                    initialValues={this.state.leaveRequest}
                    validationSchema={this.validationSchema()}
                    onSubmit={(values, actions) => {
                        actions.setSubmitting(true)
                        try {
                            let includeWeekend = false
                            values.leaveDate && values.leaveDate.map(x => {
                                if (x.leaveDateFrom && x.leaveDateTo) {
                                    console.log('leaveDateFrom')
                                    let start = moment(x.leaveDateFrom)
                                    let end = moment(x.leaveDateTo)
                                    var current = start.clone();
                                    while (current.isSameOrBefore(end)) {
                                        console.log(current)
                                        console.log(current.day())

                                        if (current.day() === 0 || current.day() === 6)
                                            includeWeekend = true

                                        current.add(1, 'days')
                                    }
                                }
                            })

                            if (includeWeekend) {
                                if (confirm(this.props.i18n.t('leave_request.message.weekend_message'))) {
                                    this.submit(values)
                                }
                            }
                            else {
                                this.submit(values)
                            }


                        } finally {
                            actions.setSubmitting(false)
                        }
                    }}
                >
                    {(props: FormikProps<LeaveRequest>) => (
                        <Card style={{ margin: '0px auto', maxWidth: 1200 }}>

                            <Loading loading={props.isSubmitting || this.state.isLoading} />
                            <CardHeader title={this.props.i18n.t('leave_request.title')}>
                            </CardHeader>
                            <Form>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        {
                                            !this.state.isNew &&
                                            <Grid item md={12} xs={12}>
                                                <MuiTextField disabled label={this.props.i18n.t('leave_request.request_id')} fullWidth margin="dense" value={props.values.requestId} />
                                            </Grid>
                                        }
                                        <Grid item md={6} xs={12}>
                                            <Field name="applicant" component={TextField}
                                                select
                                                // disabled={!this.state.isNew}
                                                InputProps={{
                                                    readOnly: !(this.state.isNew && this.props.auth && this.props.auth.user && this.props.auth.user.hradmin),
                                                }}
                                                label={this.props.i18n.t('leave_request.applicant')}
                                                required={true}
                                                margin="dense"
                                                type="number"
                                                onChange={(e: React.ChangeEvent<any>) => {
                                                    props.handleChange(e);
                                                    if (!this.props.preApprovalBalanceSpend) {
                                                        return;
                                                    }
                                                    if (!this.props.leaveTypeEmployeeType) {
                                                        return;
                                                    }
                                                    if (!this.props.user) {
                                                        return;
                                                    }
                                                    let applicant = this.props.user.userList.find(x => x.id == e.target.value);
                                                    if (isNullOrUndefined(applicant)) {
                                                        return;
                                                    }
                                                    let leaveTypeProp = this.props.leaveTypeEmployeeType.leaveTypePropsList
                                                        .find(x => x.employeeTypeId == applicant!.employeeTypeId && x.leaveTypeId == props.values.leaveTypeId);

                                                    let osim = this.props.preApprovalBalanceSpend.PreApprovalBalanceSpendList
                                                        .filter(this.preApprovalBalanceSpendFilter(e.target.value, props.values.leaveTypeId))

                                                    if (osim && osim.length == 1) {
                                                        props.setFieldValue('leaveBalanceId', osim[0].leaveBalanceId);
                                                    } else {
                                                        props.setFieldValue('leaveBalanceId', 0);
                                                    }
                                                }}>
                                                <MenuItem value="">
                                                    <em>{this.props.i18n.t('none')}</em>
                                                </MenuItem>
                                                {this.props.user != undefined && this.props.user.userList.map((option: UserModel) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.userName}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <MuiTextField
                                                // disabled
                                                label={this.props.i18n.t('status')}
                                                fullWidth
                                                margin="dense"
                                                value={this.props.i18n.t('common_status.' + props.values.status)}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Field name="leaveTypeId" component={TextField}
                                                select
                                                // disabled={!this.state.isNew}
                                                InputProps={{
                                                    readOnly: !this.state.isNew,
                                                }}
                                                label={this.props.i18n.t('leave_request.leave_type')}
                                                required={true}
                                                margin="dense"
                                                type="number"
                                                onChange={(e: React.ChangeEvent<any>) => {
                                                    props.handleChange(e);
                                                    if (!this.props.preApprovalBalanceSpend) {
                                                        return;
                                                    }
                                                    var osim = this.props.preApprovalBalanceSpend.PreApprovalBalanceSpendList
                                                        .filter(this.preApprovalBalanceSpendFilter(props.values.applicant, e.target.value));
                                                    if (osim && osim.length == 1) {
                                                        props.setFieldValue('leaveBalanceId', osim[0].leaveBalanceId);
                                                    } else {
                                                        props.setFieldValue('leaveBalanceId', 0);
                                                    }
                                                }}>
                                                <MenuItem value="">
                                                    <em>{this.props.i18n.t('none')}</em>
                                                </MenuItem>
                                                {this.props.leaveType != undefined && this.props.leaveType.leaveTypeList
                                                    .filter(this.leaveTypeFilter(props.values.applicant, this.state.leaveRequest.leaveTypeId))
                                                    .map((option: LeaveTypeModel) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {this.props.i18n.t('ddl.leavetype.' + option.leaveTypeName)}
                                                        </MenuItem>
                                                    ))}
                                            </Field>
                                            {this.props.leaveType != undefined && props.values.applicant != undefined && props.values.applicant > 0
                                                && props.values.leaveTypeId != undefined && props.values.leaveTypeId > 0
                                                && this.props.leaveType.leaveTypeList.find(x => x.id == props.values.leaveTypeId)
                                                && this.props.leaveType.leaveTypeList.find(x => x.id == props.values.leaveTypeId)!.leaveTypeName == 'annual_leave'
                                                &&

                                                <FormHelperText>{this.applicantALTaken(props.values.applicant)}</FormHelperText>
                                            }
                                        </Grid>


                                        {props.values.applicant != undefined &&
                                            props.values.leaveTypeId != undefined &&
                                            this.needPreApproval(props.values.applicant, props.values.leaveTypeId) &&

                                            <Grid item md={6} xs={12}>
                                                <Field name="leaveBalanceId" component={TextField}
                                                    select
                                                    // disabled={!this.state.isNew}
                                                    InputProps={{
                                                        readOnly: !this.state.isNew,
                                                    }}
                                                    label={this.props.i18n.t('leave_request.balance')}
                                                    required={true}
                                                    margin="dense"
                                                    type="number">
                                                    {this.props.preApprovalBalanceSpend != undefined
                                                        && this.props.preApprovalBalanceSpend.PreApprovalBalanceSpendList
                                                            .filter(this.preApprovalBalanceSpendFilter(props.values.applicant, props.values.leaveTypeId, props.values.leaveBalanceId))
                                                            .length != 1
                                                        &&
                                                        <MenuItem value="">
                                                            <em>{this.props.i18n.t('none')}</em>
                                                        </MenuItem>
                                                    }
                                                    {this.props.preApprovalBalanceSpend != undefined && this.props.preApprovalBalanceSpend.PreApprovalBalanceSpendList
                                                        .filter(this.preApprovalBalanceSpendFilter(props.values.applicant, props.values.leaveTypeId, props.values.leaveBalanceId))
                                                        .map((option: PreApprovalBalanceSpendModel, index: number) => (
                                                            <MenuItem key={option.leaveBalanceId} value={option.leaveBalanceId}>
                                                                <Typography>{option.preApprovalRequestId} ({option.leaveTake}/{option.totalBalance}) {option.expiryDate &&
                                                                    <React.Fragment>
                                                                        {this.props.i18n.t('expire_on')}:
                                                                        <ReactMoment.default date={option.expiryDate} format="YYYY-MM-DD" />
                                                                    </React.Fragment>
                                                                }
                                                                </Typography>
                                                            </MenuItem>
                                                        ))}
                                                </Field>
                                            </Grid>
                                        }


                                        <Grid item xs={12} >
                                            <FieldArray name="leaveDate">
                                                {({ move, swap, push, insert, unshift, remove, pop }) => (


                                                    <TableContainer component={Paper}>
                                                        <Table size="small" aria-label="Leave table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {this.state.isNew &&
                                                                        <TableCell style={{ minWidth: "2rem", width: "5%" }} className={this.props.classes.TableCell}>
                                                                            {
                                                                                !isNullOrUndefined(props.values.leaveTypeId) && props.values.leaveTypeId > 0 &&
                                                                                (
                                                                                    (
                                                                                        this.props.user &&
                                                                                        this.props.leaveTypeEmployeeType &&
                                                                                        this.props.user.userList.find(x => x.id == props.values.applicant) &&
                                                                                        this.props.leaveTypeEmployeeType.leaveTypePropsList
                                                                                            .find(x => x.leaveTypeId == props.values.leaveTypeId &&
                                                                                                x.employeeTypeId == this.props.user!.userList.find(x => x.id == props.values.applicant)!.employeeTypeId) &&

                                                                                        !this.props.leaveTypeEmployeeType.leaveTypePropsList
                                                                                            .find(x => x.leaveTypeId == props.values.leaveTypeId &&
                                                                                                x.employeeTypeId == this.props.user!.userList.find(x => x.id == props.values.applicant)!.employeeTypeId)!
                                                                                            .consecutive
                                                                                    )
                                                                                    ||
                                                                                    (
                                                                                        props.values.leaveDate && props.values.leaveDate.length < 1
                                                                                    )
                                                                                )
                                                                                &&
                                                                                <IconButton size="small" onClick={() => push({ id: 0, leaveDateFrom: null, leaveDateTo: null, slot: 'FD' })}>
                                                                                    <AddIcon />
                                                                                </IconButton>
                                                                            }
                                                                        </TableCell>
                                                                    }
                                                                    <TableCell style={{ minWidth: "6rem", width: "40%" }} className={this.props.classes.TableCell}>{this.props.i18n.t('leave_date.start')}</TableCell>
                                                                    <TableCell style={{ minWidth: "6rem", width: "40%" }} className={this.props.classes.TableCell}>{this.props.i18n.t('leave_date.end')}</TableCell>
                                                                    <TableCell style={{ minWidth: "4rem", width: "15%" }} className={this.props.classes.TableCell}>{this.props.i18n.t('leave_date.slot')}</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {props.values.leaveDate!.map((leaveDate: LeaveDate, index: number) => (
                                                                    <TableRow key={index}>
                                                                        {this.state.isNew &&
                                                                            <TableCell className={this.props.classes.TableCell}>
                                                                                <IconButton size="small" onClick={() => remove(index)}>
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        }
                                                                        <TableCell className={this.props.classes.TableCell}>
                                                                            <Field name={`leaveDate.${index}.leaveDateFrom`} component={DatePicker} readOnly={!this.state.isNew} label="" format="YYYY-MM-DD" okLabel={this.props.i18n.t('date_picker.ok')} cancelLabel={this.props.i18n.t('date_picker.cancel')} clearLabel={this.props.i18n.t('date_picker.clear')} placeholder="YYYY-MM-DD" margin="dense" />
                                                                        </TableCell>
                                                                        <TableCell className={this.props.classes.TableCell}>
                                                                            <Field name={`leaveDate.${index}.leaveDateTo`} component={DatePicker} readOnly={!this.state.isNew} label="" format="YYYY-MM-DD" okLabel={this.props.i18n.t('date_picker.ok')} cancelLabel={this.props.i18n.t('date_picker.cancel')} clearLabel={this.props.i18n.t('date_picker.clear')} placeholder="YYYY-MM-DD" margin="dense" />
                                                                        </TableCell>
                                                                        <TableCell className={this.props.classes.TableCell}>
                                                                            {leaveDate.leaveDateFrom && leaveDate.leaveDateTo && sameDay(leaveDate.leaveDateFrom, leaveDate.leaveDateTo) &&
                                                                                <Field name={`leaveDate.${index}.slot`} component={TextField} select InputProps={{ readOnly: !this.state.isNew }} label="" margin="dense">
                                                                                    <MenuItem value='FD'>{this.props.i18n.t('fd')}</MenuItem>
                                                                                    <MenuItem value='AM'>{this.props.i18n.t('am')}</MenuItem>
                                                                                    <MenuItem value='PM'>{this.props.i18n.t('pm')}</MenuItem>
                                                                                </Field>
                                                                            }
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>

                                                )}
                                            </FieldArray>

                                            <ErrorMessage name="leaveDate" render={msg => {
                                                if (typeof msg == 'string') {
                                                    return (<FormHelperText error={true}>{msg}</FormHelperText>)
                                                }
                                                else {
                                                    return null;
                                                }
                                            }} />
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            <div>
                                                <AttachmentList data={props.values.leaveAttachment!.filter((x: LeaveAttachment) => x.id != 0)} />
                                            </div>

                                            {this.state.leaveRequest.status != LeaveRequestStatus.Withdraw && this.state.leaveRequest.status != LeaveRequestStatus.Reject && this.props.auth && this.props.auth.user && (this.state.isNew || this.state.leaveRequest.applicant == this.props.auth.user.id) &&
                                                <FilePond
                                                    name="Files"
                                                    allowMultiple={true}
                                                    onupdatefiles={(fileItems: any) => {
                                                        let files: LeaveAttachment[] = fileItems.map(async (fileItem: any) => {
                                                            let fObj: LeaveAttachment = {
                                                                id: 0,
                                                                fileName: fileItem.file.name,
                                                                fileData: '',
                                                                contentType: 'application/octet-stream'//fileItems.file.type,
                                                            }
                                                            fObj.fileData = await convertFile(fileItem.file)
                                                            return fObj
                                                        })

                                                        Promise.all(files)
                                                            .then((completed) => {
                                                                var tStatud = this.state.leaveRequest.leaveAttachment
                                                                if (tStatud == undefined) {
                                                                    tStatud = completed
                                                                } else {
                                                                    tStatud = tStatud.filter(x => x.id != 0)
                                                                    tStatud = tStatud.concat(completed)
                                                                }
                                                                props.setFieldValue('leaveAttachment', tStatud)
                                                            })
                                                    }}
                                                    labelIdle={this.props.i18n.t('filepond.labelIdle')}
                                                />
                                            }



                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <MuiTextField disabled label={this.props.i18n.t('create_by')} fullWidth margin="dense" value={this.createByUserName()} />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <MuiTextField disabled label={this.props.i18n.t('last_modify')} fullWidth margin="dense" value={this.lastModifyUserName()} />
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            <Field name="remarks" component={TextField}
                                                margin="dense"
                                                label={this.props.i18n.t('remarks')}
                                                multiline
                                                // rows={5} 
                                                InputProps={{
                                                    readOnly: !this.state.isNew,
                                                }}
                                            />
                                        </Grid>

                                        {!this.state.isNew && this.state.leaveRequest.reportingLine &&
                                            <Grid item md={12} xs={12}>
                                                <ReportingLineTable data={this.state.leaveRequest.reportingLine.sort(x => x.approvalLevel ? x.approvalLevel : 0)} />
                                            </Grid>
                                        }
                                    </Grid>

                                </CardContent>
                                <Divider />
                                <CardActions style={{ float: 'right', marginRight: 10 }}>
                                    <Button component={Link} to="/leave_request"
                                        color="default"
                                        variant="outlined">
                                        {this.props.i18n.t('back')}
                                    </Button>
                                    {(this.state.leaveRequest.status == LeaveRequestStatus.Draft || this.state.leaveRequest.status == LeaveRequestStatus.Approved || this.state.leaveRequest.status == LeaveRequestStatus.PendingApprove) &&
                                        this.props.auth && this.props.auth.user && this.props.auth.user.id == this.state.leaveRequest.applicant &&
                                        <Button color="primary" variant="contained" type="submit">
                                            {this.state.isNew && this.props.i18n.t('submit')}
                                            {!this.state.isNew && this.props.i18n.t('save')}
                                        </Button>
                                    }
                                    {
                                        // only following sceniale can allow applicant to withdarw
                                        // 1. Case submitted, but did not complete the aproval flow
                                        // 2. Case approved, but haven't TAKE the leave

                                        // applicant Checking
                                        this.props.auth &&
                                        this.props.auth.user &&
                                        this.props.auth.user.id == this.state.leaveRequest.applicant
                                        &&
                                        (
                                            // Case 1
                                            (
                                                this.state.leaveRequest.status == LeaveRequestStatus.PendingApprove
                                            )
                                            ||
                                            //Case 2
                                            (
                                                this.state.leaveRequest.status == LeaveRequestStatus.Approved
                                                &&
                                                !isNullOrUndefined(this.state.leaveRequest.leaveDate)
                                                && this.state.leaveRequest.leaveDate.filter(x => {
                                                    // is any leave date not match withdraw
                                                    if (isNullOrUndefined(x.leaveDateFrom)) {
                                                        return true;
                                                    }
                                                    return !x.leaveDateFrom.isAfter(moment(), 'day');
                                                }).length == 0
                                            )
                                        ) &&
                                        <ConfirmDialog
                                            color="secondary"
                                            variant="contained"
                                            onConfirm={() => this.withdraw()}
                                            title={this.props.i18n.t('withdraw')}
                                            confirmMessage={this.props.i18n.t('leave_request.form.withdraw_confirm_message', { requestId: this.state.leaveRequest.requestId })}
                                        />
                                    }
                                    {!this.state.isNew && this.state.leaveRequest.status == LeaveRequestStatus.PendingApprove && this.props.auth && this.props.auth.user && this.state.leaveRequest.nextApprover == this.props.auth.user.id &&
                                        <RejectReasonDialog onConfirm={(reason) => { this.reject(reason) }} />
                                    }
                                    {!this.state.isNew && this.state.leaveRequest.status == LeaveRequestStatus.PendingApprove && this.props.auth && this.props.auth.user && this.state.leaveRequest.nextApprover == this.props.auth.user.id &&
                                        <Button color="primary" variant="contained" onClick={() => this.approve()}>{this.props.i18n.t('approve')}</Button>
                                    }
                                </CardActions>
                            </Form>
                        </Card>
                    )}
                </Formik>

            </React.Fragment >
        );
    }

}

export default compose(
    withTranslation(),
    withStyles(useStyles),
    connector,
)(LeaveRequestForm as any);

