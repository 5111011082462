import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';

import { RouteComponentProps } from 'react-router';
import * as PreApprovalRequestStore from '../store/PreApprovalRequestStore';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/styles';
import { Field, Form, Formik, FormikProps, FieldArray, ErrorMessage } from 'formik';

import { connect, ConnectedProps } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { ApplicationState } from '../store';
import * as AuthenticateStore from '../store/AuthenticateStore';
import * as PreApprovalBalanceSpendStore from '../store/PreApprovalBalanceSpendStore';
import * as TaskApprovalStore from '../store/TaskApprovalStore';
import * as UserStore from '../store/UserStore';
import * as LeaveTypeStore from '../store/LeaveTypeStore';
import * as LeaveTypeEmployeeTypeStore from '../store/LeaveTypeEmployeeTypeStore';
import UserName from '../component/UserName';
import LeaveTypeName from '../component/LeaveTypeName';
import AttachmentList from '../component/AttachmentList';
import Loading from '../component/Loading';
import ConfirmDialog from '../component/ConfirmDialog';
import RejectReasonDialog from '../component/RejectReasonDialog';
import ReportingLineTable from './ReportingLineTable';

import Unauthorized from '../views/Unauthorized';

import {
    PreApprovalRequest,
    PreApprovalRequestStatus,
    LeaveAttachment,
    LeaveType as LeaveTypeModel,
    User as UserModel,
    PreApprovalBalanceSpend as PreApprovalBalanceSpendModel,
    PreApprovalRefDate,
    TaskApproval,
} from '../model'

import { TextField } from 'material-ui-formik-components/TextField'
import { DatePicker } from 'material-ui-formik-components/DatePicker'
import { convertFile, setJWT } from '../helper'


import * as Yup from 'yup';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import "filepond/dist/filepond.min.css";

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

import {
    Backdrop,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardActions,
    CardContent,
    CircularProgress,
    Divider,
    FormHelperText,
    Grid,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField as MuiTextField,
    Theme,
    Typography,
    MenuItem,
} from '@material-ui/core';
import {
    Add as AddIcon,
    Clear as DeleteIcon,
    CloudDownload as DownloadIcon,
} from '@material-ui/icons';
import { isNullOrUndefined } from 'util';


const { FilePond, registerPlugin } = require('react-filepond');

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover,
        // },
    },
    monthButton: {
        marginRight: 10,
        marginBottom: 10,
    },
    actionButton: {
        float: 'right',
        marginRight: 10,
    },
    customRow: {
        height: 51
    },
    head: {
        // backgroundColor: '#c0c0c0',
    },
    headerCell: {
        fontWeight: 'bold',
        alignContent: 'left',
        whiteSpace: 'nowrap'
    },
    TableCell: {
        '@media (max-width: 599px)': {
            'padding': '3px 6px 3px 4px',
        },
    },
    infoArea: {
        marginBottom: 10,
    }
}));
const mapStateToProps = (state: ApplicationState) => {
    const {
        authenticate,
        leaveType,
        leaveTypeEmployeeType,
        preApprovalBalanceSpend,
        preApprovalRequest,
        taskApproval,
        user,
    } = state;
    return {
        auth: authenticate,
        leaveType: leaveType,
        leaveTypeEmployeeType: leaveTypeEmployeeType,
        preApprovalBalanceSpend: preApprovalBalanceSpend,
        preApprovalRequest: preApprovalRequest,
        taskApproval: taskApproval,
        user: user,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        ...UserStore.actionCreators,
        ...TaskApprovalStore.actionCreators,
        ...LeaveTypeStore.actionCreators,
        ...LeaveTypeEmployeeTypeStore.actionCreators,
        ...PreApprovalBalanceSpendStore.actionCreators,
        ...PreApprovalRequestStore.actionCreators,
    }, dispatch)
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type PreApprovalFormProps =
    {
        classes: {
            root: string;
            container: string;
            TableCell: string;
        },
    }
    & RouteComponentProps<{ recordId?: string }>
    & PropsFromRedux

const PreApprovalForm = (props: PreApprovalFormProps) => {

    const { auth, user, leaveType, leaveTypeEmployeeType, preApprovalBalanceSpend, preApprovalRequest, taskApproval, history, requestUserRecord, requestLeaveType, requestLeaveTypeEmployeeType, requestOnePreApprovalRequest, reuqestOnePreApprovalBlanceSpend, createPreApprovalRequest, updatePreApprovalRequest, match, withdrawPreApprovalRequest, hrApproval } = props

    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const [preApprovalRequestForm, setPreApprovalRequest] = useState<PreApprovalRequest>({
        id: 0,
        requestId: '',
        leaveNum: 0,
        applicant: (auth && auth.user) ? auth.user.id : 0,
        leaveTypeId: 0,
        remarks: '',
        status: PreApprovalRequestStatus.Draft,

        leaveAttachment: [],
        refDate: [
            {
                id: 0,
                date: null,
                reason: '',
            },
        ],
    })

    const [isNew, setIsNew] = useState(true)

    const [isSubmitting, setIsSubmitting] = useState(false)

    const backToLastPage = () => {
        history.goBack()
    }

    useEffect(() => {

        var routeRecordId = 0
        if (match.params
            && match.params.recordId
            && (routeRecordId = parseInt(match.params.recordId, 10))
            && routeRecordId > 0) {
            requestOnePreApprovalRequest(routeRecordId);
        }
    }, []);

    useEffect(() => {
        if (preApprovalRequest == undefined)
            return;
        let routeRecordId = 0;

        if (match.params
            && match.params.recordId
            && (routeRecordId = parseInt(match.params.recordId, 10))
            && routeRecordId > 0) {
            setIsNew(false);

            var psorgRecord = preApprovalRequest.preApprovalRequestList.find(x => x.id == routeRecordId);
            if (psorgRecord != undefined) {
                setPreApprovalRequest(psorgRecord);
            }
        }

    }, [preApprovalRequest]);

    useEffect(() => {
        if (!preApprovalRequestForm.id || preApprovalBalanceSpend == undefined || preApprovalBalanceSpend.isLoading)
            return;

        if (!preApprovalBalanceSpend.PreApprovalBalanceSpendList.find(x => x.preApprovalId == preApprovalRequestForm.id)) {
            reuqestOnePreApprovalBlanceSpend(preApprovalRequestForm.id);
        }
    }, [preApprovalRequestForm]);

    useEffect(() => {

    }, [preApprovalBalanceSpend]);

    useEffect(() => {
        if (user == undefined || !user.userList || user.userList.length == 0) {
            requestUserRecord()
        }
        if (leaveType == undefined || !leaveType.leaveTypeList || leaveType.leaveTypeList.length == 0) {
            requestLeaveType()
        }
        if (leaveTypeEmployeeType == undefined || !leaveTypeEmployeeType.leaveTypePropsList || leaveTypeEmployeeType.leaveTypePropsList.length == 0) {
            requestLeaveTypeEmployeeType()
        }
    }, []);

    const validationSchema = () => {

        var yupObj = Yup.object({
            applicant: Yup.number()
                .required(t('pre_approval.form.required_applicant'))
                .min(1, t('pre_approval.form.required_applicant')),
            leaveTypeId: Yup.number()
                .required(t('pre_approval.form.required_leave_type'))
                .min(1, t('pre_approval.form.required_leave_type')),
            leaveNum: Yup.number()
                .required(t('pre_approval.form.required_leave_num'))
                .integer(t('pre_approval.form.required_leave_num'))
                .min(1, t('pre_approval.form.min_leave_num'))
                .when(['applicant', 'leaveTypeId'], (applicant: number | undefined, leaveTypeId: number | undefined, schema: Yup.NumberSchema): Yup.NumberSchema => {

                    if (isNullOrUndefined(applicant) || isNullOrUndefined(leaveTypeId) || isNullOrUndefined(user) || isNullOrUndefined(leaveTypeEmployeeType)) {
                        return schema;
                    }

                    let applicantObj = user.userList.find(x => x.id == applicant);
                    if (isNullOrUndefined(applicantObj)) {
                        return schema;
                    }

                    let leaveTypeProp = leaveTypeEmployeeType.leaveTypePropsList.find(x => x.employeeTypeId == applicantObj!.employeeTypeId && x.leaveTypeId == leaveTypeId);

                    if (isNullOrUndefined(leaveTypeProp)) {
                        return schema;
                    }

                    if (leaveTypeProp.leaveNum) {
                        let max_leave_num_msg = t('pre_approval.form.max_leave_num', { max_leave_num: leaveTypeProp.leaveNum })
                        schema = schema.max(leaveTypeProp.leaveNum, max_leave_num_msg);
                    }

                    return schema;
                }),
            refDate: Yup.array<{
                date: Date,
                reason: string,
            }>().when(['applicant', 'leaveTypeId'], (applicant: number | undefined, leaveTypeId: number | undefined, schema: Yup.ArraySchema<{
                date: Date,
                reason: string,
            }>): Yup.ArraySchema<{
                date: Date,
                reason: string,
            }> => {
                if (isNullOrUndefined(applicant) || isNullOrUndefined(leaveTypeId) || isNullOrUndefined(user) || isNullOrUndefined(leaveTypeEmployeeType)) {
                    return schema;
                }

                let applicantObj = user.userList.find(x => x.id == applicant);
                if (isNullOrUndefined(applicantObj)) {
                    return schema;
                }

                let leaveTypeProp = leaveTypeEmployeeType.leaveTypePropsList.find(x => x.employeeTypeId == applicantObj!.employeeTypeId && x.leaveTypeId == leaveTypeId);

                if (isNullOrUndefined(leaveTypeProp)) {
                    return schema;
                }

                if (leaveTypeProp.preApprovalRefDate) {
                    schema = schema
                        .required(t('pre_approval.form.ref_date.must_provide'))
                        .min(1, t('pre_approval.form.ref_date.min_one_select'));

                    schema = schema.of(Yup.object().shape({
                        date: Yup.date()
                            .typeError(t('pre_approval.form.ref_date.date.type_error'))
                            .required(t('pre_approval.form.ref_date.date.required')),
                        reason: Yup.string()
                            .required(t('pre_approval.form.ref_date.reason.required')),
                    }))
                }

                return schema;
            }),
        });

        return yupObj;
    };

    const submit = (preApproval: PreApprovalRequest) => {
        setIsSubmitting(true);

        if (leaveTypeEmployeeType && user) {
            let applicant = user.userList.find(x => x.id == preApproval.applicant);

            if (isNullOrUndefined(applicant)) {
                setIsSubmitting(false);
                throw Error("Applicant is not allowed");
                return;
            }

            let levaeTypeProp = leaveTypeEmployeeType.leaveTypePropsList
                .find(x => x.leaveTypeId == preApproval.leaveTypeId && x.employeeTypeId == applicant!.employeeTypeId);

            if (isNullOrUndefined(levaeTypeProp)) {
                setIsSubmitting(false);
                throw Error("Leave Type is not allowed");
                return;
            }

            if (!levaeTypeProp.preApprovalRefDate) {
                preApproval.refDate = [];
            }
        }


        createPreApprovalRequest(preApproval, (result: boolean) => {
            switch (result) {
                case true:
                    alert(t("pre_approval.message.submit_success"))
                    history.push('/pre_approval');
                    break;
                case false:
                    alert(t('pre_approval.message.submit_failure'));
                    break;
            }
            setIsSubmitting(false);
        });
    }

    const update = (preApproval: PreApprovalRequest) => {
        setIsSubmitting(true);
        updatePreApprovalRequest(preApproval, (result: boolean) => {
            switch (result) {
                case true:
                    alert(t("pre_approval.message.update_success"))
                    history.push('/pre_approval');
                    break;
                case false:
                    alert(t('pre_approval.message.update_failure'));
                    break;
            }
            setIsSubmitting(false);

        });
    }

    const hr_action = (action: string, remark?: string) => {
        if (isNew) {
            throw new Error("Cannot approve brand new request");
        }

        if (!auth || !auth.user || !auth.user.hradmin) {
            throw new Error("You are not permitted HR admin");
        }

        if (isNullOrUndefined(remark))
            remark = "";

        let taskApproval: TaskApproval = {
            id: 0,
            appId: 200,
            referenceId: preApprovalRequestForm.id,
            action: action,
            remarks: remark
        }

        setIsSubmitting(true);
        hrApproval(taskApproval, (result: boolean) => {
            switch (result) {
                case true:
                    alert(t("pre_approval.message.approve_success"))
                    history.push('/pre_approval');
                    break;
                case false:
                    alert(t('pre_approval.message.approve_failure'));
                    break;
            }

            setIsSubmitting(false);
        });
    }

    const hr_approve = () => {
        hr_action('Approve');
    }

    const hr_reject = (reason?: string) => {
        hr_action('Reject', reason);
    }

    const approve = () => {
        if (isNew) {
            throw new Error("Cannot approve brand new request");
        }

        if (isNullOrUndefined(preApprovalRequestForm.reportingLine)) {
            throw new Error("Reporting line is null");
        }

        var readyup = preApprovalRequestForm.reportingLine.sort(x => x.approvalLevel ? x.approvalLevel : 0).find(x => isNullOrUndefined(x.action));

        if (isNullOrUndefined(readyup)) {
            throw new Error("No up-coming approve action in current request");
        }

        if (!auth || !auth.user || readyup.actionBy != auth.user.id) {
            throw new Error("You are not the next approver on this request");
        }

        let fetchDest = '/api/taskApproval/' + readyup.id;
        let method = 'PUT'

        readyup.action = "Approve";

        let httpOptions = {
            method: method, // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(readyup)
        }

        setJWT(httpOptions, auth);

        fetch(fetchDest, httpOptions).then((response) => {

            if (response.ok && response.status == 200) {
                alert(t('pre_approval.message.approve_success'))
                history.push('/pre_approval')
                return
                //return response.json() as Promise<LeaveRecord>
            } else if (response.ok && response.status == 201) {
                alert(t('pre_approval.message.approve_success'))
                history.push('/pre_approval')
                return
            } else if (response.ok && response.status == 204) {
                alert(t('pre_approval.message.approve_success'))
                history.push('/pre_approval')
                return
            } else {
                throw new Error("response isnt ok")
            }
        })
    }

    const reject = (reason?: string) => {
        if (isNew) {
            throw new Error("Cannot reject brand new request")
        }

        if (isNullOrUndefined(preApprovalRequestForm.reportingLine)) {
            throw new Error("Reporting line is null");
        }

        var readyup = preApprovalRequestForm.reportingLine.sort(x => x.approvalLevel ? x.approvalLevel : 0).find(x => isNullOrUndefined(x.action));

        if (isNullOrUndefined(readyup)) {
            throw new Error("No up-coming approve action in current request");
        }

        if (!auth || !auth.user || readyup.actionBy != auth.user.id) {
            throw new Error("You are not the next approver on this request");
        }

        let fetchDest = '/api/taskApproval/' + readyup.id;
        let method = 'PUT'

        readyup.action = "Reject";
        if (reason !== undefined)
            readyup.remarks = reason;

        fetch(fetchDest, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(readyup)
        }).then((response) => {
            if (response.ok && response.status == 200) {
                alert(t('pre_approval.message.reject_success'))
                history.push('/pre_approval')
                return
                //return response.json() as Promise<LeaveRecord>
            } else if (response.ok && response.status == 201) {
                alert(t('pre_approval.message.reject_success'))
                history.push('/pre_approval')
                return
            } else if (response.ok && response.status == 204) {
                alert(t('pre_approval.message.reject_success'))
                history.push('/pre_approval')
                return
            } else {
                throw new Error("response isnt ok")
            }
        })
    }

    const withdraw = () => {
        if (isNew) {
            throw new Error("Cannot reject brand new request")
        }

        setIsSubmitting(true);
        withdrawPreApprovalRequest(preApprovalRequestForm.id, (result: boolean) => {
            if (result) {
                alert(t("pre_approval.message.withdraw_success"))
                history.push('/pre_approval')
            } else {
                alert(t("pre_approval.message.withdraw_failure"))
                history.push('/pre_approval')
            }
            setIsSubmitting(false);
        })

    }

    const refDateRequired = (applicantId: number, leaveTypeId: number): boolean => {
        if (isNullOrUndefined(leaveType) || isNullOrUndefined(user) || isNullOrUndefined(leaveTypeEmployeeType)) {
            return false;
        }

        let applicant = user.userList.find(x => x.id == applicantId);
        if (isNullOrUndefined(applicant)) {
            console.warn("Applicant not found");
            return false;
        }

        let leaveTypeProp = leaveTypeEmployeeType.leaveTypePropsList.find(ltp => ltp.leaveTypeId == leaveTypeId && ltp.employeeTypeId == applicant!.employeeTypeId);

        if (isNullOrUndefined(leaveTypeProp)) {
            console.warn("Leave Type Employee Type not found");
            return false;
        }

        return leaveTypeProp!.preApprovalRefDate ? true : false;
    }

    if (preApprovalRequest && preApprovalRequest.authorized == false) {
        return (<Unauthorized />);
    }
    return (
        <React.Fragment>
            <Formik
                enableReinitialize={true}
                initialValues={preApprovalRequestForm}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    actions.setSubmitting(true)
                    try {
                        if (isNew) {
                            submit(values);
                        } else {
                            update(values);
                        }

                    } finally {
                        actions.setSubmitting(false);
                    }
                }}
            >
                {(props: FormikProps<PreApprovalRequest>) => (
                    <Form>
                        <Card style={{ margin: '0px auto', maxWidth: 1200 }}>

                            <CardHeader title={t('pre_approval.title')}>
                            </CardHeader>
                            <CardContent>
                                <Grid container spacing={3}>
                                    {
                                        !isNew &&
                                        <Grid item md={12} xs={12}>
                                            <MuiTextField disabled label={t('pre_approval.request_id')} fullWidth margin="dense" value={props.values.requestId} />
                                        </Grid>
                                    }
                                    <Grid item md={6} xs={12}>
                                        <Field name="applicant" component={TextField}
                                            select
                                            InputProps={{
                                                readOnly: !(auth && auth.user && auth.user.hradmin && isNew),
                                            }}
                                            label={t('pre_approval.applicant')}
                                            required={true}
                                            margin="dense"
                                            type="number">
                                            {user != undefined && user.userList.map((option: UserModel) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.userName}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </Grid>

                                    <Grid item md={6} xs={12}>
                                        <MuiTextField
                                            label={t('status')}
                                            fullWidth
                                            margin="dense"
                                            value={t('common_status.' + props.values.status)}
                                            InputProps={{ readOnly: true, }}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Field name="leaveTypeId" component={TextField}
                                            select
                                            InputProps={{ readOnly: !isNew, }}
                                            label={t('pre_approval.leave_type')}
                                            required={true}
                                            margin="dense"
                                            type="number"
                                            onChange={(e: React.ChangeEvent<any>) => {
                                                props.handleChange(e);

                                                let applicantId = props.values.applicant;
                                                let leaveTypeId = e.target.value;
                                                if (isNullOrUndefined(applicantId) || isNullOrUndefined(leaveTypeId) || isNullOrUndefined(user) || isNullOrUndefined(leaveTypeEmployeeType)) {
                                                    return;
                                                }

                                                let applicantObj = user.userList.find(x => x.id == applicantId);
                                                if (isNullOrUndefined(applicantObj)) {
                                                    return;
                                                }

                                                let leaveTypeProp = leaveTypeEmployeeType.leaveTypePropsList.find(x => x.employeeTypeId == applicantObj!.employeeTypeId && x.leaveTypeId == leaveTypeId);

                                                if (leaveTypeProp && leaveTypeProp.leaveNum) {
                                                    props.setFieldValue('leaveNum', leaveTypeProp.leaveNum);
                                                } else {
                                                    props.setFieldValue('leaveNum', '', true);
                                                }
                                            }}>
                                            {leaveTypeEmployeeType != undefined &&
                                                leaveTypeEmployeeType.leaveTypePropsList.filter(x => {
                                                    if (user == undefined) {
                                                        return false;
                                                    }
                                                    let applicant = user!.userList.find(x => x.id == props.values.applicant);
                                                    if (applicant == undefined) {
                                                        return false;
                                                    }
                                                    return x.employeeTypeId == applicant.employeeTypeId && x.preApproval === true
                                                }).map((option) => (
                                                    <MenuItem key={option.leaveTypeId} value={option.leaveTypeId}>
                                                        {option.leaveTypeId != undefined &&
                                                            <LeaveTypeName leaveTypeId={option.leaveTypeId} />
                                                        }
                                                    </MenuItem>
                                                ))
                                            }
                                        </Field>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Field name="leaveNum"
                                            component={TextField}
                                            InputProps={{ readOnly: !isNew, }}
                                            type="number"
                                            step="1"
                                            margin="dense"
                                            label={t('pre_approval.leave_num')}></Field>
                                    </Grid>
                                    {!isNullOrUndefined(props.values.applicant) && !isNullOrUndefined(props.values.leaveTypeId) && refDateRequired(props.values.applicant, props.values.leaveTypeId)
                                        &&
                                        <Grid item xs={12} >
                                            <FieldArray name="refDate">
                                                {({ move, swap, push, insert, unshift, remove, pop }) => (


                                                    <TableContainer component={Paper}>
                                                        <Table size="small" aria-label="Leave table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {isNew &&
                                                                        <TableCell style={{ minWidth: "2rem", width: "5%" }} className={classes.TableCell}>
                                                                            <IconButton size="small" onClick={() => push({ id: 0, date: null, reason: '' })}>
                                                                                <AddIcon />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    }
                                                                    <TableCell style={{ minWidth: "6rem", width: "40%" }} className={classes.TableCell}>{t('pre_approval.form.ref_date.date.title')}</TableCell>
                                                                    <TableCell style={{ minWidth: "7rem", width: "55%" }} className={classes.TableCell}>{t('pre_approval.form.ref_date.reason.title')}</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {props.values.refDate!.map((refDate: PreApprovalRefDate, index: number) => (
                                                                    <TableRow key={index}>
                                                                        {isNew &&
                                                                            <TableCell className={classes.TableCell}>
                                                                                <IconButton size="small" onClick={() => remove(index)}>
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        }
                                                                        <TableCell className={classes.TableCell}>
                                                                            <Field name={`refDate.${index}.date`} component={DatePicker} readOnly={!isNew} label="" format="YYYY-MM-DD" okLabel={t('date_picker.ok')} cancelLabel={t('date_picker.cancel')} clearLabel={t('date_picker.clear')} placeholder="YYYY-MM-DD" margin="dense" />
                                                                        </TableCell>
                                                                        <TableCell className={classes.TableCell}>
                                                                            <Field name={`refDate.${index}.reason`} component={TextField} readOnly={!isNew} label="" margin='dense' />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                )}
                                            </FieldArray>
                                            <ErrorMessage name="refDate" render={msg => {
                                                if (typeof msg == 'string') {
                                                    return (<FormHelperText error={true}>{msg}</FormHelperText>)
                                                }
                                                else {
                                                    return null;
                                                }
                                            }} />
                                        </Grid>
                                    }
                                    <Grid item md={12} xs={12}>
                                        <div>
                                            <AttachmentList data={props.values.leaveAttachment!.filter((x: LeaveAttachment) => x.id != 0)} />
                                        </div>

                                        {(preApprovalRequestForm.status != PreApprovalRequestStatus.Reject && preApprovalRequestForm.status != PreApprovalRequestStatus.Withdraw) && auth && auth.user && (isNew || preApprovalRequestForm.applicant == auth.user.id) &&
                                            <FilePond
                                                name="Files"
                                                allowMultiple={true}
                                                onupdatefiles={(fileItems: any) => {
                                                    let files: LeaveAttachment[] = fileItems.map(async (fileItem: any) => {
                                                        let fObj: LeaveAttachment = {
                                                            id: 0,
                                                            fileName: fileItem.file.name,
                                                            fileData: '',
                                                            contentType: 'application/octet-stream'//fileItems.file.type,
                                                        }
                                                        fObj.fileData = await convertFile(fileItem.file)
                                                        return fObj
                                                    })

                                                    Promise.all(files)
                                                        .then((completed) => {
                                                            var tStatud = preApprovalRequestForm.leaveAttachment
                                                            if (tStatud == undefined) {
                                                                tStatud = completed
                                                            } else {
                                                                tStatud = tStatud.filter(x => x.id != 0)
                                                                tStatud = tStatud.concat(completed)
                                                            }
                                                            props.setFieldValue('leaveAttachment', tStatud)
                                                        })
                                                }}
                                                labelIdle={t('filepond.labelIdle')}
                                            />
                                        }
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Field name="remarks" component={TextField}
                                            margin="dense"
                                            label={t('remarks')}
                                            multiline
                                            InputProps={{ readOnly: !isNew, }}
                                        />
                                    </Grid>

                                    {!isNew && preApprovalRequestForm.reportingLine &&
                                        <Grid item md={12} xs={12}>
                                            <ReportingLineTable data={preApprovalRequestForm.reportingLine.sort(x => x.approvalLevel ? x.approvalLevel : 0)} />
                                        </Grid>
                                    }
                                </Grid>
                            </CardContent>
                            <Divider />
                            <CardActions style={{ float: 'right', marginRight: 10 }}>
                                <Button color="default" variant="outlined"
                                    // onClick={() => backToLastPage()}
                                    component={Link} to="/pre_approval"
                                >{t('back')}</Button>
                                {(isNew || ((preApprovalRequestForm.status == PreApprovalRequestStatus.Draft || preApprovalRequestForm.status == PreApprovalRequestStatus.PendingApprove || preApprovalRequestForm.status == PreApprovalRequestStatus.PendingHRApprove || preApprovalRequestForm.status == PreApprovalRequestStatus.Approved) && auth && auth.user && props.values.applicant == auth.user.id)) &&
                                    <Button color="primary" variant="contained" type="submit">
                                        {isNew && t('submit')}
                                        {!isNew && t('save')}
                                    </Button>
                                }
                                {!isNew && preApprovalRequestForm.status == PreApprovalRequestStatus.PendingApprove && auth && auth.user && preApprovalRequestForm.nextApprover == auth.user.id &&
                                    <RejectReasonDialog onConfirm={reject} />
                                }
                                {!isNew && preApprovalRequestForm.status == PreApprovalRequestStatus.PendingApprove && auth && auth.user && preApprovalRequestForm.nextApprover == auth.user.id &&
                                    <Button color="primary" variant="contained" onClick={() => approve()}>{t('approve')}</Button>
                                }
                                {!isNew && preApprovalRequestForm.status == PreApprovalRequestStatus.PendingHRApprove && auth && auth.user && auth.user.hradmin &&
                                    <RejectReasonDialog onConfirm={hr_reject} />
                                }
                                {!isNew && preApprovalRequestForm.status == PreApprovalRequestStatus.PendingHRApprove && auth && auth.user && auth.user.hradmin &&
                                    <Button color="primary" variant="contained" onClick={() => hr_approve()}>{t('approve')}</Button>
                                }
                                {
                                    // only following sceniale can allow applicant to withdarw
                                    // 1. Case submitted, but did not complete the aproval flow
                                    // 2. Case approved, but haven't TAKE the leave

                                    // applicant Checking
                                    auth &&
                                    auth.user &&
                                    auth.user.id == preApprovalRequestForm.applicant
                                    &&
                                    (

                                        // Case 1
                                        (
                                            preApprovalRequestForm.status == PreApprovalRequestStatus.PendingApprove
                                            ||
                                            preApprovalRequestForm.status == PreApprovalRequestStatus.PendingHRApprove
                                        )
                                        ||
                                        // Case 2
                                        (
                                            preApprovalRequestForm.status == PreApprovalRequestStatus.Approved
                                            &&
                                            !isNullOrUndefined(preApprovalBalanceSpend)
                                            && preApprovalBalanceSpend.PreApprovalBalanceSpendList
                                                .find(x => x.preApprovalId == preApprovalRequestForm.id)
                                            && preApprovalBalanceSpend.PreApprovalBalanceSpendList
                                                .find(x => x.preApprovalId == preApprovalRequestForm.id)!.leaveTake === 0
                                        )
                                    ) &&

                                    <ConfirmDialog
                                        color="secondary"
                                        variant="contained"
                                        onConfirm={() => withdraw()}
                                        title={t('withdraw')}
                                        confirmMessage={t('pre_approval.form.withdraw_confirm_message', { requestId: preApprovalRequestForm.requestId })}
                                    />
                                }
                            </CardActions>
                        </Card>
                    </Form>
                )}
            </Formik>
            <Loading loading={isSubmitting || preApprovalRequest == undefined || preApprovalRequest.isLoading || taskApproval == undefined || taskApproval.isLoading} />
        </React.Fragment>
    )
}


export default compose(
    connector,
)(PreApprovalForm as any) 