import * as React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment, { Moment } from 'moment';
import * as ReactMoment from 'react-moment';
import { Field, Form, Formik, FormikProps, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import "filepond/dist/filepond.min.css";

import { createStyles, Theme, withStyles, WithStyles, makeStyles, useTheme } from '@material-ui/core/styles';

import { tableIcons } from '../bug-fix/mui-table-tableicons';

import { connect, ConnectedProps } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { ApplicationState } from '../store';
import * as AuthenticateStore from '../store/AuthenticateStore';
import * as UserStore from '../store/UserStore';
import * as OvertimeRequestStore from '../store/OvertimeRequestStore';

import { Autocomplete } from 'material-ui-formik-components/Autocomplete';
import { TextField } from 'material-ui-formik-components/TextField';
import { Select } from 'material-ui-formik-components/Select';
import { KeyboardDatePicker } from 'material-ui-formik-components/KeyboardDatePicker';
import { DatePicker } from 'material-ui-formik-components/DatePicker';
import Loading from '../component/Loading';
import ConfirmDialog from '../component/ConfirmDialog';
import RejectReasonDialog from '../component/RejectReasonDialog';


import MaterialTable, { Column } from 'material-table';
import MuiTable from '../i18n/MuiTable';
import { RouteComponentProps } from 'react-router';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardActions,
    CardContent,
    Divider,
    FormHelperText,
    Grid,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableFooter,
    TableSortLabel,
    TextField as MuiTextField,
    Typography,
    MenuItem,
    InputLabel,
} from '@material-ui/core';
import {
    Add as AddIcon,
    Edit as EditIcon,
    Clear as DeleteIcon,
    CloudDownload as DownloadIcon,
    ImportantDevices,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    FirstPage as FirstPageIcon,
    LastPage as LastPageIcon
} from '@material-ui/icons';

import {
    LeaveAttachment,
    OvertimeDate,
    OvertimeRequest,
    OvertimeRequestStatus,
    User as UserModel,
    TaskApproval as TaskApprovalModel,
} from '../model'

import { Base64 } from 'js-base64'
import { type } from 'os';
import { isNullOrUndefined } from 'util';
import ReportingLineTable from './ReportingLineTable';
import AttachmentList from './AttachmentList';
import { setJWT } from '../helper';
//import { makeStyles } from '@material-ui/styles';
//import { OvertimeRequest } from '../views';
//import { OvertimeRequest } from '../views';
//import { OvertimeRequest } from '../views';
const { FilePond, registerPlugin } = require('react-filepond');

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);


function convertFile(file: File) {
    return new Promise<string>((resolve, reject) => {
        // 建立FileReader物件
        let reader = new FileReader()
        // 註冊onload事件，取得result則resolve (會是一個Base64字串)
        reader.onload = () => {
            if (typeof reader.result === 'string') {

                resolve(reader.result.substr(reader.result.indexOf(',') + 1))
            }
            else throw Error('Type of result in-correct: ' + typeof reader.result)
        }
        // 註冊onerror事件，若發生error則reject
        reader.onerror = () => { reject(reader.error) }
        // 讀取檔案
        reader.readAsDataURL(file)
    })
}

const dataURLtoFile = (dataurl: string, filename: string) => {

    var arr = dataurl.split(',')

    if (arr == null || arr == undefined || arr.length != 2) {
        throw Error('DataURL format incorrect, pleace ref to RFC 2397 - The "data" URL scheme')
    }


    var tMime = arr[0].match(/:(.*?);/)

    if (tMime == null || tMime == undefined || tMime.length == 0) {
        throw Error('MIME type (mediatype) does not specify in DataURL')
    }

    var mime = tMime[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        auth: state.authenticate,
        leaveType: state.leaveType,
        preApprovalBalanceSpend: state.preApprovalBalanceSpend,
        user: state.user,
        userAnnuallyLeaveTaked: state.userAnnuallyLeaveTaked,

    }
}
const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        ...UserStore.actionCreators,
        ...OvertimeRequestStore.actionCreators
    }, dispatch)
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type OvertimeRequestProps =
    {
        userid: number | undefined,
        classes: {
            root: string;
            container: string;
            TableCell: string;
            timeBox: string;
            hideAttendance: string;
        },
    }
    & RouteComponentProps<{ recordId?: string }> // ... plus incoming routing parameters
    & PropsFromRedux
    & WithTranslation


const useStyles = {
    root: {
        width: '100%',
        'font-size': '50px',
    },
    container: {
        maxHeight: '100%',
    },
    '@media (max-width: 599px)': {
        'TableCell': {
            'padding': '3px 6px 3px 4px',
        },
    },
    timeBox: {
        paddingRight: 5,
        // width: '100px',
    },
    hideAttendance: {
        display: 'none'
    }
};

type OvertimeRequestState = {
    isNew: boolean,
    overtimeRequest: OvertimeRequest,
    isApprover: boolean,
    rejectReason?: string,
    isHrAdmin: boolean,
    arPage: number,
    arRowsPerPage: number,
    datesNotAllow: Moment[],
    isLoading: boolean,
}

const useStyles1 = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexShrink: 0,
            marginLeft: theme.spacing(2.5),
        },
    }),
);

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}


const initialValues: OvertimeRequest = {
    id: 0,
    requestId: '',
    applicant: 0,
    nextApprover: 0,
    status: OvertimeRequestStatus.Draft,
    remarks: '',
    overtimeDate: [
        {
            id: 0,
            overtimeRequestId: 0,
            overtimeDate1: moment(new Date()).toDate(),
            fromTime: "0",
            toTime: "0",
            isActive: true,
            reason: ""
        },
    ],
    leaveAttachment: [],
    taskApproval: [],
    accessRecord: [],
    attendance: true
}

class OvertimeRequestForm extends React.Component<OvertimeRequestProps, OvertimeRequestState> {

    constructor(prop: OvertimeRequestProps) {
        super(prop)

        let initOTRequest = initialValues;
        if (this.props.auth !== undefined && this.props.auth.user) {
            initOTRequest.applicant = this.props.auth.user.id;
            initOTRequest = this.getInitialValue();
        }
        this.state = {
            isNew: true,
            overtimeRequest: initOTRequest,
            isApprover: false,
            isHrAdmin: false,
            arPage: 0,
            arRowsPerPage: 10,
            datesNotAllow: [],
            isLoading: false,
        }
        this.getHrAdmin();
        this.getRequestedDates();
    }

    public handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        this.setState((state, props) => {
            return {
                arPage: newPage,
            }
        });

    }

    public handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        if (event.target != null && event.target != undefined) {
            var rows = parseInt(event.target.value, 10);
            this.setState((state, props) => {
                return {
                    arPage: 0,
                    arRowsPerPage: rows
                }
            });
        }
    }

    public setOvertimeRequestDate = (index: number, overtimeRequest: OvertimeRequest, otDate: OvertimeDate, overtimeDate: Date) => {

        if (overtimeRequest.overtimeDate) {
            overtimeRequest.overtimeDate[index].overtimeDate1 = moment(overtimeDate).toDate();
        }
        if (otDate) {
            otDate.overtimeDate1 = moment(overtimeDate).toDate();
        }
        this.state.overtimeRequest.overtimeDate = overtimeRequest.overtimeDate;
        this.setState((state, props) => {
            return {
                isNew: true,
                overtimeRequest: overtimeRequest,
            }
        });
    }

    public setOvertimeRequestFromTime = (index: number, overtimeRequest: OvertimeRequest, otDate: OvertimeDate, time: string) => {

        if (overtimeRequest.overtimeDate) {
            overtimeRequest.overtimeDate[index].fromTime = time;
        }
        if (otDate) {
            otDate.fromTime = time;
        }
        this.state.overtimeRequest.overtimeDate = overtimeRequest.overtimeDate;
        this.setState((state, props) => {
            return {
                isNew: true,
                overtimeRequest: overtimeRequest,
            }
        });
    }

    public setOvertimeRequestToTime = (index: number, overtimeRequest: OvertimeRequest, otDate: OvertimeDate, time: string) => {

        if (overtimeRequest.overtimeDate)
            overtimeRequest.overtimeDate[index].toTime = time;
        if (otDate) {
            otDate.toTime = time;
        }
        this.state.overtimeRequest.overtimeDate = overtimeRequest.overtimeDate;
        this.setState((state, props) => {
            return {
                isNew: true,
                overtimeRequest: overtimeRequest,
            }
        });
    }

    public setOvertimeRequestRemarks = (overtimeRequest: OvertimeRequest, remarks: string) => {
        if (overtimeRequest)
            overtimeRequest.remarks = remarks;
        this.state.overtimeRequest.remarks = overtimeRequest.remarks;
        this.setState((state, props) => {
            return {
                isNew: true,
                overtimeRequest: overtimeRequest,
            }
        });
    }

    public setOvertimeRequestReasons = (index: number, overtimeRequest: OvertimeRequest, otDate: OvertimeDate, reason: string) => {
        if (overtimeRequest.overtimeDate)
            overtimeRequest.overtimeDate[index].reason = reason;
        if (otDate) {
            otDate.reason = reason;
        }
        this.state.overtimeRequest.overtimeDate = overtimeRequest.overtimeDate;
        this.setState((state, props) => {
            return {
                isNew: true,
                overtimeRequest: overtimeRequest,
            }
        });
    }

    public setRejectReason = (rejectReason: string) => {
        this.setState((state, props) => {
            return {
                rejectReason: rejectReason,
            }
        });
    }
    public getInitialValue(): OvertimeRequest {
        var req = initialValues;
        if (this.props.auth === undefined || !this.props.auth.user) {
            throw Error('Login required');
        }
        let fetchDest = '/api/overtimeRequest/init'
        let method = 'GET'

        var url = require('url');
        var qdata = { userid: this.props.auth.user.id.toString() };
        fetchDest = fetchDest + url.format({ query: qdata });
        fetch(fetchDest, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Bearer ' + this.props.auth.user.token,
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url        

        }).then(repsonse => repsonse.json())
            .then((data) => {
                req = data;
                this.setState((state, props) => {
                    return {
                        isNew: true,
                        overtimeRequest: req,
                    }
                });
            }).catch(function (error) { throw new Error("response isnt ok") });
        return req;
    }

    public updateAccessRecord(uid: number): void {
        if (!this.props.auth) {
            throw Error('Login required');
        }
        if (!this.props.auth.user) {
            throw Error('Login required');
        }
        let fetchDest = '/api/overtimeRequest/init'
        let method = 'GET'

        var url = require('url');
        var qdata = { userid: uid.toString() };
        fetchDest = fetchDest + url.format({ query: qdata });
        fetch(fetchDest, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Bearer ' + this.props.auth.user.token,
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url        

        }).then(response => { return response.json() as Promise<OvertimeRequest> })
            .then((data) => {
                this.state.overtimeRequest.accessRecord = data.accessRecord;
                this.setState((state, props) => {
                    return {
                        overtimeRequest: data,
                    }
                });
            }).catch(function (error) { throw new Error("response isnt ok") });
    }

    public getHrAdmin(): boolean {
        var req = false;
        if (!this.props.auth) {
            throw Error('Login required');
        }
        if (!this.props.auth.user) {
            throw Error('Login required');
        }
        let fetchDest = '/api/overtimeRequest/GetHrAdmin'
        let method = 'GET'

        fetch(fetchDest, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Bearer ' + this.props.auth.user.token,
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url        

        }).then(repsonse => repsonse.json())
            .then((data) => {
                req = data;
                this.setState((state, props) => {
                    return {
                        isHrAdmin: req
                    }
                });
            }).catch(function (error) { throw new Error("response isnt ok") });
        return req;
    }


    public getRequestedDates(userid?: number): void {
        //var req = [];
        if (!this.props.auth) {
            throw Error('Login required');
        }
        if (!this.props.auth.user) {
            throw Error('Login required');
        }
        let fetchDest = '/api/overtimeRequest/RequestedOTDate'
        let method = 'GET'
        //var uid=    this.state.overtimeRequest.applicant;
        var uid = 0;
        if (userid == undefined || userid == null) {
            uid = this.props.auth.user.id;
        }
        else {
            uid = userid;
        }
        var url = require('url');
        var qdata = { userid: uid.toString() };
        fetchDest = fetchDest + url.format({ query: qdata });
        fetch(fetchDest, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Bearer ' + this.props.auth.user.token,
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url        

        }).then(repsonse => repsonse.json() as Promise<Date[]>)
            .then((data) => {
                //req = data;
                var tmp = new Array<Moment>();
                data!.forEach(d => {
                    if (d !== null) {
                        var g = moment(d);
                        tmp.push(g);
                    }
                })
                this.setState((state, props) => {
                    return {
                        datesNotAllow: tmp
                    }
                });
            }).catch(function (error) { throw new Error("response isnt ok") });
        //return req;
    }
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched()
        let routeRecordId: number

        if (this.props.match.params
            && this.props.match.params.recordId
            && (routeRecordId = parseInt(this.props.match.params.recordId, 10))
            && routeRecordId > 0) {
            //try{
            this.loadOTRecord(routeRecordId);
            //}
            //catch(e){
            //    if(e.message=="Login required")
            //    {
            //       alert(this.props.i18n.t("overtimeRequest.message.session_expired"))
            //        this.props.history.push('/login')
            //       return
            //    }
            //}
        }
        this.cleanAsNewRecord()
    }

    private createByUserName(): string {
        var createBy = '';
        var createById = this.state.overtimeRequest.createdBy;

        if (createById && this.props.user !== undefined) {

            var createByUser = this.props.user.userList.find(x => x.id == createById)

            if (createByUser && createByUser.userName) {
                createBy = createByUser.userName;
            }
        }

        return createBy
    }

    private lastModifyUserName(): string {
        var lastModify = '';
        var lastModifyId = this.state.overtimeRequest.modifiedBy;

        if (lastModifyId && this.props.user !== undefined) {

            var lastModifyUser = this.props.user.userList.find(x => x.id == lastModifyId)

            if (lastModifyUser && lastModifyUser.userName) {
                lastModify = lastModifyUser.userName;
            }
        }

        return lastModify
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
    }

    private ensureDataFetched() {
        if (this.props.user === undefined || !this.props.user.userList || this.props.user.userList.length == 0) {
            this.props.requestUserRecord()
        }
    }

    private cleanAsNewRecord() {
        this.setState((state, props) => {
            return {
                isNew: true,
                overtimeRequest: (this.props.auth === undefined || this.props.auth.user == null) ? initialValues : this.getInitialValue(),
            }
        });
    }

    private loadOTRecord(recordId: number) {
        if (this.props.auth === undefined || !this.props.auth.user) {
            throw Error('Login required');
        }
        var isApprover = false;
        fetch('/api/overtimeRequest/' + recordId, {
            headers: {
                Authorization: 'Bearer ' + this.props.auth.user.token,
            }
        })
            .then(response => {
                if (!response.ok || response.status != 200) {
                    throw Error("response not ok");
                }
                return response.json() as Promise<OvertimeRequest>
            })
            .then(overtimeRequest => {
                if (this.props.auth === undefined || !this.props.auth.user) {
                    throw Error('Login required');
                }
                fetch('/api/overtimeRequest/' + recordId + '/GetDates', {
                    headers: {
                        Authorization: 'Bearer ' + this.props.auth.user.token,
                    }
                })
                    .then(response => {
                        if (!response.ok || response.status != 200) {
                            throw Error("response not ok");
                        }
                        return response.json() as Promise<OvertimeDate[]>
                    })
                    .then(overtimeDate => {
                        if (this.props.auth === undefined || !this.props.auth.user) {
                            throw Error('Login required');
                        }
                        var url = require('url');
                        var qdata = { id: recordId, userid: this.props.auth.user.id.toString() };
                        let fetchDest = '/api/overtimeRequest/CheckApprover';
                        fetchDest = fetchDest + url.format({ query: qdata });
                        fetch(fetchDest, {
                            headers: {
                                Authorization: 'Bearer ' + this.props.auth.user.token,
                            }
                        })
                            .then(response => {
                                if (!response.ok || response.status != 200) {
                                    throw Error("response not ok");
                                }
                                return response.json() as Promise<boolean>
                            })
                            .then(isCurrentApprover => {
                                isApprover = isCurrentApprover
                                overtimeRequest.overtimeDate = overtimeDate;
                                overtimeRequest.overtimeDate!.forEach(element => {
                                    if (element.overtimeDate1 !== null)
                                        element.overtimeDate1 = moment(element.overtimeDate1).toDate();
                                    if (element.fromTime !== null)
                                        element.fromTime = element.fromTime;
                                    if (element.toTime !== null)
                                        element.toTime = element.toTime;
                                });
                                overtimeRequest.accessRecord!.forEach(element => {
                                    if (element.overtimeDate1 !== null)
                                        element.overtimeDate1 = moment(element.overtimeDate1).toDate();
                                    if (element.fromTime !== null)
                                        element.fromTime = element.fromTime;
                                    if (element.toTime !== null)
                                        element.toTime = element.toTime;
                                });
                                this.setState((state, props) => {
                                    return {
                                        isNew: false,
                                        overtimeRequest: overtimeRequest,
                                        isApprover: isApprover
                                    }
                                });
                            })
                    })
                    .finally(() => {
                        this.setState({ isLoading: false });
                    });
                this.setState({ isLoading: true });
            })
    }




    private submit(overtimeRequest: OvertimeRequest) {
        if (this.props.auth === undefined || !this.props.auth.user) {
            throw Error('Login required');
        }
        let fetchDest = '/api/overtimeRequest/submit'
        let method = 'POST'

        fetch(fetchDest, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Bearer ' + this.props.auth.user.token,
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url        
            body: JSON.stringify(overtimeRequest)
        }).then((response) => {

            if (response.ok && response.status == 200) {
                alert(this.props.i18n.t("overtimeRequest.message.submit_success"))
                this.props.history.push('/overtime')
                return
                //return response.json() as Promise<LeaveRecord>
            } else if (response.ok && response.status == 201) {
                alert(this.props.i18n.t("overtimeRequest.message.submit_success"))
                this.props.history.push('/overtime')
                return
            } else if (response.ok && response.status == 204) {
                alert(this.props.i18n.t("overtimeRequest.message.submit_success"))
                this.props.history.push('/overtime')
                return
            } else {
                alert(this.props.i18n.t("overtimeRequest.message.submit_failed"))
                return //throw new Error("response isnt ok")
            }
        })
            .finally(() => {
                this.setState({ isLoading: false });
            });
        this.setState({ isLoading: true });
    }

    private approve() {
        if (this.props.auth === undefined || !this.props.auth.user) {
            throw Error('Login required');
        }

        if (this.state.isNew) {
            throw new Error("Cannot approve brand new request");
        }

        let fetchDest = '/api/overtimeRequest/' + this.state.overtimeRequest.id + '/approve';
        let method = 'POST'
        let data = { id: this.state.overtimeRequest.id, remarks: '' };

        fetch(fetchDest, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.props.auth.user.token,
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url        
            body: JSON.stringify(data)
        }).then((response) => {

            if (response.ok && response.status == 200) {
                alert(this.props.i18n.t("overtimeRequest.message.approve_success"))
                this.props.history.push('/overtime')
                return
            } else if (response.ok && response.status == 201) {
                alert(this.props.i18n.t("overtimeRequest.message.approve_success"))
                this.props.history.push('/overtime')
                return
            } else if (response.ok && response.status == 204) {
                alert(this.props.i18n.t("overtimeRequest.message.approve_success"))
                this.props.history.push('/overtime')
                return
            } else {
                throw new Error("response isnt ok")
            }
        })
            .finally(() => {
                this.setState({ isLoading: false });
            });
        this.setState({ isLoading: true });
    }

    private reject(reason?: string) {
        if (reason == undefined || reason.trim() == "") {
            alert(this.props.i18n.t("overtimeRequest.message.reject_reason_required"))
            return
        }
        if (this.props.auth === undefined || !this.props.auth.user) {
            throw Error('Login required');
        }

        if (this.state.isNew) {
            throw new Error("Cannot reject brand new request")
        }
        let data = { id: this.state.overtimeRequest.id, remarks: reason };

        let fetchDest = '/api/overtimeRequest/' + this.state.overtimeRequest.id + '/reject';
        let method = 'POST'

        fetch(fetchDest, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.props.auth.user.token,
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url        

            body: JSON.stringify(data)
        }).then((response) => {
            if (response.ok && response.status == 200) {
                alert(this.props.i18n.t("overtimeRequest.message.reject_success"))
                this.props.history.push('/overtime')
                return
            } else if (response.ok && response.status == 201) {
                alert(this.props.i18n.t("overtimeRequest.message.reject_success"))
                this.props.history.push('/overtime')
                return
            } else if (response.ok && response.status == 204) {
                alert(this.props.i18n.t("overtimeRequest.message.reject_success"))
                this.props.history.push('/overtime')
                return
            } else {
                throw new Error("response isnt ok")
            }
        })
            .finally(() => {
                this.setState({ isLoading: false });
            });
        this.setState({ isLoading: true });
    }
    private withdraw(reason?: string) {
        if (!this.props.auth) {
            throw Error('Login required');
        }

        if (!this.props.auth.user) {
            throw Error('Login required');
        }

        if (this.state.isNew) {
            throw new Error("Cannot reject brand new request")
        }
        let data = { id: this.state.overtimeRequest.id, remarks: reason };

        let fetchDest = '/api/overtimeRequest/' + this.state.overtimeRequest.id + '/withdraw';
        let method = 'POST'

        fetch(fetchDest, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.props.auth.user.token,
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url        

            body: JSON.stringify(data)
        }).then((response) => {
            if (response.ok && response.status == 200) {
                alert(this.props.i18n.t("overtimeRequest.message.withdraw_success"))
                this.props.history.push('/overtime')
                return
            } else if (response.ok && response.status == 201) {
                alert(this.props.i18n.t("overtimeRequest.message.withdraw_success"))
                this.props.history.push('/overtime')
                return
            } else if (response.ok && response.status == 204) {
                alert(this.props.i18n.t("overtimeRequest.message.withdraw_success"))
                this.props.history.push('/overtime')
                return
            } else {
                alert(this.props.i18n.t("overtimeRequest.message.withdraw_failed"))
                throw new Error("response isnt ok")
            }
        })
            .finally(() => {
                this.setState({ isLoading: false });
            });
        this.setState({ isLoading: true });
    }
    private dlAtth(index: number) {
        if (this.state.overtimeRequest.leaveAttachment && this.state.overtimeRequest.leaveAttachment[index]) {
            const linkSource = `data:application/octet-stream;base64,${this.state.overtimeRequest.leaveAttachment[index].fileData}`;
            const downloadLink = document.createElement("a");
            const fileName = this.state.overtimeRequest.leaveAttachment[index].fileName;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            return;
        }
        console.warn('Attachment not found')
        alert('Attachmnt not found')
    }

    private validationSchema(d: Moment[]) {

        var yupObj = Yup.object({
            applicant: Yup.number()
                .required(this.props.i18n.t('overtimeRequest.form.required_applicant'))
                .min(1, this.props.i18n.t('overtimeRequest.form.required_applicant')),

            overtimeDate: Yup.array<{
                overtimeDate1: Date,
                fromTime: string,
                toTime: string,
                reason: string
            }>().when('id', (id: number | undefined, schema: Yup.ArraySchema<{
                overtimeDate1: Date,
                fromTime: string,
                toTime: string,
                reason: string
            }>): Yup.ArraySchema<{
                overtimeDate1: Date,
                fromTime: string,
                toTime: string,
                reason: string
            }> => {
                if (isNullOrUndefined(id)) {
                    return schema;
                }
                schema = schema
                    .required(this.props.i18n.t('overtimeRequest.form.overtimeDate.must_provide'))
                    .min(1, this.props.i18n.t('overtimeRequest.form.overtimeDate.min_one_select'))

                if (d.length > 0) {
                    schema = schema.of(Yup.object().shape({
                        overtimeDate1: Yup.date()
                            .typeError(this.props.i18n.t('overtimeRequest.form.overtimeDate.date_type_error'))
                            .required(this.props.i18n.t('overtimeRequest.form.overtimeDate.date_required'))
                            .max(new Date(), this.props.i18n.t('overtimeRequest.form.overtimeDate.date_max'))
                            .test('is-incorrect', this.props.i18n.t('overtimeRequest.form.overtimeDate.date_selected'), function (value: Date) {
                                var chk = false;
                                d.forEach(element => {
                                    if (!chk) {
                                        var ed = moment(element).toDate();
                                        chk = (value.getDate() == ed.getDate()
                                            && value.getMonth() == ed.getMonth()
                                            && value.getFullYear() == ed.getFullYear());
                                    }
                                })
                                return !chk;
                            })
                        ,
                        fromTime: Yup.string()
                            .required(this.props.i18n.t('overtimeRequest.form.overtimeDate.fromTime_required'))
                        ,
                        toTime: Yup.string()
                            .required(this.props.i18n.t('overtimeRequest.form.overtimeDate.toTime_required')),
                        reason: Yup.string()
                            .required(this.props.i18n.t('overtimeRequest.form.required_reason')),
                    }))
                }
                else {
                    schema = schema.of(Yup.object().shape({
                        overtimeDate1: Yup.date()
                            .typeError(this.props.i18n.t('overtimeRequest.form.overtimeDate.date_type_error'))
                            .required(this.props.i18n.t('overtimeRequest.form.overtimeDate.date_required'))
                            .max(new Date(), this.props.i18n.t('overtimeRequest.form.overtimeDate.date_max'))
                        ,
                        fromTime: Yup.string()
                            .required(this.props.i18n.t('overtimeRequest.form.overtimeDate.fromTime_required'))
                        ,
                        toTime: Yup.string()
                            .required(this.props.i18n.t('overtimeRequest.form.overtimeDate.toTime_required')),
                        reason: Yup.string()
                            .required(this.props.i18n.t('overtimeRequest.form.required_reason')),
                    }))
                }
                return schema;
            }),
        });
        return yupObj;
    }

    public render() {


        return (
            <React.Fragment>
                <Formik
                    enableReinitialize={true}
                    initialValues={this.state.overtimeRequest}
                    validationSchema={this.validationSchema(this.state.datesNotAllow)}
                    onSubmit={(values, actions) => {
                        actions.setSubmitting(true)
                        try {
                            this.submit(values)
                        } finally {
                            actions.setSubmitting(false)
                        }
                    }}
                >
                    {(props: FormikProps<OvertimeRequest>) => (
                        <Card style={{ margin: '0px auto', maxWidth: 1200 }}>

                            <Loading loading={props.isSubmitting || this.state.isLoading} />
                            <CardHeader title={this.props.i18n.t('overtimeRequest.title')}>
                            </CardHeader>
                            <Form>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        {
                                            !this.state.isNew &&
                                            <Grid item md={12} xs={12}>
                                                <MuiTextField disabled label={this.props.i18n.t('pre_approval.request_id')} fullWidth margin="dense" value={props.values.requestId} />
                                            </Grid>
                                        }
                                        <Grid item md={6} xs={12}>
                                            <Field name="applicant" component={TextField}
                                                select
                                                //disabled={true}
                                                // disabled={!this.state.isNew}
                                                InputProps={{
                                                    readOnly: !(this.state.isHrAdmin && props.values.status == OvertimeRequestStatus.Draft),
                                                }}
                                                label={this.props.i18n.t('overtimeRequest.applicant')}
                                                required={true}
                                                margin="dense"
                                                type="number"
                                                onChange={
                                                    (e: React.ChangeEvent<any>) => {
                                                        props.handleChange(e);
                                                        this.updateAccessRecord(e.target.value);
                                                        this.getRequestedDates(e.target.value);
                                                    }
                                                }>
                                                <MenuItem value="">
                                                    <em>{this.props.i18n.t('none')}</em>
                                                </MenuItem>
                                                {this.props.user !== undefined && this.props.user.userList.map((option: UserModel) => (

                                                    <MenuItem key={option.id} value={option.id} >
                                                        {option.userName}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <MuiTextField
                                                // disabled
                                                label={this.props.i18n.t('status')}
                                                fullWidth
                                                margin="dense"
                                                value={this.props.i18n.t('common_status.' + props.values.status)}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Grid>
                                        {/* <Grid item md={6} xs={12}>
                                            <Field name="requestId" component={TextField}
                                                //select
                                                // disabled={!this.state.isNew}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                label={this.props.i18n.t('overtimeRequest.request_no')}
                                                //required={true}
                                                margin="dense"
                                                type="text"
                                                disabled={true}
                                                value={props.values.requestId}
                                                onChange={(e: React.ChangeEvent<any>) => {
                                                    props.handleChange(e);

                                                }}>

                                            </Field>
                                        </Grid> */}
                                        {/* <Grid item xs={12} className={props.values.attendance == true ? "" : this.props.classes.hideAttendance} >
                                            <Field name="lblAccessRecord" component={InputLabel}>
                                                {this.props.i18n.t('overtimeRequest.accessRecord')}
                                            </Field>
                                        </Grid> */}
                                        {props.values.attendance &&
                                            <Grid item xs={12} >
                                                <Field name="lblAccessRecord" component={InputLabel}>
                                                    {this.props.i18n.t('overtimeRequest.accessRecord')}
                                                </Field>
                                                <FieldArray name="accessRecord">
                                                    {({ move, swap, push, insert, unshift, remove, pop }) => (
                                                        <TableContainer component={Paper} className={props.values.attendance == true ? "" : this.props.classes.hideAttendance}>
                                                            <Table size="small" aria-label="Access Record for Reference" >
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className={this.props.classes.TableCell}>{this.props.i18n.t('overtimeDate.overtimeDate')}</TableCell>
                                                                        <TableCell className={this.props.classes.TableCell}>{this.props.i18n.t('overtimeDate.fromTime')}</TableCell>
                                                                        <TableCell className={this.props.classes.TableCell}>{this.props.i18n.t('overtimeDate.toTime')}</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {/*props.values.accessRecord!.map((otDate: any, index: number) => (*/
                                                                        (this.state.arRowsPerPage > 0
                                                                            ? props.values.accessRecord ? props.values.accessRecord.slice(this.state.arPage * this.state.arRowsPerPage, this.state.arPage * this.state.arRowsPerPage + this.state.arRowsPerPage) : []
                                                                            : props.values.accessRecord
                                                                        )!.map((otDate: any, index: number) => (
                                                                            <TableRow key={index}>
                                                                                <TableCell className={this.props.classes.TableCell}>
                                                                                    {moment(otDate.overtimeDate).format('YYYY-MM-DD')}
                                                                                </TableCell>
                                                                                <TableCell className={this.props.classes.TableCell}>
                                                                                    {otDate.fromTime}
                                                                                </TableCell>
                                                                                <TableCell className={this.props.classes.TableCell}>
                                                                                    {otDate.toTime}
                                                                                </TableCell>
                                                                                {/* <TableCell className={this.props.classes.TableCell}>
                                                                            <Field name={`accessRecord.${index}.overtimeDate`} component={DatePicker} readOnly={true} label="" format="YYYY-MM-DD" okLabel={this.props.i18n.t('date_picker.ok')} cancelLabel={this.props.i18n.t('date_picker.cancel')} clearLabel={this.props.i18n.t('date_picker.clear')} placeholder="YYYY-MM-DD" margin="dense" />
                                                                        </TableCell>
                                                                        <TableCell className={this.props.classes.TableCell}>
                                                                            <TimePicker placeholder="--:--"
                                                                                showSecond={false}
                                                                                minuteStep={1}
                                                                                disabled={true}
                                                                                value={moment(otDate.fromTime, 'HH:mm')}
                                                                                className={this.props.classes.timeBox}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell className={this.props.classes.TableCell}>
                                                                            <TimePicker placeholder="--:--"
                                                                                showSecond={false}
                                                                                minuteStep={1}
                                                                                disabled={true}
                                                                                value={moment(otDate.toTime, 'HH:mm')}
                                                                                className={this.props.classes.timeBox}
                                                                            />
                                                                        </TableCell> */}
                                                                            </TableRow>
                                                                        ))}
                                                                </TableBody>
                                                                <TableFooter>
                                                                    <TableRow>
                                                                        <TablePagination
                                                                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}

                                                                            colSpan={3}
                                                                            count={props.values.accessRecord ? props.values.accessRecord.length : 0}
                                                                            rowsPerPage={this.state.arRowsPerPage}
                                                                            page={this.state.arPage}
                                                                            labelRowsPerPage={this.props.i18n.t('overtimeRequest.arRowsPerPage')}
                                                                            SelectProps={{
                                                                                inputProps: { 'aria-label': 'Rows per Page' },
                                                                                native: true,
                                                                            }}
                                                                            onChangePage={this.handleChangePage}
                                                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                                            ActionsComponent={TablePaginationActions}
                                                                        />
                                                                    </TableRow>
                                                                </TableFooter>
                                                            </Table>
                                                        </TableContainer>

                                                    )}
                                                </FieldArray>

                                                <ErrorMessage name="accessRecord" render={msg => {
                                                    if (typeof msg == 'string') {
                                                        return (<FormHelperText error={true}>{msg}</FormHelperText>)
                                                    }
                                                    else {
                                                        return null;
                                                    }
                                                }} />
                                            </Grid>
                                        }
                                        {/* <Grid item xs={12} >
                                            <Field name="lblOvertimeDate" component={InputLabel}>
                                                {this.props.i18n.t('overtimeRequest.requestedDate')}
                                            </Field>
                                        </Grid> */}
                                        <Grid item xs={12} >
                                            <Field name="lblOvertimeDate" component={InputLabel}>
                                                {this.props.i18n.t('overtimeRequest.requestedDate')}
                                            </Field>
                                            <FieldArray name="overtimeDate">
                                                {({ move, swap, push, insert, unshift, remove, pop }) => (
                                                    <TableContainer component={Paper}>
                                                        <Table size="small" aria-label="Overtime Date table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {this.state.isNew &&
                                                                        <TableCell style={{ minWidth: "2rem", width: "5%" }} className={this.props.classes.TableCell}>

                                                                            <IconButton size="small" onClick={() => push({ id: 0, overtimeDate1: null, fromTime: "00:00", toTime: "00:00", overtimeRequestId: 0, isActive: true })
                                                                            }>
                                                                                <AddIcon />
                                                                            </IconButton>

                                                                        </TableCell>
                                                                    }
                                                                    <TableCell className={this.props.classes.TableCell}>{this.props.i18n.t('overtimeDate.overtimeDate')}</TableCell>
                                                                    <TableCell className={this.props.classes.TableCell}>{this.props.i18n.t('overtimeDate.fromTime')}</TableCell>
                                                                    <TableCell className={this.props.classes.TableCell}>{this.props.i18n.t('overtimeDate.toTime')}</TableCell>
                                                                    <TableCell className={this.props.classes.TableCell}>{this.props.i18n.t('overtimeRequest.reason')}</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {props.values.overtimeDate!.map((otDate: OvertimeDate, index: number) => (
                                                                    <TableRow key={index}>
                                                                        {this.state.isNew &&
                                                                            <TableCell className={this.props.classes.TableCell}>
                                                                                <IconButton size="small" onClick={() => remove(index)}>
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        }
                                                                        <TableCell className={this.props.classes.TableCell}>
                                                                            <Field name={`overtimeDate.${index}.overtimeDate1`}
                                                                                component={DatePicker}
                                                                                readOnly={!this.state.isNew} label="" format="YYYY-MM-DD" placeholder="YYYY-MM-DD" margin="dense"
                                                                                onChange={(e: any) => this.setOvertimeRequestDate(index, props.values, otDate, e)}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell className={this.props.classes.TableCell}>
                                                                            <TimePicker placeholder="--:--"
                                                                                defaultValue={moment('00:00', 'HH:mm')}
                                                                                showSecond={false}
                                                                                minuteStep={15}
                                                                                disabled={props.values.status != null && props.values.status != OvertimeRequestStatus.Draft}
                                                                                onChange={(e: any) =>
                                                                                    this.setOvertimeRequestFromTime(index, props.values, otDate, e == null ? null : e.format('HH:mm'))
                                                                                }
                                                                                allowEmpty={false}
                                                                                value={moment(otDate.fromTime, 'HH:mm')}
                                                                                format='HH:mm'
                                                                                className={this.props.classes.timeBox}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell className={this.props.classes.TableCell}>
                                                                            <TimePicker placeholder="--:--"
                                                                                defaultValue={moment('00:00', 'HH:mm')}
                                                                                showSecond={false}
                                                                                minuteStep={15}
                                                                                disabled={props.values.status != null && props.values.status != OvertimeRequestStatus.Draft}
                                                                                onChange={(e: any) =>
                                                                                    this.setOvertimeRequestToTime(index, props.values, otDate, e == null ? null : e.format('HH:mm'))
                                                                                }
                                                                                allowEmpty={false}
                                                                                value={moment(otDate.toTime, 'HH:mm')}
                                                                                format='HH:mm'
                                                                                className={this.props.classes.timeBox}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell className={this.props.classes.TableCell}>
                                                                            <Field name={`overtimeDate.${index}.reason`}
                                                                                component={TextField}
                                                                                label=""
                                                                                multiline
                                                                                required={true}
                                                                                disabled={props.values.status != null && props.values.status != OvertimeRequestStatus.Draft}
                                                                                readOnly={!this.state.isNew} margin="dense"
                                                                                onChange={(e: any) => this.setOvertimeRequestReasons(index, props.values, otDate, e.target.value)}
                                                                            />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>

                                                )}
                                            </FieldArray>

                                            <ErrorMessage name="overtimeDate" render={msg => {
                                                if (typeof msg == 'string') {
                                                    return (<FormHelperText error={true}>{msg}</FormHelperText>)
                                                }
                                                else {
                                                    return null;
                                                }
                                            }} />
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            <div>
                                                <AttachmentList data={props.values.leaveAttachment!.filter((x: LeaveAttachment) => x.id != 0)} />
                                            </div>

                                            {this.state.overtimeRequest.status == OvertimeRequestStatus.Draft &&
                                                <FilePond
                                                    name="Files"
                                                    allowMultiple={true}
                                                    onupdatefiles={(fileItems: any) => {
                                                        let files: LeaveAttachment[] = fileItems.map(async (fileItem: any) => {
                                                            let fObj: LeaveAttachment = {
                                                                id: 0,
                                                                fileName: fileItem.file.name,
                                                                fileData: '',
                                                                contentType: 'application/octet-stream'//fileItems.file.type,
                                                            }
                                                            fObj.fileData = await convertFile(fileItem.file)
                                                            return fObj
                                                        })

                                                        Promise.all(files)
                                                            .then((completed) => {
                                                                var tStatud = this.state.overtimeRequest.leaveAttachment
                                                                if (tStatud == undefined) {
                                                                    tStatud = completed
                                                                } else {
                                                                    tStatud = tStatud.filter(x => x.id != 0)
                                                                    tStatud = tStatud.concat(completed)
                                                                }
                                                                props.setFieldValue('leaveAttachment', tStatud)
                                                            })
                                                    }}
                                                    labelIdle={this.props.i18n.t('filepond.labelIdle')}
                                                />
                                            }

                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <MuiTextField disabled label={this.props.i18n.t('create_by')} fullWidth margin="dense" value={this.createByUserName()} />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <MuiTextField disabled label={this.props.i18n.t('last_modify')} fullWidth margin="dense" value={this.lastModifyUserName()} />
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <Field name="remarks" component={TextField}
                                                margin="dense"
                                                label={this.props.i18n.t('remarks')}
                                                multiline
                                                disabled={props.values.status != null && props.values.status != OvertimeRequestStatus.Draft}
                                                // rows={5} 
                                                InputProps={{
                                                    readOnly: !this.state.isNew,
                                                }}
                                                onChange={(e: any) =>
                                                    this.setOvertimeRequestRemarks(props.values, e == null ? "" : e.target.value)
                                                }
                                            />
                                        </Grid>
                                        {!this.state.isNew && this.state.overtimeRequest.taskApproval &&
                                            <Grid item md={12} xs={12}>
                                                <ReportingLineTable data={this.state.overtimeRequest.taskApproval} />
                                            </Grid>
                                        }
                                    </Grid>

                                </CardContent>
                                <Divider />
                                <CardActions style={{ float: 'right', marginRight: 10 }}>
                                    <Button component={Link} to="/overtime"
                                        color="default"
                                        variant="outlined">
                                        {this.props.i18n.t('back')}
                                    </Button>
                                    {(this.state.overtimeRequest.status == OvertimeRequestStatus.Draft) &&
                                        <Button color="primary" variant="contained" type="submit">
                                            {this.props.i18n.t('submit')}
                                        </Button>
                                    }
                                    {!this.state.isNew && (this.props.auth ? (this.props.auth.user ? (this.props.auth.user.id == this.state.overtimeRequest.applicant) : false) : false) && (this.state.overtimeRequest.status == OvertimeRequestStatus.PendingApproval || (this.state.overtimeRequest.status == OvertimeRequestStatus.PendingHRApproval)) &&

                                        <ConfirmDialog
                                            color="secondary"
                                            variant="contained"
                                            onConfirm={() => this.withdraw()}
                                            title={this.props.i18n.t('withdraw')}
                                            confirmMessage={this.props.i18n.t('overtimeRequest.form.withdraw_confirm_message', { requestId: this.state.overtimeRequest.requestId })}
                                        />
                                    }
                                    {!this.state.isNew && this.state.isApprover && (this.state.overtimeRequest.status == OvertimeRequestStatus.PendingApproval || (this.state.overtimeRequest.status == OvertimeRequestStatus.PendingHRApproval)) &&
                                        //<RejectReasonDialog onConfirm={this.reject} />
                                        <RejectReasonDialog onConfirm={(reason: string) => { this.reject(reason) }} />}
                                    {!this.state.isNew && this.state.isApprover && (this.state.overtimeRequest.status == OvertimeRequestStatus.PendingApproval || (this.state.overtimeRequest.status == OvertimeRequestStatus.PendingHRApproval)) &&
                                        <Button color="primary" variant="contained" onClick={() => this.approve()} >{this.props.i18n.t('approve')}</Button>
                                    }

                                </CardActions>
                            </Form>
                        </Card>
                    )}
                </Formik>

            </React.Fragment >
        );
    }

}

export default compose(
    withTranslation(),
    withStyles(useStyles),
    connector,
)(OvertimeRequestForm as any);

