import React from 'react';
import { LeaveAttachment } from '../model/';
import UserName from './UserName';
import moment from 'moment';

import {
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import {
    CloudDownload as DownloadIcon,
} from '@material-ui/icons';

type AttachmentListItemProps =
    {
        data: LeaveAttachment
    }

const AttachmentListItem = (props: AttachmentListItemProps) => {
    const { data } = props;

    const DownloadAttahcment = () => {
        const linkSource = `data:application/octet-stream;base64,${data.fileData}`;
        const downloadLink = document.createElement("a");
        const fileName = data.fileName;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        return;
    }

    return (
        <ListItem button onClick={() => { DownloadAttahcment() }}>
            <ListItemIcon>
                <DownloadIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText
                primary={data.fileName}
                secondary={<React.Fragment>by <UserName userid={data.createdBy} /> at {moment(data.createdDate).format('YYYY-MM-DD HH:mm:ss')} </React.Fragment>} />
        </ListItem>
    );
}

export default AttachmentListItem
