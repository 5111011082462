import React, { useEffect, useState } from 'react'

import { connect, ConnectedProps } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    TextField,
    Paper,
    createMuiTheme,
    MuiThemeProvider,
    Button,
    Grid,
} from '@material-ui/core';
import { DatePicker } from "@material-ui/pickers";
import { User as UserModel } from '../../model';
import { ApplicationState } from '../../store';
import * as AuthenticateStore from '../../store/AuthenticateStore';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import { tableIcons } from '../../bug-fix/mui-table-tableicons';
import MaterialTable from 'material-table';
import MuiTable from '../../i18n/MuiTable';
import moment from "moment";

const mtTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#4caf50',
        },
        secondary: {
            main: '#5c6bc0',
        },
    },
    overrides: {
    },
});

const mapStateToProps = (state: ApplicationState) => {
    return {
        auth: state.authenticate,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        ...AuthenticateStore.actionCreators,
    }, dispatch)
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type AttendanceSummaryReportProps =
    {
        [x: string]: any;
    }
    & PropsFromRedux

interface IAttendanceSummaryModel {
    userid: number
    upn: string
    username: string
    date: string
    weekname: string
    logon: string
    alogon: string
    logoff: string
    alogoff: string
    ondutytime: string
    offdutytime: string
    otresult: string
    leave: string
    leaveslot: string
}

interface ISearchProps {
    users: string
    datefrom: string
    dateto: string
}


const AttendanceSummaryReport = (props: AttendanceSummaryReportProps) => {
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [master, setMaster] = useState({
        masterUsers: []
    });
    const [data, setData] = useState<IAttendanceSummaryModel[]>([]);
    const [searchProps, setSearchProps] = useState<ISearchProps>({
        users: '',
        datefrom: moment().startOf('month').format('YYYY-MM-DD'),
        dateto: moment().endOf('month').format('YYYY-MM-DD'),
    });

    // INIT
    useEffect(() => {
        fetch('api/user')
            .then(response => response.json())
            .then(data => {
                setMaster({ masterUsers: data });
            })
    }, []);

    const handleClick = () => {
        setIsLoading(true);
        fetch('api/report/attendancesummary?datefrom=' + searchProps.datefrom + '&dateto=' + searchProps.dateto + '&users=' + searchProps.users)
            .then(response => response.json())
            .then(data => {
                setData(data.map((x: IAttendanceSummaryModel) => {
                    return {
                        ...x,
                        leave: x.leave && t('report.leave_type.' + x.leave)
                    }
                }));
                setIsLoading(false);
            })
    }

    const MTable = () => {
        return (
            <Paper>
                <MuiThemeProvider theme={mtTheme}>
                    <MaterialTable
                        icons={tableIcons}
                        columns={tableState.columns}
                        data={data}
                        localization={MuiTable(i18n)}
                        options={{
                            search: false,
                            showTitle: false,
                            pageSize: 10,
                            headerStyle: { backgroundColor: '#9e9e9e', color: 'white', whiteSpace: 'nowrap' },
                            exportButton: true,
                            exportFileName: 'Attendance Summary Report ' + moment().format('YYYY-MM-DDTHHmmss'),
                            exportAllData: true,
                        }}
                    />
                </MuiThemeProvider>
            </Paper>
        )
    }

    const tableState = {
        columns: [
            {
                title: 'User Name',
                field: 'username',
            },
            {
                title: 'Date',
                field: 'date',
            },
            {
                title: 'Weekday',
                field: 'weekname',
            },
            {
                title: 'LogOn',
                field: 'logon',
            },
            {
                title: 'LogOn Result',
                field: 'alogon',
            },
            {
                title: 'LogOff',
                field: 'logoff',
            },
            {
                title: 'LogOff Result',
                field: 'alogoff',
            },
            {
                title: 'On-Duty Time',
                field: 'ondutytime',
            },
            {
                title: 'Off-Duty Time',
                field: 'offdutytime',
            },
            {
                title: 'OT Result',
                field: 'otresult',
            },
            {
                title: 'Leave',
                field: 'leave',
                // render: (rowData: any) => rowData.leave && t('ddl.report.' + rowData.leave),
            },
            {
                title: 'Slot',
                field: 'leaveslot',
            },
        ]
    }

    return (
        <>
            <Card>
                <CardHeader
                    title={t('report.attendance_summary.title')}
                />
                <Divider />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <Autocomplete
                                fullWidth
                                multiple
                                style={{ marginBottom: 8 }}
                                options={master.masterUsers}
                                onChange={(e: any, val: any) => {
                                    let selectedPeoples = val.map((a: any) => a.id)
                                    setSearchProps((prevState): any => {
                                        return {
                                            ...prevState,
                                            users: selectedPeoples.join(',')
                                        }
                                    })
                                }}
                                getOptionLabel={(option: UserModel) => option.upn}
                                blurOnSelect
                                renderInput={(params) =>
                                    <TextField {...params}
                                        label={t("report.attendance_summary.people")}
                                        name="people"
                                    />}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <div>{option.upn} ({option.userName})</div>
                                    </React.Fragment>
                                )}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <DatePicker
                                label={t("report.attendance_summary.datefrom")}
                                autoOk
                                format='YYYY-MM-DD'
                                value={searchProps.datefrom}
                                onChange={(val: any) => {
                                    setSearchProps((prevState): any => {
                                        return {
                                            ...prevState,
                                            datefrom: moment(val).format('YYYY-MM-DD')
                                        }
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <DatePicker
                                label={t("report.attendance_summary.dateto")}
                                autoOk
                                format='YYYY-MM-DD'
                                value={searchProps.dateto}
                                onChange={(val: any) => {
                                    setSearchProps((prevState): any => {
                                        return {
                                            ...prevState,
                                            dateto: moment(val).format('YYYY-MM-DD')
                                        }
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                style={{ float: 'right' }}
                                color="primary"
                                variant="contained"
                                onClick={handleClick}
                            >
                                {t('report.attendance_summary.search')}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <MTable />
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </>

    )
}


export default compose(
    connector,
)(AttendanceSummaryReport as any) 