import React, { useEffect, useState, useRef } from 'react'
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import {
    Backdrop,
    CircularProgress,
    Theme,
} from '@material-ui/core';

type loadingProps = {
    loading: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const Loading = (props: loadingProps) => {
    const { loading } = props;
    const classes = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default Loading
