import * as Attendance from './Attendance';
import * as AuthenticateStore from './AuthenticateStore';
import * as EmployeeTypeStore from './EmployeeTypeStore';
import * as LeaveRequestStore from './LeaveRequestStore';
import * as LeaveTypeStore from './LeaveTypeStore';
import * as LeaveTypeEmployeeTypeStore from './LeaveTypeEmployeeTypeStore';
import * as MyStatStore from './MyStatStore';
import * as PreApprovalRequestStore from './PreApprovalRequestStore';
import * as preApprovalBalanceSpendStore from './PreApprovalBalanceSpendStore';
import * as OvertimeRequestStore from './OvertimeRequestStore';
import * as TaskApprovalStore from './TaskApprovalStore';
import * as UserAnnuallyLeaveTakedStore from './UserAnnuallyLeaveTakedStore';
import * as UserStore from './UserStore';
import { UserStat } from '../model';

// The top-level state object
export interface ApplicationState {
    attendanceDetail: Attendance.AttendanceState | undefined;
    authenticate: AuthenticateStore.AuthenticateState | undefined;
    employeeType: EmployeeTypeStore.EmployeeTypeState | undefined;
    leavedRequest: LeaveRequestStore.LeaveRequestState | undefined;
    leaveType: LeaveTypeStore.LeaveTypeState | undefined;
    leaveTypeEmployeeType: LeaveTypeEmployeeTypeStore.LeaveTypeEmployeeTypeState | undefined;
    myStat: MyStatStore.MyStatState | undefined;
    overtimeRequest: OvertimeRequestStore.OvertimeRequestState | undefined;
    preApprovalRequest: PreApprovalRequestStore.PreApprovalRequestState | undefined;
    preApprovalBalanceSpend: preApprovalBalanceSpendStore.PreApprovalBalanceSpendState | undefined;
    taskApproval: TaskApprovalStore.TaskApprovalState | undefined;
    userAnnuallyLeaveTaked: UserAnnuallyLeaveTakedStore.UserAnnuallyLeaveTakedState | undefined;
    user: UserStore.UserState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    leavedRequest: LeaveRequestStore.reducer,
    authenticate: AuthenticateStore.reducer,
    employeeType: EmployeeTypeStore.reducer,
    leaveType: LeaveTypeStore.reducer,
    leaveTypeEmployeeType: LeaveTypeEmployeeTypeStore.reducer,
    myStat: MyStatStore.reducer,
    overtimeRequest: OvertimeRequestStore.reducer,
    preApprovalRequest: PreApprovalRequestStore.reducer,
    preApprovalBalanceSpend: preApprovalBalanceSpendStore.reducer,
    taskApproval: TaskApprovalStore.reducer,
    userAnnuallyLeaveTaked: UserAnnuallyLeaveTakedStore.reducer,
    user: UserStore.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
