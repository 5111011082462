import React from 'react';
import MaterialTable, { Column } from 'material-table';
import MuiTable from '../i18n/MuiTable';
import { useTranslation } from 'react-i18next';
import { tableIcons } from '../bug-fix/mui-table-tableicons';
import UserName from './UserName';
import moment from 'moment';

import { TaskApproval as TaskApprovalModel } from '../model';

type ReportingLineTableProps =
    {
        data: TaskApprovalModel[]
    }

const ReportingLineTable = (props: ReportingLineTableProps) => {
    const { data } = props
    const { t, i18n } = useTranslation();
    return (
        <MaterialTable
            icons={tableIcons}
            columns={[
                {
                    title: t('task_approval.action_by'),
                    field: 'actionBy',
                    render: rowData => (<UserName userid={rowData.actionBy} />)

                },
                {
                    title: t('task_approval.action'),
                    field: 'action',
                    render: rowData => rowData.action != null ? t(rowData.action.toLowerCase()) : null
                },
                {
                    title: t('task_approval.date'),
                    field: 'modifiedDate',
                    render: rowData => {
                        if (rowData.modifiedDate)
                            return (
                                <React.Fragment>{moment(rowData.modifiedDate).format("YYYY-MM-DD")}</React.Fragment>
                            )
                        else
                            return (
                                <React.Fragment></React.Fragment>
                            )
                    }
                },
                {
                    title: t('remark'),
                    field: 'remarks',
                },
            ]}
            data={data}
            localization={MuiTable(i18n)}
            options={{
                search: false,
                paging: false,
                showTitle: false,
                toolbar: false,
                draggable: false,
                sorting: false,
            }}
        />
    )
}

export default ReportingLineTable;
