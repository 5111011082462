
import { LeaveTypeState } from '../store/LeaveTypeStore';
import { isNullOrUndefined } from 'util';

type leaveTypeSortingProps = {
    field: string,
    leaveTypeState: LeaveTypeState | undefined
}

const leaveTypeSorting = (props: leaveTypeSortingProps) => (a: any, b: any): number => {
    if (isNullOrUndefined(props.leaveTypeState) || props.leaveTypeState.isLoading) {
        return 0;
    }

    let aField = a[props.field];
    let bField = b[props.field];
    let aLeaveTypeName = '';
    let bLeaveTypeName = '';

    if (!isNullOrUndefined(aField)) {
        let aLeaveType = props.leaveTypeState.leaveTypeList.find(x => x.id == aField);
        if (!isNullOrUndefined(aLeaveType)) {
            aLeaveTypeName = aLeaveType.leaveTypeName;
        }
    }

    if (!isNullOrUndefined(bField)) {
        let bLeaveType = props.leaveTypeState.leaveTypeList.find(x => x.id == bField);
        if (!isNullOrUndefined(bLeaveType)) {
            bLeaveTypeName = bLeaveType.leaveTypeName;
        }
    }

    return aLeaveTypeName.localeCompare(bLeaveTypeName);
}

export default leaveTypeSorting
