import React from 'react';
import AttachmentListItem from "./AttahcmentListItem"
import { LeaveAttachment } from '../model/'

import {
    List,
} from '@material-ui/core';

type AttachmentListProps =
    {
        data: LeaveAttachment[];
    }

const AttachmentList = (props: AttachmentListProps) => {
    const { data } = props;

    return (
        <List>
            {data.map((leaveAttachment: LeaveAttachment, index: number) => {
                return (
                    <AttachmentListItem key={leaveAttachment.id} data={leaveAttachment} />
                )
            })}
        </List>
    )
}

export default AttachmentList
