import React, { useEffect, useState } from 'react'

import { connect, ConnectedProps } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    Card,
    CardHeader,
    CardContent,
    Divider,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Paper,
    createMuiTheme,
    MuiThemeProvider,
    MenuItem,
    IconButton,
} from '@material-ui/core';
import { User as UserModel, Region as RegionModel } from '../../model';
import { ApplicationState } from '../../store';
import * as AuthenticateStore from '../../store/AuthenticateStore';
import { useTranslation } from 'react-i18next';
import { tableIcons } from '../../bug-fix/mui-table-tableicons';
import MaterialTable from 'material-table';
import MuiTable from '../../i18n/MuiTable';
import {
    Edit as EditIcon,
} from '@material-ui/icons';
import Loading from '../../component/Loading';
import UserFilter from '../../component/User/UserFilter';

const useStyles = makeStyles(() => ({
    root: {
    },
    calendarContent: {
        marginTop: 20
    },
    formControl: {
        float: 'right',
        marginTop: 40,
        marginBottom: 20,
        minWidth: 120,
    },
}));

const mtTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#4caf50',
        },
        secondary: {
            main: '#5c6bc0',
        },
    },
    overrides: {
    },
});

const mapStateToProps = (state: ApplicationState) => {
    return {
        auth: state.authenticate,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        ...AuthenticateStore.actionCreators,
    }, dispatch)
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type UserListProps =
    {
        [x: string]: any;
    }
    & PropsFromRedux

interface User {
    id: number
    upn: string
    userName: string
    email: string
    employeeTypeId?: number
    birthDay: Date
    regionid: number
    enttiy: string
    entitledLeave: number
    hradmin?: boolean
    activestatus: boolean
    region: string
    employeetypename: string
}


const UserList = (props: UserListProps) => {
    const { className, ...rest } = props;

    const { t, i18n } = useTranslation();
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState<UserModel[]>([]);
    const [master, setMaster] = useState({
        masterRegions: []
    });

    // INIT
    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            fetch('api/user/GetUserList'),
            fetch('api/region'),
        ])
            .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
            .then(([data1, data2]) => {
                setUsers(data1);
                setMaster({ masterRegions: data2 });
                setIsLoading(false);
            })
    }, []);

    
    const regionFilter = (props: any): React.ReactElement<any> => {
        return (
            <Select
                fullWidth
                value={props.columnDef.tableData.filterValue || ''}
                onChange={(event) => {
                    props.onFilterChanged(props.columnDef.tableData.id, event.target.value);
                }}
            >
                <MenuItem value=''>--</MenuItem>
                {master.masterRegions.map((option: RegionModel) =>
                    <MenuItem key={option.id} value={option.name}>{t("region." + option.name)}</MenuItem>
                )}
            </Select>
        )
    }


    const MTable = () => {
        return (
            <Paper>
                <MuiThemeProvider theme={mtTheme}>
                    <MaterialTable
                        icons={tableIcons}
                        columns={tableState.columns}
                        data={users}
                        localization={MuiTable(i18n)}
                        options={{
                            filtering: true,
                            showTitle: false,
                            pageSize: 10,
                            toolbar: false,
                            headerStyle: { backgroundColor: '#9e9e9e', color: 'white', whiteSpace: 'nowrap' },
                        }}
                    />
                </MuiThemeProvider>
            </Paper>
        )
    }

    const tableState = {
        columns: [
            {
                title: '',
                sorting: false,
                // cellStyle: {
                //     width: "3rem",
                // },
                width: 70,
                render: (rowData: any) => <>
                    <IconButton
                        component={Link}
                        to={"/user/" + rowData.id}
                        size="small"
                    >
                        <EditIcon />
                    </IconButton>
                </>
            },
            {
                title: t('administration.user.upn'),
                field: 'upn',
            },
            {
                title: t('administration.user.username'),
                field: 'username',
            },
            {
                title: t('administration.user.email'),
                field: 'email',
            },
            {
                title: t('administration.user.employee_type'),
                field: 'employeetypename',
            },
            {
                title: t('administration.user.region'),
                field: 'region',
                filterComponent: regionFilter,
                render: (rowData: any) => t('region.' + rowData.region)
            },
            // {
            //     title: t('administration.user.hr_admin'),
            //     field: 'hradmin',
            //     filtering: false,
            // },
            {
                title: t('administration.user.status'),
                field: 'activestatus',
                filtering: false,
                render: (rowData: any) => rowData.activestatus ? t('administration.user.enable') : t('administration.user.disable')
            },
        ]
    }

    return (
        <>
            <Card>
                <CardHeader
                    title={t('administration.user.title')}
                />
                <Divider />
                <CardContent>
                    <div style={{ display: 'flex', marginBottom: 10 }}>
                        <div style={{ flexGrow: 1 }}></div>
                        <Button
                            component={Link}
                            to={"/user/add"}
                            color="primary"
                            variant="contained">{t('administration.user.new')}
                        </Button>
                    </div>
                    <MTable />
                </CardContent>
                <div>
                    <Loading loading={isLoading} />
                </div>
            </Card>
        </>

    )
}


export default compose(
    connector,
)(UserList as any) 