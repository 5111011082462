import React from 'react';

import { User as UserModel } from '../model';
import { Avatar } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import FaceIcon from '@material-ui/icons/Face';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            alignItems: 'center',
            display: 'flex',
            margin: 10,
        },
        avatar: {
            color: theme.palette.primary.contrastText,
            backgroundColor: "#8c8c8c",
            marginRight: 10,
        },
    }),
)

type UserInfoProps =
    {
        user: UserModel
    }

const UserInfo = (props: UserInfoProps) => {
    const { user } = props;
    const classes = useStyles();

    return <div className={classes.root}>
        <Avatar sizes="small" className={classes.avatar}>
            <FaceIcon />
        </Avatar>
        {user.userName}
    </div>
};

export default UserInfo;