
import { isNullOrUndefined } from 'util';

import moment from 'moment';

const dateStringFilter = (field: string, term: string, RowData: any) => {

    let tDate = RowData[field];
    
    if (term.length == 0 || isNullOrUndefined(tDate)) {
        return true;
    }

    if (!moment.isMoment(tDate)) {
        return false;
    }

    return tDate.format('YYYY-MM-DD').includes(term);

}

const dateSearch = (field: string) => {
    return (term: any, RowData: any): boolean => {
        switch (typeof term) {
            case 'string':
                return dateStringFilter(field, term, RowData);
            default:
                return true;
        }
    }
}

export default dateSearch;