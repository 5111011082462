import { Moment } from 'moment'
import { OvertimeDate } from './OvertimeDate'
import { LeaveAttachment } from './LeaveAttachment'
import { TaskApproval } from './TaskApproval'
export enum OvertimeRequestStatus {
    Draft = 'draft',
    PendingApproval = 'pending_approval',
    Approved = 'approved',
    Reject = 'rejected',
    PendingHRApproval = 'pending_hr_approval',
    Withdraw = 'withdraw'
}

export interface OvertimeRequest {
    id: number
    requestId: string
    applicant?: number
    remarks: string
    nextApprover?: number
    status:string
    createdBy?: number
    createdDate?: Moment
    modifiedBy?: number
    modifiedDate?: Moment
    overtimeDate?: OvertimeDate[]
    leaveAttachment?: LeaveAttachment[]
    taskApproval?: TaskApproval[]
    accessRecord?: OvertimeDate[]
    attendance?:boolean
}
