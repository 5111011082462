import React, { useEffect, useState, useRef } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Button,
	Card,
	CardHeader,
	CardActions,
	CardContent,
	Divider,
	Grid,
	Paper,
	FormControl,
	Select,
	Theme,
	Checkbox,
	FormControlLabel,
	TextField,
	InputLabel,
	createMuiTheme,
	MuiThemeProvider,
	MenuItem,
	Switch,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from "moment";
import 'moment/locale/zh-hk';
import 'moment/locale/zh-cn';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import {
	ReasonType as ReasonTypeModel,
	User as UserModel
} from '../../model';
import theme from '../../theme';
import { RouteComponentProps } from 'react-router';
import Loading from '../../component/Loading';

import { useMediaQuery } from '@material-ui/core';
import { IAttendance } from './AttendanceModel';


import { tableIcons } from '../../bug-fix/mui-table-tableicons';
import MaterialTable from 'material-table';
import MuiTable from '../../i18n/MuiTable';
import { ApplicationState } from '../../store';
import * as AuthenticateStore from '../../store/AuthenticateStore';
import { setJWT } from '../../helper';
import Unauthorized from '../Unauthorized';

// STYLE
const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	monthButton: {
		marginRight: 10,
		marginBottom: 10,
	},
	selectAllButton: {
		float: 'right'
	},
	actionButton: {
		float: 'right',
		marginRight: 10,
	},
	headerCell: {
		fontWeight: 'bold',
		alignContent: 'left',
		whiteSpace: 'nowrap'
	},
	detailLabel: {
		marginTop: 8,
	},
	detailFontSize: {
		fontSize: 14
	},
	timeBox: {
		paddingRight: 5,
		// width: '100px',
	}
}));

const mtTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#4caf50',
		},
		secondary: {
			main: '#5c6bc0',
		},
	},
	overrides: {
		MuiTableCell: {
			root: {
				padding: '10px 10px',
			}
		},
		MuiIconButton: {
			root: {
				padding: 8
			}
		},
		MuiTypography: {
			body1: {
				fontSize: 14
			}
		},
		MuiTableRow: {
			root: {
				height: 59
			}
		},
		MuiFormControlLabel: {
			root: {
				marginBottom: 0
			},
			label: {
				minWidth: 60
			}
		},
	},
});

type Action = "SAVE" | "SUBMIT"

const mapStateToProps = (state: ApplicationState) => {
	return {
		auth: state.authenticate,
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return bindActionCreators({
		...AuthenticateStore.actionCreators,
	}, dispatch)
}

const connector = connect(
	mapStateToProps,
	mapDispatchToProps,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type UserAttendanceProps =
	{
		[x: string]: any;
	}
	& PropsFromRedux



const UserAttendance = (props: UserAttendanceProps) => {
	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
		defaultMatches: true
	})

	const { className, auth, ...rest } = props;
	const classes = useStyles();
	const { t, i18n } = useTranslation();

	const [date, setDate] = useState({
		startdate: moment(new Date()).startOf('month').format('YYYY-MM-DD'),
		enddate: moment(new Date()).endOf('month').format('YYYY-MM-DD'),
		userid: new URLSearchParams(props.location.search).get("userid") || (auth && auth.user && auth.user.id),
		currentselected: true,
	});

	const [attendanceData, setAttendanceData] = useState<IAttendance[]>([]);
	const [master, setMaster] = useState({
		Peoples: [],
		ReasonType: [],
	})

	const [isLoading, setIsLoading] = useState(false);
	const allowItems: number = attendanceData.filter(x => (x.status === null || x.status === 'rejected') && x.leavetypename === null && moment(x.date) <= moment().subtract(1, 'days')).length
	const seekApprovalItems: number = attendanceData.filter(x => x.seekapproval === true).length
	const [authok, setAuthok] = useState(true);


	const httpOptions = (_method: string) => {
		let options = {
			method: _method,
			headers: { 'Content-Type': 'application/json' },
		}
		if (auth) {
			setJWT(options, auth);
		}
		return options;
	};

	// INIT
	useEffect(() => {
		moment.locale(i18n.language); // weekday cannot change by the provider, fource 
		setIsLoading(true);

		Promise.all([
			fetch('/api/user/GetSubordinates'),
			fetch('/api/reasontype'),
		])
			.then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
			.then(([data1, data2]) => {
				setMaster({
					Peoples: data1,
					ReasonType: data2
				})
				// setDate({ ...date, userid: props.props.auth.user && props.auth.user.id })
				setIsLoading(false);
			})
	}, []);


	useEffect(() => {
		setIsLoading(true);
		fetch("/api/userattendance?userid=" + date.userid + "&startdate=" + date.startdate + "&enddate=" + date.enddate)
			// .then(response => response.json())
			.then(response => {
				if (response.ok) return response.json()
				else throw new Error()
			})
			.then(data => {
				setAttendanceData(data);
				setIsLoading(false);
			})
			.catch((ex) => {
				setAuthok(false)
				console.warn(ex);
			})
	}, [date]);

	// ACTION
	const handlePrevious = () => {
		setDate((prevState): any => {
			return {
				...date,
				startdate: moment(prevState.startdate).add(-1, 'M').format('YYYY-MM-DD'),
				enddate: moment(prevState.startdate).add(-1, 'd').format('YYYY-MM-DD'),
			}
		});

	}

	const handleNext = () => {
		setDate((prevState): any => {
			return {
				...date,
				startdate: moment(prevState.startdate).add(1, 'M').format('YYYY-MM-DD'),
				enddate: moment(prevState.startdate).add(1, 'M').endOf('month').format('YYYY-MM-DD')
			}
		});
	}

	const handleAction = (action: Action): void => {
		setIsLoading(true);
		let url = '';
		let jsonObjs: IAttendance[] = [];
		switch (action) {
			case "SAVE":
				url = '/api/userattendance';
				jsonObjs = attendanceData.filter((obj: IAttendance) => obj.update == true)
				break;
			case "SUBMIT":
				url = '/api/userattendance/submit';
				jsonObjs = attendanceData.filter((obj: IAttendance) => obj.seekapproval == true)
				break;
		}


		let options: any = {};
		options.method = 'POST';
		options.cache = 'no-cache';
		options.headers = { 'Content-Type': 'application/json' };
		options.body = JSON.stringify(jsonObjs)
		if (auth) {
			setJWT(options, auth);
		}

		fetch(url, options)
			.then(response => {
				if (response.ok) {
					switch (action) {
						case 'SAVE':
							alert(t("attendance.message.save_success"))
							break;
						case 'SUBMIT':
							alert(t("attendance.message.submit_success"))
							break;
					}

					setDate((prevState): any => {
						return { ...prevState }
					});
				}
				else {
					throw new Error("response isnt ok")
				}

				setIsLoading(false);
			})
	}

	const handleSelectAllClick = (e: any, val: any) => {
		if (val) {
			setAttendanceData(attendanceData.map(o => (
				{
					...o,
					seekapproval: (o.status === null || o.status === 'rejected') && o.leavetypename === null && moment(o.date) <= moment().subtract(1, 'days')
				})
			))
		}
		else {
			setAttendanceData(attendanceData.map(o => (
				{
					...o,
					seekapproval: false
				})
			))
		}
	}

	const handleSelectPeople = ((e: any) => setDate({
		...date,
		userid: e.target.value,
		currentselected: (props.auth && props.auth.user && props.auth.user.id) === e.target.value,
	}))

	// DISPLAY
	const lateColumn = (row: IAttendance) => {
		let retVal = null;
		if ((moment(row.date) <= moment().subtract(1, 'days')) && row.leavetypename == null) {
			if (row.late == null) retVal = t('attendance.absence_missing');
			else if (row.late < -15) retVal = t('attendance.absence_long');
			else if (row.late < 0 && row.late >= -15) retVal = t('attendance.absence_short');
		}

		return retVal == null ? '--' : retVal;
	}

	const approvalColumn = (row: any) => {
		if ((row.status == null || row.status == 'rejected')
			&& row.leavetypename == null
			&& (moment(row.date) <= moment().subtract(1, 'days')))
			return <FormControlLabel
				className={classes.detailFontSize}
				label={t("attendance.seek_approval")}
				control={
					<Checkbox
						color="primary"
						size="small"
						checked={row.seekapproval == true ? true : false}
						onChange={(e: any) =>
							setAttendanceData([...attendanceData].map(object => {
								if (object.date === row.date) {
									return {
										...object,
										seekapproval: e.target.checked,
									}
								}
								else return object;
							}))
						}
					/>
				}
			/>
	}

	const reasonColumn = (row: any) => {

		if (row.leavetypename != null) {
			return t("ddl.leavetype." + row.leavetypename)
		}

		if (!(row.status == null || row.status == 'rejected')) {
			return row.reasontypename && t("ddl.reasontype." + row.reasontypename)
		}

		return <FormControl variant="standard" fullWidth>
			<Select
				id="reason"
				className={classes.detailFontSize}
				margin="dense"
				value={row.reasontype != null ? row.reasontype : 0}
				onChange={(e: any) =>
					setAttendanceData([...attendanceData].map((object, index) => index === row.tableData.id ? {
						...object,
						reasontype: e.target.value != '' ? parseInt(e.target.value) : null,
						starttime: e.target.value != '' ? object.starttime : '',
						endtime: e.target.value != '' ? object.endtime : '',
						update: true,
					} : object))
				}
				disabled={!date.currentselected}
			>
				<MenuItem value=''><em>--</em></MenuItem>
				{master.ReasonType.map((item: ReasonTypeModel) =>
					<MenuItem key={item.id} value={item.id}>{t("ddl.reasontype." + item.reasonTypeName)}</MenuItem>
				)}
			</Select>
		</FormControl>
	}

	const timeColumn = (row: any) => {
		if (row.leavetypename != null) {
			return null
		}
		else {
			return <div style={{ display: 'flex' }}>
				<TimePicker
					placeholder="--:--"
					showSecond={false}
					minuteStep={15}
					disabled={row.reasontype == null || row.status == 'pending_approval' || row.status == 'approved' || !date.currentselected}
					onChange={(e: any) =>
						setAttendanceData(prevState => prevState.map((object, index) => index === row.tableData.id ? {
							...object,
							starttime: e == null ? null : e.format('HH:mm'),
							update: true,
						} : object))
					}
					value={row.starttime && moment(row.starttime, 'HH:mm')}
					className={classes.timeBox}
				/>
				<TimePicker
					placeholder="--:--"
					showSecond={false}
					minuteStep={15}
					disabled={row.reasontype == null || row.status == 'pending_approval' || row.status == 'approved' || !date.currentselected}
					onChange={(e: any) =>
						setAttendanceData(prevState => prevState.map((object, index) => index === row.tableData.id ? {
							...object,
							endtime: e == null ? null : e.format('HH:mm'),
							update: true,
						} : object))
					}
					value={row.endtime && moment(row.endtime, 'HH:mm')}
					className={classes.timeBox}
				/>
			</div>
		}
	}


	const tableState = {
		columns: [
			{
				title: t('attendance.date'),
				field: 'date',
				// width: 100,
				render: (rowData: any) => {
					return moment(rowData.date).format('YYYY-MM-DD')
				},
			},
			{
				title: t('attendance.weekday'),
				field: 'weekday',
				// width: 100,
				hidden: !isDesktop,
				render: (rowData: any) => {
					return moment(rowData.date).format('dddd')
				},
			},
			{
				title: t('attendance.first_in'),
				field: 'logon',
				// width: 100,
				render: (rowData: any) => {
					return (rowData.logon != null) ? moment(rowData.logon).format('hh:mm A') : rowData.logon
				},
			},
			{
				title: t('attendance.last_out'),
				field: 'logoff',
				// width: 100,
				render: (rowData: any) => {
					return (rowData.logoff != null) ? moment(rowData.logoff).format('hh:mm A') : rowData.logoff
				},
			},
			{
				title: t('attendance.absence_status'),
				field: 'late',
				hidden: !isDesktop,
				render: (rowData: any) => lateColumn(rowData)
			},
			{
				title: t('attendance.reason'),
				field: 'reasontype',
				hidden: !isDesktop,
				render: (rowData: any) => reasonColumn(rowData)
			},
			{
				title: t('attendance.start_time') + '/' + t('attendance.end_time'),
				hidden: !isDesktop,
				cellStyle: {
					width: "15%"
				},
				render: (rowData: any) => timeColumn(rowData)
			},
			{
				title: t('attendance.status'),
				field: 'status',
				hidden: !isDesktop || !date.currentselected,
				render: (rowData: any) => rowData.status == null ? '--' : t('common_status.' + rowData.status.toLowerCase())
			},
			{
				// title: 'Seek Approval',
				hidden: !isDesktop || !date.currentselected,
				render: (rowData: any) => approvalColumn(rowData)
			},
		]
	}


	if (!authok)
		return <Unauthorized />
	return (
		<Card
			// {...rest}
			className={clsx(classes.root, className)}
		>
			<CardHeader
				title={t("attendance.title")}
			/>
			<Divider />
			<CardContent>
				<>
					<Grid container spacing={0}>
						<Grid style={{ marginBottom: 10 }} item lg={6} xs={12}>
							<InputLabel shrink>People</InputLabel>
							<FormControl variant="standard" fullWidth>
								<Select
									id="people"
									className={classes.detailFontSize}
									margin="dense"
									value={date.userid}
									onChange={handleSelectPeople}
								>
									{master.Peoples.map((option: UserModel) => <MenuItem key={option.id} value={option.id}>{option.upn} ({option.userName})</MenuItem>)}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<Button className={classes.monthButton} color="primary" variant="contained" onClick={handlePrevious}>{t("attendance.previous")}</Button>
							<Button className={classes.monthButton} color="primary" variant="contained" onClick={handleNext}>{t("attendance.next")}</Button>
							{date.currentselected &&
								<FormControlLabel
									className={classes.selectAllButton}
									control={
										<Switch color="primary"
											checked={allowItems > 0 && seekApprovalItems === allowItems} 
										/>
									}
									label={t("attendance.select_all")}
									labelPlacement="end"
									onChange={handleSelectAllClick}
								/>
							}
						</Grid>
					</Grid>
				</>
				<Paper>
					<MuiThemeProvider theme={mtTheme}>
						<MaterialTable
							icons={tableIcons}
							columns={tableState.columns}
							data={attendanceData}
							localization={MuiTable(i18n)}
							options={{
								// filtering: true,
								sorting: false,
								showTitle: false,
								paging: false,
								toolbar: false,
								// selection: true,
								// selectionProps: (rowData: any) => ({
								// 	size: 'small',
								// }),
								headerStyle: { backgroundColor: '#9e9e9e', color: 'white', whiteSpace: 'nowrap' },
								// filterCellStyle: { padding: 10 },
								// actionsCellStyle: { padding: 5 },
								detailPanelType: 'single',
								rowStyle: rowData => ({
									backgroundColor: rowData.seekapproval ? '#d6ffd7' : '#FFF'
								})
							}}

							// onSelectionChange={handleSelection}

							onRowClick={(event: any, rowData: any, togglePanel: any) => !isDesktop && togglePanel()}
							detailPanel={isDesktop ? [] : rowData =>
								<React.Fragment>
									<Grid style={{ padding: '10px 15px', backgroundColor: rowData.seekapproval ? '#D6FFD7' : '#FFF' }}
										container spacing={0}>
										<Grid className={classes.detailLabel} item xs={6}>
											<InputLabel shrink>{t("attendance.weekday")}</InputLabel>
											{moment(rowData.date).format('dddd')}
										</Grid>
										<Grid className={classes.detailLabel} item xs={6}>
											<InputLabel shrink>{t("attendance.absence_status")}</InputLabel>
											{lateColumn(rowData)}
										</Grid>
										<Grid className={classes.detailLabel} item xs={12}>
											<InputLabel htmlFor="reason" shrink>{t("attendance.reason")}</InputLabel>
											{reasonColumn(rowData)}
										</Grid>
										<Grid className={classes.detailLabel} item xs={12}>
											{timeColumn(rowData)}
										</Grid>
										{date.currentselected && <Grid className={classes.detailLabel} item xs={12}>
											<InputLabel shrink>{t("attendance.status")}</InputLabel>
											{rowData.status == null ? '--' : t('common_status.' + rowData.status.toLowerCase())}
										</Grid>}
										{date.currentselected && <Grid className={classes.detailLabel} item xs={12}>
											{approvalColumn(rowData)}
										</Grid>}
									</Grid>
								</React.Fragment>
							}
						/>
					</MuiThemeProvider>
				</Paper>
			</CardContent>
			<CardActions className={classes.actionButton}>
				{
					attendanceData.some(e => e.status === null || e.status === 'rejected') && date.currentselected &&
					<Button color="primary" variant="contained" onClick={() => handleAction('SAVE')}>{t('save')}</Button>
				}
				{
					attendanceData.some(d => d.seekapproval === true) && date.currentselected && // !moment().isBefore(date.enddate) &&
					<Button color="primary" variant="contained" onClick={() => handleAction('SUBMIT')}>{t('submit')}</Button>
				}
			</CardActions>
			<div>
				<Loading loading={isLoading} />
			</div>
		</Card >
	)
}

export default compose(
	connector,
)(UserAttendance as any) 