import React, { useEffect } from "react"
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../store';
import * as UserStore from '../store/UserStore';

const mapStateToProps = (state: ApplicationState) => {
    return {
        users: state.user,
    }
}

const connector = connect(
    mapStateToProps,
    UserStore.actionCreators,
)

type PropsFromRedux = ConnectedProps<typeof connector>

type UserNameProps =
    {
        userid: number | undefined
    }
    & PropsFromRedux

const UserName = (props: UserNameProps) => {
    const { userid, users, requestUserRecord, ...rest } = props;

    useEffect(() => {
        if (!users)
            throw Error('userstate did not ready??');

        if (!users.isLoading && (!users.userList || users.userList.length == 0)) {
            requestUserRecord();
        }
    }, []);

    const getUserName = (): string => {
        if ((userid && userid <= 0) || !users || !users.userList || users.userList.length == 0)
            return '';
        var user = users.userList.find(x => x.id == userid)

        if (user)
            return user.userName
        else
            return '';
    }

    return (
        <React.Fragment>{getUserName()}</React.Fragment>
    );
}


export default connector(UserName)